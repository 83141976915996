import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, CondOptions, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page12 = () => {

  const page = 12;
  const [loading, setLoading] = useState(false);
  const qn = ["4.01", "4.02", "4.03", "4.04", "4.041", "4.05", "4.06", "4.07", "4.071", "4.08", "4.09", "4.10", "4.11", "4.12"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);
  const [v404, setV404] = useState("No");
  const [v407, setV407] = useState("No");

  // fetch data
  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV404(result.answers[3].choice);
      setV407(result.answers[7].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      if (typeof (c) === 'object') c = c.join(',');
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <TextField
            form={form}
            label="1. How did you reach a new place of residence?"
            question="c4.01"
          />
          <TextField
            form={form}
            label="2. Whom did you travel with?"
            question="c4.02"
          />
          <SingleField
            form={form}
            label="3. How dangerous was the journey to your new place of residence?"
            question="c4.03"
            options={[
              "very dangerous",
              "somewhat dangerous",
              "neither dangerous nor safe",
              "somewhat safe",
              "very safe",
              "don’t know",
            ]}
          />
          <CondOptions
            form={form}
            label="4. Were you subjected to any violence on your journey to your new place of residence?"
            question="c4.04"
            trigger="Yes"
            current={v404}
            options={["Yes", "No", "Don’t know"]}
            subQuestion="c4.041"
            subLabel="4.1 If yes, what kind of violence?"
            subOptions={[
              "Physical violence",
              "Sexual violence",
              "Exploitation",
              "Abuse",
              "Don’t know",
            ]}
            subOther="Other (specify):"
            subExtension="e4.041"
            subMulti={true}
          />
          <TextField
            form={form}
            label="5. For how long were you and your family looking for accommodation?"
            question="c4.05"
          />
          <TextField
            form={form}
            label="6. Where were you staying meanwhile?"
            question="c4.06"
          />
          <CondField
            form={form}
            label="7. Did you take any personal belongings with you from home?"
            question="c4.07"
            trigger="Yes"
            current={v407}
            options={["Yes", "No"]}
            subQuestion="c4.071"
            subLabel="7.1 If yes, what?"
          />
          <SingleField
            form={form}
            label="8. Please describe your new housing situation"
            question="c4.08"
            options={[
              "Private house",
              "Apartment",
              "Office space",
              "Basement",
              "Collective housing",
              "Refugee camp",
              "Military camp",
            ]}
            other="Other (specify):"
            extension="e4.08"
          />
          <TextField
            form={form}
            label="9. What was the size of the living space (approximately in m²)?"
            question="c4.09"
          />
          <TextField
            form={form}
            label="10. How many persons did you share that space with?"
            question="c4.10"
          />
          <TextField
            form={form}
            label="11. Whom did you live with?"
            question="c4.11"
          />
          <SingleField
            form={form}
            label="12. Was your new place of residence a direct target of attacks?"
            question="c4.12"
            options={[
              "Yes",
              "No",
              "I don't remember"
            ]}
            other="Other:"
            extension="e4.12"
          />
        </Form>
        <Navigator prev={110} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page12;