import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { Table, Layout, Modal, message } from "antd";

import service from "services/apiServices";
import { isAdmin } from "services/authServices";
import tableHeader from "services/tableService";
import config from "config";
import Inhabitancy from './inhabitancy';

const Residences = ({ participant, labels }) => {

  const colProps = [
    { key: "order", title: "", width: '6%' },
    { key: "place", title: labels.PLACE_INHABITED, width: '40%' },
    { key: "period", title: labels.PERIOD_INHABITED, width: '40%' }
  ];

  const columns = ({ canEdit, onEditClick }) =>
    tableHeader(
      colProps,
      false,
      canEdit,
      false,
      onEditClick
    );

  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list(`participants/${participant}/residence`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [participant]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const editInhabitancy = id => {
    if (id === 0) {
      setItem({ id: 0, type: 0, order: 0, place: '', period: '', participantId: participant });
    } else {
      const inhabit = _.find(data, { id: id });
      setItem({ id: inhabit.id, type: inhabit.type, order: inhabit.order, place: inhabit.place, period: inhabit.period, participantId: participant });
    }
    showModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <Layout>
        <Table
          loading={isLoading}
          size="small"
          tableLayount={"fixed"}
          columns={columns({
            canEdit: isAdmin(),
            onEditClick: (id) => editInhabitancy(id),
          })}
          dataSource={data}
          pagination={false}
          rowKey={(row) => row.id}
        />
      <Modal width={'50%'} open={visible} title={<h2>PLACES INHABITED DURING THE ARMED CONFLICT</h2>} footer={[]} onCancel={hideModal}>
        <Inhabitancy item={item} labels={labels} onOk={refresh} />
      </Modal>
    </Layout>
  );
};

export default Residences;
