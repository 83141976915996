import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { Table, Layout, Modal, message } from "antd";

import service from "services/apiServices";
import { isAdmin } from "services/authServices";
import tableHeader from "services/tableService";
import config from "config";
import Loan from './loan';

const colProps = [
  { key: "type", title: "Type", width: '12%' },
  { key: "status", title: "Status", width: '12%' },
  { key: "contactPerson", title: "Contact Person", width: '24%' },
  { key: "loanDate", title: "Loan Date", width: '24%' },
  { key: "loanPeriod", title: "Loan Period", width: '24%' }
];

const columns = ({ canView, canEdit, onViewClick, onEditClick }) =>
  tableHeader(
    colProps,
    true,
    true,
    onViewClick,
    onEditClick
  );

const { Content } = Layout;

const Loans = ({ object }) => {

  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list(`pieces/${object}/loans`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [object]);

  useEffect(() => { fetch(); }, [fetch]);

  const editLoan = id => {
    if (id === 0) {
      setItem({
        id: 0,
        type: 'Incoming',
        status: 'Unknown',
        institution: '',
        contactPerson: '',
        loanDate: '0001-01-01',
        loanPeriod: '',
        returnDate: '0001-01-01',
        extensionDate: '0001-01-01',
        extensionPeriod: '',
        extensionReturnDate: '0001-01-01',
        mount: false,
        mountStorage: '',
        wayOfTransport: '',
        shippingDate: '0001-01-01',
        shipper: '',
        returnShippingDate: '0001-01-01',
        returnShipper: '',
        note: '',
        objectId: object
      });
    } else {
      const loan = _.find(data, { id: id });
      setItem({
        id: loan.id,
        type: loan.type,
        status: loan.status,
        institution: loan.institution,
        contactPerson: loan.contactPerson,
        loanDate: loan.loanDate,
        loanPeriod: loan.loanPeriod,
        returnDate: loan.returnDate,
        extensionDate: loan.extensionDate,
        extensionPeriod: loan.extensionPeriod,
        extensionReturnDate: loan.extensionReturnDate,
        mount: loan.mount,
        mountStorage: loan.mountStorage,
        wayOfTransport: loan.wayOfTransport,
        shippingDate: loan.shippingDate,
        shipper: loan.shipper,
        returnShippingDate: loan.returnShippingDate,
        returnShipper: loan.returnShipper,
        note: loan.note,
        objectId: object
      });
    }
    showModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={isLoading}
            size="small"
            tableLayount={"fixed"}
            columns={columns({
              canView: false,
              canEdit: isAdmin(),
              onViewClick: {},
              onEditClick: (id) => editLoan(id),
            })}
            dataSource={data}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
      <Modal width={'90%'} open={visible} title={<h1>OBJECT LOAN</h1>} footer={[]} onCancel={hideModal} style={{ top: 20 }}><Loan item={item} onOk={refresh} /></Modal>
    </div>
  );
};

export default Loans;
