import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  SingleField
} from "../fields";
import { Curtain } from "common";

const Page16 = () => {
  const page = 16;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["4.28", "4.29", "4.30", "4.31", "4.32", "4.33"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>
          <SingleField
            form={form}
            label="28. Did you personally collect water from a water source?"
            question="c4.28"
            options={[
              "Yes, by myself",
              "Yes, with parents",
              "Yes, with friends",
              "Yes, accompanied by an adult",
              "No",
            ]}
          />
          <SingleField
            form={form}
            label="29. Did you have to stand in line (queue) for water?"
            question="c4.29"
            options={["Yes", "No", "I don't remember"]}
          />

          <SingleField
            form={form}
            label="30. For how long did you have to stand in line?"
            question="c4.30"
            extension="e4.30"
            options={[
              "Up to 15 min",
              "15-30 min",
              "30-60 min",
              "1-2 hours",
              "More than 2 hours",
              "I don't remember",
            ]}
            other="Other:"
          />
          <SingleField
            form={form}
            label="31. Did it ever start shelling/bombing while you were queuing for water?"
            question="c4.31"
            options={["Yes", "No", "I don't remember"]}
          />
          <SingleField
            form={form}
            label="32. Were you ever exposed to sniper fire while queuing for water?"
            question="c4.32"
            options={["Yes", "No", "I don't remember"]}
          />
          <SingleField
            form={form}
            label="33. Have you ever witnessed shelling/bombing/sniper firing at the line for water?"
            question="c4.33"
            options={["Yes", "No"]}
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page16;
