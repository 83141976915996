import moment from "moment";

export function joinValues(values) {

  if (values.compoundType === undefined) values.compoundType = 'And';
  if (values.conflicts) values.conflicts = values.conflicts.join();
  if (values.researchCells) values.researchCells = values.researchCells.join();
  if (values.genders) values.genders = values.genders.join();
  if (values.initialSurvey) values.initialSurvey = values.initialSurvey.join();
  if (values.statement) values.statement = values.statement.join();
  if (values.oralHistory) values.oralHistory = values.oralHistory.join();
  if (values.restrictions) values.restrictions = values.restrictions.join();

  if (values.class) values.class = values.class.join();
  if (values.status) values.status = values.status.join();
  if (values.objects) values.objects = values.objects.join();
  if (values.imaginary) values.imaginary = values.imaginary.join();

  if (values.legalStatus) values.legalStatus = values.legalStatus.join();
  values.loanDate = !values.loanDate ? null : moment(values.loanDate).format('YYYY-MM-DD');
  if (values.objectType) values.objectType = values.objectType.join();
  if (values.material) values.material = values.material.join();
  if (values.handmade) values.handmade = values.handmade.join();
  if (values.broken) values.broken = values.broken.join();
  if (values.shape) values.shape = values.shape.join();
  if (values.conditionReport) values.conditionReport = values.conditionReport.join();
  if (values.conservation) values.conservation = values.conservation.join();
  if (values.hasImage) values.hasImage = values.hasImage.join();
  if (values.hasParts) values.hasParts = values.hasParts.join();
  if (values.hasNotes) values.hasNotes = values.hasNotes.join();
  if (values.handling) values.handling = values.handling.join();
  if (values.study) values.study = values.study.join();
  if (values.digital) values.digital = values.digital.join();
  if (values.copy) values.copy = values.copy.join();

  return values;
}

export function splitValues(data) {
  const values = {
    ...data,
    conflicts: !data.conflicts ? undefined : data.conflicts.split(','),
    researchCells: !data.researchCells ? undefined : data.researchCells.split(','),
    genders: data.genders === null ? undefined : data.genders.split(','),
    initialSurvey: data.initialSurvey === null ? undefined : data.initialSurvey.split(','),
    statement: data.statement === null ? undefined : data.statement.split(','),
    oralHistory: data.oralHistory === null ? undefined : data.oralHistory.split(','),
    restrictions: data.restrictions === null ? undefined : data.restrictions.split(','),
    rangeAge: [data.age1, data.age2],

    class: !data.class ? undefined : data.class.split(','),
    status: !data.status ? undefined : data.status.split(','),
    objects: !data.objects ? undefined : data.objects.split(','),
    imaginary: !data.imaginary ? undefined : data.imaginary.split(','),

    legalStatus: data.legalStatus === null ? undefined : data.legalStatus.split(','),
    objectType: !data.objectType ? undefined : data.objectType.split(','),
    material: !data.material ? undefined : data.material.split(','),
    handmade: !data.handmade ? undefined : data.handmade.split(','),
    broken: !data.broken ? undefined : data.broken.split(','),
    shape: !data.shape ? undefined : data.shape.split(','),
    conditionReport: !data.conditionReport ? undefined : data.conditionReport.split(','),
    conservation: !data.conservation ? undefined : data.conservation.split(','),
    hasImage: !data.hasImage ? undefined : data.hasImage.split(','),
    hasParts: !data.hasParts ? undefined : data.hasParts.split(','),
    hasNotes: !data.hasNotes ? undefined : data.hasNotes.split(','),
    handling: !data.handling ? undefined : data.handling.split(','),
    study: !data.study ? undefined : data.study.split(','),
    digital: !data.digital ? undefined : data.digital.split(','),
    copy: !data.copy ? undefined : data.copy.split(',')
  };

  if (data.participationDate1 && data.participationDate2) {
    values.participationDate1 = moment(data.participationDate1);
    values.participationDate2 = moment(data.participationDate2);
    values.dateRange = [moment(data.participationDate1), moment(data.participationDate2)];
  }

  if (data.accessionDate1 && data.accessionDate2) {
    values.accessionDate1 = moment(data.accessionDate1);
    values.accessionDate2 = moment(data.accessionDate2);
    values.accRange = [moment(data.accessionDate1), moment(data.accessionDate2)];
  }

  if (data.loanDate) {
    values.loanDate = moment(data.loanDate);
  }
  
  return values;
}