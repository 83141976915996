import React from 'react'
import TableHead from "services/TableHead";
import { WarningOutlined } from '@ant-design/icons'
import config from 'config';
import service from 'services/apiServices';

const noTitle = title => {
  return (title === '') ? <WarningOutlined style={{ fontSize: '1.5em', color: 'red' }} /> : title;
}

const thumbnail = image => <img style={{ border: '1px #999 solid' }} src={config.imagePath + image} alt={image} />;

const colProps = [
  { key: "image", title: "Photo", width: '6%', render: image => thumbnail(image) },
  { key: "id", title: "Id", width: '6%', align: 'center', render: id => service.toFormat(id) },
  { key: "title", title: "Title", width: '22%', render: title => noTitle(title) },
  { key: "class", title: "Classification", width: "22%" },
  { key: "gender", title: "Gender", width: '6%', align: 'center' },
  { key: "age", title: "Age at the beginning of the conflict", width: '5%', align: 'center', ellipsis: true },
  { key: "location", title: "Location", width: '12%' },
  { key: "status", title: "Status", width: '7%' }
];

const columns = ({ canEdit, editUrl }) => TableHead(colProps, canEdit, editUrl);

export default columns;
