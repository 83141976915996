import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page27 = () => {
  const page = 27;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["5.21", "5.211", "5.22", "5.23", "5.24", "5.25"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v521, setV521] = useState("No");
  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV521(result.answers[0].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <CondField
            form={form}
            label="21.	Did you collect anything?"
            question="c5.21"
            trigger="Yes"
            current={v521}
            options={["Yes", "No"]}
            subQuestion="c5.211"
            subLabel="21.1 If yes, what?"
          />
          <SingleField
            form={form}
            label="22.	Did you have any toys?"
            question="c5.22"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="23.	How did you obtain toys?"
            question="c5.23"
            extension="e5.23"
            options={[
              "From my older brother/sister",
              "From my older cousins or friends from the neighborhood",
              "I made my toys",
              "I bought my toys",
              "I received toys in a package from abroad",
              "I don’t remember",
            ]}
            other="Other:"
          />

          <TextField
            form={form}
            label="24.	List the places in which you played the most (in front of the house, in the street…etc.)"
            question="c5.24"
          />

          <SingleField
            form={form}
            label="25. Whom did you mostly play with? "
            question="c5.25"
            options={[
              "Local children",
              "Refugee children from the same cultural background",
              "Other refugee/migrant children (not the same cultural background as you)",
            ]}
          />
          <TextField
            form={form}
            label="26.	Do you remember any games from that period? If yes, please describe them shortly."
            question="c5.26"
          />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page27;
