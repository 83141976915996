import React, { useState, useEffect } from "react";
import { Table, Layout, Input, Select, Button, message, Checkbox } from "antd";
import service from "services/apiServices";
import { isSuper } from "services/authServices";
import config from "config";
import { columns, statusOptions, roleOptions } from "./columns";
import { useHistory } from "react-router-dom";

const { Search } = Input;

export default function Users() {

  const history = useHistory();
  if (!isSuper()) history.push("/home");

  const [users, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState([]);
  const [roles, setRoles] = useState([]);
  const [instArr, setInstArr] = useState([]);
  const [inst, setInst] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function fetch() {
    try {
      setIsLoading(true);
      let url = `users?filter=${filter}`;
      if (status.length > 0) url = url.concat(`&status=${status.join()}`);
      if (roles.length > 0) url = url.concat(`&roles=${roles.join()}`);
      if (inst && inst !== "ALL") url = url.concat(`&instit=${inst}`);
      let result = await service.list(url);
      if (result) setData(result);
      result = await service.list("users/inst");
      result.unshift("ALL");
      setInstArr(result);
    } catch (error) {
      message.error(config.errorMessage);
    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleSearch = e => {
    setFilter(e.target.value);
  }

  const changeStatus = values => {
    if (values.length === 3) values = [];
    setStatus(values);
  }

  const changeRoles = values => {
    if (values.length === 4) values = [];
    setRoles(values);
  }

  return (
    <Layout className="users-container">
      <div className="users-side">
        <h2 className="left" align='left'>Users / {users.length}</h2>
        <div className="right">
          <Button onClick={fetch} type="primary" shape="round" className="side-button">
            LIST
          </Button>
        </div>
        <div style={{ clear: 'both' }}></div>
        <hr />
        <div className="side-group">Search</div>
        <Search
          onChange={handleSearch}
          size='small'
          allowClear
          defaultValue={filter}
        />
        <hr />
        <div className="side-group">Status:</div>
        <Checkbox.Group options={statusOptions}
          onChange={changeStatus} />
        <hr />
        <div className="side-group">Role:</div>
        <Checkbox.Group options={roleOptions}
          onChange={changeRoles} />
        <hr />
        <div className="side-group">Institution:</div>
        <Select mode="combobox" size="small"
          showSearch
          optionFilterProp="children"
          defaultValue={"ALL"}
          filterOption={(input, option) => service.anything(input, option.children)}
          onSelect={value => setInst(value)}
          style={{ textAlign: 'left', width: '100%' }}>
          {instArr.map(p => (<Select.Option key={p} value={p}>{p}</Select.Option>))}
        </Select>
      </div>
      <div className="users-main">
        <Table
          loading={isLoading}
          size="small"
          tableLayout={"fixed"}
          columns={columns({
            canEdit: isSuper(),
            onEditClick: (id) => history.push(`users/${id}/edit`),
          })}
          dataSource={users}
          pagination={false}
          rowKey={(row) => row.id}
        />
      </div>
    </Layout>
  );
}
