import React, { useState, useEffect } from 'react';
import { Form, Layout, Button, DatePicker, message, Input, Row, Col, Upload, Radio } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import moment from 'moment';
import service from 'services/apiServices';
import config from 'config';
import { Buttons, Delete } from 'common';

const { Content } = Layout;
const { Dragger } = Upload;
const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

const Photo = ({ item, onOk }) => {

  const [url, setUrl] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...item,
      shootingDate: moment(item.shootingDate),
      objectId: service.toFormat(item.objectId),
      old: item.url
    });
    setUrl(config.imagePath + item.url + '.' + item.fileType);
  }, [form, item]);

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    values.shootingDate = moment(values.shootingDate).format('YYYY-MM-DD');
    values.objectId = service.toNumber(values.objectId);
    const result = values.id === 0 ?
      await service.insert('photos', { ...values, piece: { id: values.objectId } }) :
      await service.update('photos', { ...values, piece: { id: values.objectId } }, values.id);
    if (!result) message.error('Error updating photo information');
    onOk();
  };

  const options = {
    name: 'file',
    multiple: false,
    action: `${config.uploadPath}photo/${item.objectId}`,
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onSuccess(body) {
      setUrl(config.imagePath + body.url + '.' + body.fileType);
      form.setFieldsValue({
        fileType: body.fileType,
        shootingDate: moment(body.shootingDate),
        size: body.size,
        url: body.url
      });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
      }
    }
  }

  return (
    <Layout className="container">
      <Content align="center">
        <Row>
          <Col span={12}>

            <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFailed}>
              <Form.Item label="Object" name="objectId" hidden><Input /></Form.Item>

              <Form.Item label="Use of photo" name='use' style={{ textAlign: 'left' }}>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value='light'>Main (light)</Radio.Button>
                  <Radio.Button value='dark'>Main (dark)</Radio.Button>
                  <Radio.Button value='gallery'>Gallery</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="Photo id" name="id" hidden><Input className="stepFormField" /></Form.Item>
              <Form.Item label="Image name" name="url"><Input className="stepFormField" disabled /></Form.Item>
              <Form.Item label="Old name" name="old" hidden><Input className="stepFormField" /></Form.Item>
              <Form.Item label="File type" name="fileType"><Input className="stepFormField" disabled /></Form.Item>
              <Form.Item label="Shooting date" name="shootingDate"><DatePicker className="stepFormField" /></Form.Item>
              <Form.Item label="Size" name="size"><Input className="stepFormField" /></Form.Item>
              {/* <Form.Item label="Resolution" name="resolution"><Input className="stepFormField" /></Form.Item> */}
              <Form.Item label="Photographer" name="photographer"><Input className="stepFormField" /></Form.Item>
              <Form.Item label="Photo credit" name="photoCredit"><Input className="stepFormField" /></Form.Item>
              <Form.Item label="Note" name="note"><Input className="stepFormField" /></Form.Item>
              <hr />
              <Row>
                <Col span={8}><Delete dataSet='photos' id={item.id} onFinish={onOk} /></Col>
                <Col span={8}><Button style={Buttons.cancel} onClick={onOk}>Cancel</Button></Col>
                <Col span={8}><Button style={Buttons.button} htmlType='submit'>S A V E</Button></Col>
              </Row>
            </Form>

          </Col>

          <Col span={11} offset={1} align='center'>
            <div style={{marginBottom:'12px'}}>
              <img src={url} alt={item.url} />
            </div>
            <div>
              <Dragger {...options} style={{ border: '1px solid #369', height: '60px' }}>
                <p className='ant-upload-drag-icon'><InboxOutlined /></p>
                <p className='ant-upload-text'>Click or drag image here</p>
              </Dragger>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout >
  );
}

export default Photo;