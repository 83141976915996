import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { SingleField, CondField, CondOptions } from "../fields";
import { Curtain } from "common";

const Page6 = () => {

  const page = 6;
  const [loading, setLoading] = useState(false);
  const qn = ["3.16", "3.17", "3.18", "3.19", "3.191", "3.20", "3.21", "3.211", "3.22", "3.23"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent);
  const [v319, setV319] = useState("No");
  const [v321, setV321] = useState("No");

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result) {
      form.setFieldsValue({ id: result.id });
      if (result.answers.length > 0) {
        setV319(result.answers[3].choice);
        setV321(result.answers[6].choice);
        for (let i = 0; i < qn.length; i++) {
          if (result.answers[i] !== undefined) {
            form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
            form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
          }
        };
      }
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <SingleField form={form} label="16. Did you have running water at home?" question="c3.16" options={["Yes", "No"]} />

          <SingleField form={form} label="17. How did you obtain water?" question="c3.17"
            options={[
              "Well in a backyard",
              "Well in a neighborhood",
              "Spring (river)",
              "Cistern",
              "I don't remember",
              "Other"
            ]} />

          <SingleField form={form} label="18.	How far away was the water source from your home?" question="c3.18"
            options={[
              "Up to 300 m (3 min)",
              "Up to 500 m (5 min)",
              "Up to 1000 m (10 min)",
              "Up to 2000 m (20 min)",
              "More than 2000 m",
              "I don't remember",
              "Other"
            ]} />

          <CondField form={form}
            label="19.	Did you have any equipment for transporting water (cart)?"
            question="c3.19"
            options={["Yes", "No"]}
            trigger="Yes" current={v319}
            subLabel="19.1. If yes, what did you use?"
            subQuestion="c3.191"
          />

          <SingleField form={form} label="20.	Did you personally collect water from a water source?" question="c3.20"
            options={[
              "Yes, by myself",
              "Yes, with parents",
              "Yes, with friends",
              "Yes, accompanied by an adult",
              "No"
            ]} />

          <CondOptions form={form}
            label="21. Did you have to stand in line (queue) for water?"
            question="c3.21"
            options={["Yes", "No", "I don't remember"]}
            trigger="Yes" current={v321}
            subLabel="21.1 For how long did you have to stand in line?"
            subQuestion="c3.211"
            subOptions={[
              "Up to 15 min",
              "15-30 min",
              "30-60 min",
              "1-2 hours",
              "More than 2 hours",
              "I don't remember"
            ]}
          />

          <SingleField form={form} label="22.	Did it ever start shelling/bombing while you were queuing for water?" question="c3.22"
            options={["Yes", "No", "I don't remember"]} />

          <SingleField form={form} label="23.	Were you ever exposed to sniper fire while queuing for water?" question="c3.23"
            options={["Yes", "No", "I don't remember"]} />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
}

export default Page6;
