import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: '',
  id: 0,
  name: '',
  role: '',
  landing: '',
  conflicts: [],
  delParticipants: false,
  delStories: false,
  delObjects: false,
  delParts: false,
  delHistories: false
}


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return action.payload;
    },
    clearUser: () => initialState
  }
});

const { reducer, actions } = userSlice;

export const { setUser, clearUser } = actions;

export default reducer;