import React, { useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page26 = () => {
  const page = 26;
  const [loading, setLoading] = useState(false);
  const qn = ["5.15", "5.151", "5.16", "5.17", "5.18", "5.19", "5.20"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);
  const [visible, setVisible] = useState(false);

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setVisible(result.answers[0].choice === "Yes")
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  const YesOrNo = e => {
    setVisible(e.target.value === "Yes");
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item label={<div className="quest-label">15. Were you attending school?</div>} name="c5.15" onChange={YesOrNo}>
            <Radio.Group>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>

          {visible && <div>
            <TextField form={form} 
              label="15.1 If yes, what grade were you placed in upon arrival at the new place of residence?" 
              question="c5.151" />

            <SingleField form={form} label="16. Did you think the placement was the right grade for you to be in?"
              question="c5.16"
              options={["Yes", "No", "Don't know"]} />

            <SingleField form={form} label="17. Did you have textbooks?"
              question="c5.17"
              options={["Yes", "No", "I don't remember"]} />

            <SingleField form={form} label="18.	Did you have notebooks and school supplies?"
              question="c5.18"
              options={["Yes, some basic things", "Yes, everything", "No"]} />

            <TextField form={form} label="19.	How did you spend your free time?" question="c5.19" />

            <SingleField form={form} label="20.	Were you enrolled in any extracurricular activities? (music, sport, art…etc.)"
              question="c5.20"
              options={["Yes", "No", "Don't know"]} />
          </div>}

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page26;


