import React from "react";
import { Button } from "antd";

let button = {
  addNew: {
    height: '30px',
    marginRight: '6px',
    borderRadius: '4px',
    fontWeight: 500,
    backgroundColor: '#0D0B0B',
    color: 'white',
    width: '130px',
  },
  viewEdit: {
    height: '30px',
    margin: '0 3px',
    borderRadius: '4px',
    fontWeight: 500,
    color: '#211E1A',
    backgroundColor: '#ddc7b0'
  }
};

const tableHeader = (colProps, canView, canEdit, onViewClick, onEditClick, elipse) => {

  const width = canView && canEdit ? '60px' : '130px';

  const AddNew = () => <Button style={button.addNew} size="small" onClick={() => onEditClick(0)}>ADD NEW</Button>;
  const View = ({id, data}) => <Button className='tableButton' style={{width:width}} size="small" onClick={() => onViewClick(id, data)}>VIEW</Button>;
  const Edit = ({ id, name }) => <Button className='tableButton' style={{width:width}} size="small" onClick={() => onEditClick(id, name)}>EDIT</Button>;

  const colArray = colProps.map((col) => {
    return {
      title: col.title,
      ellipsis: true,
      dataIndex: col.key,
      sorter: col.sorter,
      key: col.key,
      defaultSortOrder: col.sort,
      width: col.width,
      render: col.render
    };
  });

  colArray.push({
    title: (
      <div style={{ float: 'right' }}>
        {canEdit && <AddNew />}
      </div>
    ),
    key: "action",
    render: (record) => (
      <div style={{ float: 'right' }}>
        {canView && <View data={record} id={record.id} />}
        {canEdit && <Edit id={record.id} name={record.name} />}
      </div>
    ),
  });

  return colArray;
};

export default tableHeader;
