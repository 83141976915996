import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, MultiField } from "../fields";
import { Curtain } from "common";

const Page28 = () => {
  const page = 28;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = [
    "5.27",
    "5.28",
    "5.281",
    "5.29",
    "5.291",
    "5.30",
    "5.301",
  ];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v528, setV528] = useState("No");
  const [v529, setV529] = useState("No");
  const [v530, setV530] = useState("No");

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV528(result.answers[1].choice);
      setV529(result.answers[3].choice);
      setV530(result.answers[5].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      if (typeof (c) === 'object') c = c.join(',');
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <MultiField
            form={form}
            label="27. Which kind of transportation did you use?"
            question="c5.27"
            other="Other:"
            extension="e5.27"
            options={["I went everywhere on foot", "Bicycle", "Car", "Tram"]}
          />

          <CondField
            form={form}
            label="28.	Did you receive humanitarian aid?"
            question="c5.28"
            trigger="Yes"
            current={v528}
            options={["Yes", "No"]}
            subQuestion="c5.281"
            subLabel="28.1 If yes, what did you usually receive?"
          />
          <CondField
            form={form}
            label="29.	Did you experience any language barriers at the new place of residence?"
            question="c5.29"
            trigger="Yes"
            current={v529}
            options={["Yes", "No"]}
            subQuestion="c5.291"
            subLabel="29.1 If yes, please describe"
          />
          <CondField
            form={form}
            label="30.	Did you feel that your ability to speak the local language had an impact on how other people treated you?"
            question="c5.30"
            trigger="Yes"
            current={v530}
            options={["Yes", "No", "I don't know"]}
            subQuestion="c5.301"
            subLabel="30.1 If yes, please describe."
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page28;
