import React, { useEffect, useState } from 'react';
import { Layout, Popover } from 'antd';
import axios from 'axios';
import config from 'config';

const { Footer } = Layout;

const footer = {
  bottom: '0',
  position: 'flex',
  width: '100%',
  textAlign: 'center',
  height: '32px',
  fontSize: '0.9em',
  fontWeight: '500',
  paddingTop: '12px',
  borderTop: '1px solid #707070',
  color: '#f0f2f5',
  backgroundColor: '#1d1e1f'
};

const coord = (num) => {
  const d = Math.floor(num);
  const mn = (num - d) * 60;
  const m = Math.floor(mn);
  const s = Math.round((mn - m) * 60);
  return `${d}° ${m}' ${s}"`;
}

const AppFooter = () => {

  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [address, setAddress] = useState('');

  const handleLocation = () => {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(
      position => {
        setLat(coord(position.coords.latitude));
        setLon(coord(position.coords.longitude));
      },
      () => console.error('Unable to detect position'));
  }

  const handleIP = async () => {
    const response = await axios.get('https://api.ipify.org');
    setAddress(response.data);
  }

  useEffect(() => {
    handleLocation();
    handleIP();
  }, [lat, lon, address]);

  const year = new Date().getFullYear();
  const copyright = config.copyright;

  const Location = () => (
    <div>
      <div>Latitude: {lat}</div>
      <div>Longitude: {lon}</div>
      <div>IP Address: {address}</div>
    </div>
  );

  return (
    <Footer style={footer}>
      <Popover disabled={true} content={<Location />} title={<h3>LOCATION</h3>} trigger='click'>
        <span>Copyright &copy; {copyright}{year}. All rights reserved.</span>
      </Popover >
    </Footer>
  )
};

export default AppFooter;