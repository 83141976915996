import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  CondOptions,
  SingleField,
  TextField
} from "../fields";
import { Curtain } from "common";

const Page23 = () => {
  const page = 23;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["5.01", "5.02", "5.03", "5.04", "5.041"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v504, setV504] = useState("No");

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV504(result.answers[3].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <TextField
            form={form}
            label="1. How did you reach a new place of residence?"
            question="c5.01"
          />

          <TextField
            form={form}
            label="2. Whom did you travel with?"
            question="c5.02"
          />

          <SingleField
            form={form}
            label="3. How dangerous was the journey to your new place of residence?"
            question="c5.03"
            options={[
              "Very dangerous",
              "Somewhat dangerous",
              "Neither dangerous nor safe",
              "Somewhat safe",
              "Very safe",
              "I don't remember",
            ]}
          />

          <CondOptions
            form={form}
            label="4. Were you subjected to any violence on your journey to your new place of residence?"
            question="c5.04"
            trigger="Yes"
            current={v504}
            options={["Yes", "No", "Don’t know"]}
            subQuestion="c5.041"
            subLabel="4.1 If yes, what kind of violence?"
            subOptions={[
              "Physical violence",
              "Sexual violence",
              "Exploitation",
              "Abuse",
              "Don’t know",
            ]}
            subOther="Other (specify):"
            subExtension="e5.831"
            subMulti={true}
          />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page23;
