import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import moment from 'moment';
import { Table, Layout, Modal, message } from "antd";

import service from "services/apiServices";
import tableHeader from "services/tableService";
import config from "config";
import Note from './note';
import { isAdmin } from "services/authServices";

const colProps = [
  { key: "time", title: "Time", width: '12%', render: (time) => moment(time).format('YYYY-MM-DD') },
  { key: "comment", title: "Comment", width: '50%' },
  { key: "user", title: "User", width: '20%' }
];

const columns = ({ canView, canEdit, onViewClick, onEditClick }) =>
  tableHeader(
    colProps,
    canView,
    canEdit,
    onViewClick,
    onEditClick
  );

const { Content } = Layout;

const Notes = ({ story }) => {

  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list(`stories/${story}/notes`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [story]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const editNote = id => {
    if(id===0){
      setItem({ id: 0, time: Date.now(), comment: '', storyId: story, user: config.currentUser.name });
    } else {
      const note = _.find(data, { id: id });
      setItem({ id: note.id, time: note.time, comment: note.comment, storyId: story, user: note.user });  
    }
    showModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={isLoading}
            size="small"
            tableLayount={"fixed"}
            columns={columns({
              canView: false,
              canEdit: isAdmin(),
              onViewClick: {},
              onEditClick: (id) => editNote(id),
            })}
            dataSource={data}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
          <Modal width={'60%'} open={visible} title={<h2>N O T E</h2>} footer={[]} onCancel={hideModal}><Note item={item} onOk={refresh} /></Modal>
    </div>
  );
};

export default Notes;
