import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Input, Layout, Row, Col, Table } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

import { isAdmin } from 'services/authServices';
import service from 'services/apiServices';
import { Curtain, Delete, Title, Buttons } from "common";
import TableView from 'services/TableView';

const { TextArea } = Input;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'move', color: '#666' }} />
));

export default function ({ history }) {

  const [id, setId] = useState(parseInt(useParams().id));
  const [typeId] = useState(parseInt(useParams().type));
  const [loading, setLoading] = useState(true);
  const [subs, setSubs] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    const fetch = async (id) => {
      if (id === 0) {
        form.setFieldsValue({ id: 0, type: typeId });
      } else {
        const result = await service.list('tags?tag=' + id);
        form.setFieldsValue({ ...result, type: typeId });
        setSubs(result.subTags);
      }
      setLoading(false);
    };
    if (id !== '0') fetch(id);
    setLoading(false);
  }, [id, form, typeId]);

  useEffect(() => {
    if (id !== '0') fetch(id);
    setLoading(false);
  }, [id, form, typeId]);

  const onFailed = () => history.push('/tags');

  const onFinish = async (values) => {
    setLoading(true);
    const result = (values.id === 0) ? await service.insert('tags', values) : await service.update('tags', values, values.id);
    setLoading(false);
    if (values.id === 0) {
      setId(result.id);
      form.setFieldsValue({ id: result.id });
    } else {
      history.push('/tags');
    }
  }

  const onDelete = () => {
    history.push('/tags');
  }

  const addSub = () => {
    // ADD SUB FOR {id}
  } 

  const editSub = (record) => { 
    // EDIT SUB FOR {record.id}
  }

  const colProps = [
    { title: '', dataIndex: 'sort', width: '5%', className: 'drag-visible', render: () => <DragHandle /> },
    { key: "value", title: "English", width: '40%' },
    { key: "bhValue", title: "B C S", width: '40%' },
    { key: "operation", title: <Button type="primary" onClick={addSub}>Add</Button>, 
      width: '15%', align: 'center', render: (text, record) => <Button type="primary" onClick={() => editSub(record)}>Edit</Button> }
  ];

  const columns = () => TableView(colProps);

  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);

  const onSortEnd = async ({ newIndex, oldIndex }) => {
    if (oldIndex !== newIndex) {
      const dataSource = subs;
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      setLoading(true);
      const result = await service.insert('subtags/order', newData);
      setLoading(false);
      setSubs(result);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = subs;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  if (loading) return <Curtain />;
  return (<Layout className='container'>
    <Title align="center">Main Tag</Title>
    <hr style={{ marginBottom: '24px' }} />
    <Form form={form} layout='vertical' onFinish={onFinish} onFinishFailed={onFailed}>
      <Row>
        <Col span={9} offset={2}>
          <Form.Item label='English' name='value'>
            <TextArea autoSize />
          </Form.Item>
        </Col>
        <Col span={9} offset={2}>
          <Form.Item label='B C S' name='bhValue'>
            <TextArea autoSize />
          </Form.Item>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={6} offset={3}><Delete dataSet='tags' id={id} onFinish={onDelete} disabled={!isAdmin()} /></Col>
        <Col span={6}><Button style={Buttons.close} onClick={() => history.push('/tags')}>Close</Button></Col>
        {(!id || isAdmin()) && (<Col span={6}><Button style={Buttons.confirm} htmlType='submit'>SAVE</Button></Col>)}
      </Row>
      <Form.Item name='id' hidden><Input /></Form.Item>
      <Form.Item name='order' ><Input hidden /></Form.Item>
      <Form.Item name='type' hidden><Input /></Form.Item>
      <Form.Item label='Description' name='description' hidden><Input /></Form.Item>
      <Form.Item label='BH Description' name='bhDescription' hidden><Input.TextArea rows={6} /></Form.Item>
    </Form>

    <hr />
    <Table style={{ width: '70%', margin: '0 auto' }}
      loading={loading}
      title={() => <h2>Subtags</h2>}
      size="small"
      tableLayout={"fixed"}
      columns={columns()}
      dataSource={subs}
      pagination={false}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        }
      }}
    />
  </Layout>);
};