import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Popconfirm, message } from 'antd';
import service from 'services/apiServices';


const Delete = ({ dataSet, id, redirect, disabled, onFinish }) => {
 
  const history = useHistory();

  const button = {
    width: "120px",
    height: "32px",
    margin: "8px",
    borderRadius: "6px",
    fontWeight: "500",
    border: "1px solid black",
    backgroundColor: disabled ? 'grey' : 'red',
    color: 'white'
  };
  
  const onDelete = async () => {
    const result = await service.remove(dataSet, id);
    if (result.status === 207) {
      message.error(<h2>There are some problems with your operation.<br /><small>The object you tried to delete has children.</small></h2>);
    } else {
      message.success(<h2>The data successfully removed from the database.</h2>);
      if (redirect) history.push(redirect); else onFinish();
    }
  }

  if (id === 0) return <div />
  return (
    <Popconfirm title={<h3>Are you sure you want to delete these data？<br />{dataSet}, id={id}</h3>}
      okText="Yes"
      cancelText="No"
      disabled={disabled}
      onConfirm={() => onDelete()}>
      <Button style={button} htmlType="button">Delete</Button>
    </Popconfirm>
  )
}

export default Delete;