import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, SingleField, TextField, OneToFive } from "../fields";
import { Curtain } from "common";

const spanStyle = {
  border: '1px solid black',
  borderRadius: '50%',
  marginLeft: '12px',
  padding: '4px 8px',
  fontWeight: 'bold'
}

const Page31 = () => {
  const page = 31;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = [
    "7.01", "7.02", "7.03", "7.04", "7.05", "7.06", "7.07", "7.08", "7.09", "7.10", "7.11",
    "7.121", "7.122", "7.123", "7.124", "7.125", "7.126", "7.13"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.respondent);

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });


      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the period after the armed conflict.
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <TextField
            form={form}
            label="1.Were you at any point professionally engaging with the topics of war, war memorialization, and/or war consequences? (i.e. through research, book, movie, photography, reconciliation project, war crimes, etc.)"
            question="c7.01"
          />

          <SingleField
            form={form}
            label="2. How often do you think about the armed conflict? "
            question="c7.02"
            options={[
              "Daily",
              "Weekly",
              "Monthly",
              "Several times per year",
              "Rarely",
              "Never",
            ]}
          />
          <SingleField
            form={form}
            label="3. How often do you have dreams about the armed conflict?"
            question="c7.03"
            options={[
              "Daily",
              "Weekly",
              "Monthly",
              "Several times per year",
              "Rarely",
              "Never",
            ]}
          />
          <CondField
            form={form}
            label="4. What triggers your wartime memories?"
            question="c7.04"
            extension="e7.04"
            options={[
              "I remember it spontaneously (in everyday situations)",
              "When I'm with friends with whom I share that experience",
              "When I see traces of the armed conflict on streets, buildings, and people",
              "When I see items (books, movies) that remind me of the war",
              "People from my surroundings often mention the war",                        // ovo ne znam. nije zavrseno
              "War-related content in the media",
              "War-related content on social media",
              "Dates and anniversaries that remind me of the war",
            ]}
            other="Other:"
          />
          <SingleField
            form={form}
            label="5. How often do you talk about armed conflict?"
            question="c7.05"
            options={[
              "Daily",
              "Weekly",
              "Monthly",
              "Several times per year",
              "Rarely",
              "Never",
            ]}
          />
          <SingleField
            form={form}
            label="6. With whom do you mostly talk about armed conflict?"
            question="c7.06"
            extension="e7.06"
            options={[
              "With my partner",
              "With my children",
              "With my parents",
              "With my sibling(s)",
              "With my friends",
              "With friends from the war with whom I share the experience of growing up in war",
              "With work colleagues",
              "With my therapist",
            ]}
            other="Other:"
          />
          <SingleField
            form={form}
            label="7. Does talking about armed conflict or wartime memories affect your daily activities?"
            question="c7.07"
            options={[
              "Yes, overwhelmingly",
              "Yes, quite a bit",
              "Yes, to some degree",
              "No",
              "I don't know",
            ]}
          />

          <SingleField
            form={form}
            label="8. How often do you read/listen to or watch materials about armed conflict besides everyday news in the media (texts, movies, TV footage, photos, music)?"
            question="c7.08"
            options={[
              "Daily",
              "Weekly",
              "Monthly",
              "Several times per year",
              "Rarely",
              "Never",
            ]}
          />

          <TextField
            form={form}
            label="9. Do you have an object (a letter, photograph, notebook, drawing, etc.) that reminds you of the period of armed conflict? List those items:"
            question="c7.09"
            subQuestion="c7.091"                                 // ovo pitanje ne znam. nije zavrseno
            subLabel="9.1 If yes, where do you keep them?"
          />
          <SingleField
            form={form}
            label="10. Do you keep some of your documents, photographs, or other objects on display?"
            question="c7.10"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="11. How often do you look at the objects from that period? "
            question="c7.11"
            options={[
              "Daily",
              "Weekly",
              "Monthly",
              "Several times per year",
              "Rarely",
              "The first time I took them out is when I received a call from the War Childhood Museum",
            ]}
          />

          <h3>12. How do you feel when you look at these objects? Rate the following emotions:</h3>
          <h4>
            <span style={spanStyle}>1</span> - I don't feel it;
            <span style={spanStyle}>2</span> - I feel it a bit;
            <span style={spanStyle}>3</span> - I feel it slightly;
            <span style={spanStyle}>4</span> - I  feel it significantly;
            <span style={spanStyle}>5</span> - I feel it strongly
          </h4>

          <OneToFive label="12.1 I feel happy" question="c7.121" />
          <OneToFive label="12.2 I feel relief" question="c7.122" />
          <OneToFive label="12.3 I feel proud" question="c7.123" />
          <OneToFive label="12.4 I feel nostalgic" question="c7.124" />
          <OneToFive label="12.5 I feel sad" question="c7.125" />
          <OneToFive label="12.6 I feel angry" question="c7.126" />

          <SingleField
            form={form}
            label="13. None of the above:"
            question="c7.13"
            extension="e7.13"
            options={[
              "I have mixed feelings; it's hard to define/rank them",
              "I don't feel anything",
            ]}
            other="Other:"
          />
        </Form>

        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page31;
