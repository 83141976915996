import React, { useState } from 'react';
import { Drawer, Form, Row, Col, message, Select } from "antd";
import { StoryView } from "components/stories";
import service from 'services/apiServices';
import config from 'config';


const ExhibitionStories = ({ id, stories, select }) => {

  const [showStory, setShowStory] = useState(false);
  const [storyId, setStoryId] = useState(0);
  const [form] = Form.useForm();

  const storySelected = values => {}

  const onFinish = values => {}

  const onFailed = () => message.error(config.message);

  return (
    <>
      <Row>
        <Col span={10} offset={2}>
          <Form form={form} layout='vertical' onFinish={onFinish} onFinishFailed={onFailed}>
            <Form.Item label="Story" name="storyId">
              <Select className="stepFormField" mode="combobox"
                onSelect={storySelected}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => service.anything(input, option.children)}
                style={{ textAlign: 'left' }}>
                {select.map(s => (<Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>))}
              </Select>
            </Form.Item>
          </Form>
          <ul>
            {stories && stories.length !== 0 && stories.map(s =>
              <li key={s.id} className='ex-story-list'
                onClick={() => { if (s.story.id !== 0) { setShowStory(true); setStoryId(s.story.id); } }}>
                {s.story.name} by {s.story.desc}
              </li>)}
          </ul>
        </Col>
      </Row>

      <Drawer title={<h2>Story Detail View</h2>}
        width={'60%'}
        closable={true}
        onClose={() => setShowStory(false)}
        destroyOnClose={true}
        open={showStory}>
        <StoryView id={storyId} />
      </Drawer>
    </>
  )
}

export default ExhibitionStories;