import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import config from 'config';
import columns from './columns';
import apiServices from 'services/apiServices';

const ObjectCorrect = () => {

  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);

  const getObjectReports = async () => {
    setLoad(true);
    const response = await apiServices.list('reports/wrong-objects');
    setLoad(false);
    if (response) setData(response);
  }

  useEffect(() => {
    getObjectReports();
    config.activeKry = '2';
  }, []);

  const tableTitle = () => (<h2>Wrong reports / {data.length}</h2>)


  return (
    <Table
      loading={load}
      title={tableTitle}
      size="small"
      tableLayout={"fixed"}
      scroll={{ y: '55vh' }}
      columns={columns({ canView: false, canEdit: false, editUrl: 'correct' })}
      dataSource={data}
      //onRow={(record, index) => ({ onClick: () => { setView(record.id, index); } })}
      pagination={false}
      rowKey={(row) => row.id} />
  )
}

export default ObjectCorrect;