import React, { useState, useEffect } from "react";
import { Form, Button, Input, Radio, Layout, Row, Col, message, DatePicker, Tabs } from "antd";
import { useParams } from "react-router-dom";
import moment from 'moment';

import { Curtain, Title, Buttons, Delete } from 'common';
import { isAdmin } from "services/authServices";
import config from "config";
import apiServices from "services/apiServices";
import service from "services/apiServices";
import ExhibitionStories from "./ExhibitionStories";

const { TabPane } = Tabs;
const { TextArea } = Input;

const fullDate = datum => {
  let dat = moment(datum);
  if (dat.year() === 1) return undefined;
  return dat;
}

const TabHead = ({ children }) => {
  return <span style={{ fontSize: '1.5em' }}>{children}</span>;
}

const ExhibitionEdit = ({ history }) => {
  
  if (!isAdmin()) history.push("/exhibitions");
  const [id] = useState(parseInt(useParams().id));
  const [loading, setLoading] = useState(true);
  const [editHistory, setEditHistory] = useState("");
  const [select, setSelect] = useState([]);
  const [form] = Form.useForm();
  const [stories, setStories] = useState([]);

  const getExhibition = async id => {
    try {
      if (id === 0) {
        form.setFieldsValue({ id: 0 });
      }
      else {
        const result = await apiServices.read('exhibitions', id);
        form.setFieldsValue({
          ...result,
          openingDate: fullDate(result.openingDate),
          closingDate: fullDate(result.closingDate),
          address: result.address.street,
          zip: result.address.zip,
          city: result.address.city,
          country: result.address.country
        });
        setEditHistory(result.editHistory);
        setStories(result.stories);
      }
    }
    catch (error) {
      console.error(error);
      message.error(config.message);
    }
    finally {
      setLoading(false);
    }
  }

  const getStories = async () => {
    try {
      setLoading(true);
      const result = await service.list("master/stories");
      setSelect(result);
    }
    catch (error) {
      console.error(error);
      message.error(config.message);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getExhibition(id);
    getStories();
    // eslint-disable-next-line
  }, [id]);

  const onFinish = async (values) => {
    const data = {
      ...values,
      openingDate: values.openingDate ? moment(values.openingDate).format('LL') : new Date('0001-01-01'),
      closingDate: values.closingDate ? moment(values.closingDate).format('LL') : new Date('0001-01-01'),
      address: { street: values.address, zip: values.zip, city: values.city, country: values.country }
    }
    setLoading(true);
    const result = id === 0 ? await service.insert('exhibitions', data) : await service.update('exhibitions', data, id);
    if (result) message.success("Exhibition successfully updated in the database.");
    setLoading(false);
    history.push('/exhibitions');
  }

  const onFailed = () => message.error(config.message);

  const onDelete = () => history.push('/exhibitions');

  if (loading) return <Curtain />;
  return (
    <Layout className="container">
      <Form form={form} layout='vertical' onFinish={onFinish} onFinishFailed={onFailed}>
        <Title align="center">Exhibition Edit {id !== "0" && <span>[{id}]</span>}</Title>
        <div style={{ textAlign: 'center', marginBottom: '12px' }}>{editHistory}</div>
        {config.currentUser.canDelete && <Delete dataSet='exhibitions' id={id} onFinish={onDelete} />}
        <Button style={Buttons.close} onClick={() => history.push('/exhibitions')}>Close</Button>
        <Button style={Buttons.confirm} htmlType='submit'>SAVE</Button>
        <div className='divider' />
        <Row>
          <Col span={5}>
            <Form.Item label="Id" name="id" hidden><Input /></Form.Item>
            <Form.Item label="Exhibition Type" name="type" style={{ textAlign: 'left' }}>
              <Radio.Group buttonStyle='solid' size='small'>
                <Radio.Button value='OnSite'>On Site</Radio.Button>
                <Radio.Button value='OnLine'>On Line</Radio.Button>
                <Radio.Button value='VR'>VR</Radio.Button>
                <Radio.Button value='Other'>Other</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Title" name="title">
              <TextArea className="stepFormField" autoSize={{ minRows: 1, maxRows: 3 }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Opening Date" name="openingDate">
              <DatePicker className="stepFormField" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Closing Date" name="closingDate">
              <DatePicker className="stepFormField" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5}><Form.Item label="Venue" name="venue"><Input className="stepFormField" /></Form.Item></Col>
          <Col span={5}><Form.Item label="Address" name="address"><Input className="stepFormField" /></Form.Item></Col>
          <Col span={2}><Form.Item label="ZIP" name="zip"><Input className="stepFormField" /></Form.Item></Col>
          <Col span={4}><Form.Item label="City" name="city"><Input className="stepFormField" /></Form.Item></Col>
          <Col span={8}><Form.Item label="Country" name="country"><Input className="stepFormField" /></Form.Item></Col>
        </Row>
      </Form>

      <div className='divider' />

      <Row>
        <Col span={23} offset={1}>
          <Tabs type='card' className='tabFrame' >
            <TabPane tab={<TabHead>Stories</TabHead>} key="1" className='tabPane'>
              <ExhibitionStories exhibition={id} stories={stories} select={select} />
            </TabPane>
            <TabPane tab={<TabHead>Photos</TabHead>} key="3" className='tabPane'>
              <h1>Photos to come</h1>
            </TabPane>
            <TabPane tab={<TabHead>Attachments</TabHead>} key="2" className='tabPane'>
              <h1>Attachments to come</h1>
            </TabPane>
            <TabPane tab={<TabHead>Notes</TabHead>} key="4" className='tabPane'>
              <h1>Notes to come</h1>
            </TabPane>
            <TabPane tab={<TabHead>Shipping</TabHead>} key="6" className='tabPane'>
              <h1>Shipping Section to come</h1>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Layout>
  )
}

export default ExhibitionEdit;