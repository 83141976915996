import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import Navigator from "./navigator";
import { useDispatch, useSelector } from "react-redux";
import { setRespondent } from "store/respondentSlice";
import { TextField, NumberField, SingleField } from "./fields";
import { Curtain } from "common";

const Personal = () => {

  const page = 2;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const respondent = useSelector(state => state.respondent);

  useEffect(() => {
    form.setFieldsValue({ ...respondent });
    // eslint-disable-next-line
  }, [respondent]);

  const onFinish = async values => {
    if (values.id === undefined) values.id = 0;
    setLoading(true);
    const result = await apiServices.insert('quest', { ...values, page: page });
    setLoading(false);
    if (result) {
      dispatch(setRespondent(result));
    }
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">Personal data</div>

        <Form form={form} onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>
          <Form.Item label="Code" name="code" hidden><Input /></Form.Item>

          <TextField form={form} label="Name and surname:" question="name" />
          <NumberField form={form} label="Year of birth:" question="birthYear" />
          <TextField form={form} label="Place of birth:" question="birthPlace" />
          <TextField form={form} label="Email address:" question="email" />
          <TextField form={form} label="Phone number:" question="phone" />
          <SingleField form={form} label="Prefered contact method:" question="contact"
            options={["E-mail", "SMS", "Phone call", "No commmunication"]} />
          <TextField form={form} label="Country of origin:" question="originCountry" />
          <TextField form={form} label="Country of residence (if different from the country of origin):" question="residenceCountry" />
          <SingleField form={form} label="Gender:" question="description"
            options={[
              "Male",
              "Female",
              "Other"]} />
          <SingleField form={form} label="Sexual orientation:" question="sexual" extension="sexDesc" other="Not listed (please specify)"
            options={[
              "Heterosexual or straight",
              "Gay",
              "Lesbian",
              "Bisexual"]} />
          <SingleField form={form} label="Level of education:" question="education"
            options={[
              "primary education",
              "secondary education",
              "vocational education",
              "undergraduate",
              "graduate",
              "post-graduate",
              "no formal education"]} />
          <SingleField form={form} label="Employment status:" question="employment"
            options={[
              "employed in private sector",
              "employed in public sector",
              "employed in NGO sector",
              "employed in international organizations",
              "part-time job",
              "freelancer",
              "owns a business",
              "student",
              "unemployed"
            ]} />
          <SingleField form={form} label="Martial status:" question="martial"
            options={[
              "married",
              "civil partnership",
              "living with a partner in a commited relationship",
              "in a commited relationship but not living together",
              "single",
              "divorced",
              "widowed"
            ]} />
          <SingleField form={form} label="Are you a parent?" question="parent" options={["Yes", "No"]} />
          <SingleField form={form} label="Do you have a physical or mental disability?" question="disabled" options={["Yes", "No"]} />
          <SingleField form={form} label="What religious family do you identify yourself most close to?"
            question="religious" extension="relDesc" other="Other (please specify)"
            options={[
              "Buddhist",
              "Christian",
              "Hindu",
              "Muslim",
              "Jewish",
              "None, I am not religious"
            ]} />
          <SingleField form={form} label="Please describe your living situation:" question="living"
            options={[
              "Living with parent(s)",
              "Living alone in own home (house, apartment, condo, etc.)",
              "Living alone in a rented home (house, apartment, condo, etc.)",
              "Living with a partner in own home (house, apartment, condo, etc.)",
              "Living with a partner in a rented home (house, apartment, condo, etc.)",
              "Staying with relative(s) or friend(s)",
              "Staying in a shelter",
              "No permanent residence",
              "Other"]} />
        </Form>
        <Navigator prev={page-1} next={page+1} save={() => form.submit()} />
      </div >
    </>
  );
}

export default Personal;