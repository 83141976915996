const TableView = (colProps) => {

  const colArray = colProps.map((col) => {
    return {
      title: col.title,
      ellipsis: true,
      dataIndex: col.key,
      key: col.key,
      width: col.width,
      render: col.render,
      align: col.align
    };
  });

  return colArray;
};

export default TableView;
