import React, { useState, useEffect } from 'react';
import { Form, Input, Switch, Layout, Radio, Button, Row, Col, Checkbox, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import slugify from 'slugify';

import service from 'services/apiServices';
import { isSuper } from 'services/authServices'
import { Title, Delete } from 'common';

const CheckboxGroup = Checkbox.Group;

const { Content } = Layout;

const button = {
  width: '150px',
  height: "36px",
  margin: "8px 16px",
  borderRadius: "6px",
  fontWeight: "500",
  border: "none",
  backgroundColor: "#ddc7b0",
  color: "#211e1a"
};

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }
const checkLay = { labelCol: { span: 16 }, wrapperCol: { span: 8 } }

const User = () => {

  const history = useHistory();
  if (!isSuper()) history.push("/home");


  let key = 0;
  const { id } = useParams();

  const [userName, setName] = useState('unknown');
  const [nocheck, setNocheck] = useState(false);
  const [usersel, setUsersel] = useState(false);
  const [dupUser, setDupUser] = useState(false);
  const [dupMail, setDupMail] = useState(false);
  const [conflicts, setConflicts] = useState([]);
  const [locked, setLocked] = useState(false);

  const [form] = Form.useForm();

  const setAccess = role => {
    switch (role) {
      case 'SuperAdmin':
        form.setFieldsValue({ delParticipants: true, delStories: true, delObjects: true, delParts: true, delHistories: true });
        setUsersel(true);
        setNocheck(true);
        break;
      case 'Admin':
        setUsersel(false);
        setNocheck(false);
        break;
      case 'User':
        form.setFieldsValue({ delParticipants: false, delStories: false, delObjects: false, delParts: false, delHistories: false });
        setUsersel(true);
        setNocheck(true);
        break;
      case 'Intern':
        form.setFieldsValue({ delParticipants: false, delStories: false, delObjects: false, delParts: false, delHistories: false });
        setUsersel(true);
        setNocheck(false);
        break;
      default:
        form.setFieldsValue({ delParticipants: false, delStories: false, delObjects: false, delParts: false, delHistories: false });
        setUsersel(true);
        setNocheck(true);
        break;
    }
  }

  const fetch = async () => {
    let accList = [];
    const master = await service.list('master/conflicts');
    setConflicts(master);
    if (parseInt(id) === 0) {
      form.setFieldsValue({ mfa: true });
    } else {
      const result = await service.read('users', id);
      if (result) {
        setName(result.fullName);
        accList = result.conflicts.split(',');
        form.setFieldsValue({ ...result, accList: accList.map(a => parseInt(a)) });
        setAccess(result.role);
        setLocked(result.status === 'Locked');
      }
    }
  };
  
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const onBack = () => { history.push('/users'); };

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    const conflicts = values.accList ? values.accList.join() : "0";
    let result = false;
    if (id === "0") {
      result = await service.insert('users', { ...values, conflicts });
    } else {
      result = await service.update('users', { ...values, id, conflicts }, id);
    }
    if (result) {
      history.push('/users');
    }
  };

  const unlock = async () => {
    await service.read('users/unlock', id);
    fetch();
  }

  const getSlug = async e => {
    const firstName = form.getFieldValue('firstName');
    const lastName = form.getFieldValue('lastName');
    const slug = slugify(`${firstName} ${lastName}`, '.').toLowerCase();
    form.setFieldsValue({ username: slug });
    const result = await service.list(`users/u/${slug}/${id}`);
    setDupUser(result);
  }

  const getUsername = async e => {
    const slug = form.getFieldValue("username");
    const result = await service.list(`users/u/${slug}/${id}`);
    setDupUser(result);
  }
  const roleChanged = e => setAccess(e.target.value);

  const Duplicate = ({ what }) => {
    if (what === "username") {
      return dupUser ? <CloseCircleOutlined style={{ color: 'red' }} /> : <CheckCircleOutlined style={{ color: 'green' }} />;
    } else {
      return dupMail ? <CloseCircleOutlined style={{ color: 'red' }} /> : <CheckCircleOutlined style={{ color: 'green' }} />;
    }
  }

  const emailChanged = async e => {
    const slug = form.getFieldValue("email");
    const result = await service.list(`users/e/${slug}/${id}`);
    setDupMail(result);
  }

  const selectAll = how => {
    let accList = form.getFieldValue('accList');
    if (accList.length === conflicts.length && !how) {
      accList = [];
    } else {
      accList = conflicts.map(c => c.id);
    }
    form.setFieldsValue({ accList });
  }

  return (
    <Layout className="container">
      <Content align="center">
        <Title align='center'>{(id === '0') ? 'ADD NEW' : userName}</Title>

        <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Row><Col span={24}><hr style={{ marginBottom: '24px' }} /></Col></Row>
          <Row>
            <Col span={8}>
              <Form.Item label='First name' name='firstName'><Input onChange={getSlug} /></Form.Item>
              <Form.Item label='Last name' name='lastName'><Input onChange={getSlug} /></Form.Item>
              <Form.Item label='E-mail address' name='email'>
                <Input suffix={<Duplicate what="email" />} onChange={emailChanged} />
              </Form.Item>
              <Form.Item label='Username' name='username'>
                <Input suffix={<Duplicate what="username" />} onChange={getUsername} />
              </Form.Item>
              <Form.Item label='Landing page' name='landing'><Input /></Form.Item>
              <Form.Item label='Institution' name='institution'><Input /></Form.Item>
              <Form.Item label='Reason for access' name='reason'>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col span={4} offset={1} align='left'>
              <Form.Item label='Role' name='role'>
                <Radio.Group buttonStyle='solid' size='small' onChange={roleChanged}>
                  <Space direction="vertical">
                    <Radio value='SuperAdmin'>SuperAdmin</Radio>
                    <Radio value='Admin'>Admin</Radio>
                    <Radio value='User'>User</Radio>
                    <Radio value='Intern'>Intern</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <hr />
              <h2>USER CAN DELETE</h2>
              <Form.Item label="Participants" name="delParticipants" valuePropName="checked" {...checkLay} className="user-switch">
                <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" disabled={usersel} />
              </Form.Item>
              <Form.Item label="Stories" name="delStories" valuePropName="checked" {...checkLay} className="user-switch">
                <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" disabled={usersel} />
              </Form.Item>
              <Form.Item label="Objects" name="delObjects" valuePropName="checked" {...checkLay} className="user-switch">
                <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" disabled={usersel} />
              </Form.Item>
              <Form.Item label="Parts" name="delParts" valuePropName="checked" {...checkLay} className="user-switch">
                <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" disabled={usersel} />
              </Form.Item>
              <Form.Item label="Oral history" name="delHistories" valuePropName="checked" {...checkLay} className="user-switch">
                <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" disabled={usersel} />
              </Form.Item>
            </Col>
            <Col span={4} offset={1} align='left'>
              <Form.Item label='Status' name='status'>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Space direction="vertical">
                    <Radio value='Inactive'>Inactive</Radio>
                    <Radio value='Active'>Active</Radio>
                    <Radio value='Locked'>Locked</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {locked &&
                <Button type="primary" style={{ width: '75%' }} onClick={unlock}>
                  UNLOCK
                </Button>
              }
              <Form.Item label="2FA" name="mfa" className="SwitchLogin" valuePropName="checked" style={{ marginTop: '64px' }}>
                <Switch size='small' className="user-switch" checkedChildren="YES" unCheckedChildren="NO" />
              </Form.Item>
            </Col>
            <Col span={6} align='left'>
              <div className='left' style={{ fontSize: '1.2rem' }}>Access List</div>
              <div className='right'><Button onClick={selectAll} disabled={nocheck}>ALL</Button></div>
              <div style={{ clear: 'both' }} />
              <hr />
              <Form.Item label='' name='accList'>
                <CheckboxGroup disabled={nocheck}>
                  {conflicts.map(c => <div key={key++}><Checkbox value={c.id} style={{ width: '320px' }}>{c.name}</Checkbox></div>)}
                </CheckboxGroup>
              </Form.Item>
            </Col>
          </Row>
          <Row><Col span={24}><hr /></Col></Row>
          <Row>
            <Col span={4} offset={6}><Delete dataSet='users' id={parseInt(id)} redirect='/users' /></Col>
            <Col span={4}><Button style={button} onClick={onBack}>Back</Button></Col>
            <Col span={4}><Button style={button} htmlType='submit' disabled={dupMail || dupUser}>SAVE</Button></Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default User;
