import React, { useState, useEffect } from 'react';
import { Divider, Form, Select, InputNumber, Button, Row, Col } from 'antd';

const Storage = ({ code, codes, onOk, onCancel }) => {

  const [inter, setInter] = useState(code);
  const [form] = Form.useForm();

  useEffect(() => {
    const parts = code ? code.split('.') : [];
    if (parts.length > 1) {
      form.setFieldsValue({
        country: parts[0] || '0',
        city: parts[1] || '0',
        building: parts[2] || '0',
        room: parts[3] || '0',
        type: parts[4].substring(0, 1) || 'C',
        closet: parts[4].substring(1) || '0',
        shelf: parts[5] || '0',
        box: parts[6] || '0',
        tray: parts[7] || '0'
      });
    };
    // eslint-disable-next-line
  }, [code]);

  const submit = values => {
    onOk(inter);
  }

  const changed = e => {
    let country = Number(form.getFieldValue("country"));
    let city = Number(form.getFieldValue("city"));
    let building = Number(form.getFieldValue("building"));
    let room = form.getFieldValue("room");
    let type = form.getFieldValue("type") || "C";
    let closet = Number(form.getFieldValue("closet") || "1");
    if (closet < 1) closet = 1;
    let shelf = Number(form.getFieldValue("shelf") || "0");
    if (shelf < 0) shelf = 0;
    let box = Number(form.getFieldValue("box") || "0");
    if (box < 0) box = 0;
    let tray = Number(form.getFieldValue("tray") || "0");
    if (tray < 0) tray = 0;

    let newCode = country + ".";
    newCode += city + ".";
    newCode += building + ".";
    newCode += room + ".";
    newCode += type + closet + "."
    newCode += shelf + "."
    newCode += box === 0 ? "*" : box;
    newCode += "."
    newCode += tray === 0 ? "*" : tray;
    setInter(newCode);
  }

  return (
    <>
      <h2 className="left">Storage {inter}</h2>
      <div className="right">
        <button onClick={() => onOk("")}>CLEAR CODE</button>
      </div>
      <Divider />

      <Form form={form} onFinish={submit}>
        <Row>
          <Col span={12} style={{ borderRight: '1px solid gray' }}>
            <Row>
              <Col span={6} align="right">
                Country:
              </Col>
              <Col span={17}>
                <Form.Item label="" name="country" initialValue="">
                  <Select mode="combobox"
                    optionFilterProp="children"
                    onSelect={changed}>
                    {codes.countries.map(c => (<Select.Option key={c.code} value={c.code}>{c.value}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={6} align="right">
                City:
              </Col>
              <Col span={17}>
                <Form.Item label="" name="city" initialValue="">
                  <Select mode="combobox"
                    optionFilterProp="children"
                    onSelect={changed}>
                    {codes.cities.map(c => (<Select.Option key={c.code} value={c.code}>{c.value}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={6} align="right">
                Building:
              </Col>
              <Col span={17}>
                <Form.Item label="" name="building" initialValue="">
                  <Select mode="combobox"
                    optionFilterProp="children"
                    onSelect={changed}>
                    {codes.buildings.map(b => (<Select.Option key={b.code} value={b.code}>{b.value}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={6} align="right">
                Room:
              </Col>
              <Col span={17}>
                <Form.Item label="" name="room" initialValue="">
                  <Select mode="combobox"
                    optionFilterProp="children"
                    onSelect={changed}>
                    {codes.rooms.map(r => (<Select.Option key={r.code} value={r.code}>{r.value}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>

            <Row>
              <Col span={8} align="right">
                Closet/Open:
              </Col>
              <Col span={8}>
                <Form.Item name="type" label="" initialValue="C">
                  <Select onSelect={changed} options={[{ value: 'C', label: 'Closet' }, { value: 'O', label: 'Open' }]} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="closet" label="" initialValue="1">
                  <InputNumber onChange={changed} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={8} align="right">
                Shelf:
              </Col>
              <Col span={16}>
                <Form.Item name="shelf" label="" initialValue="0">
                  <InputNumber onChange={changed} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={8} align="right">
                Box:
              </Col>
              <Col span={16}>
                <Form.Item name="box" label="" initialValue="0"><InputNumber onChange={changed} /></Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={8} align="right">
                Tray/Folder:
              </Col>
              <Col span={16}>
                <Form.Item name="tray" label="" initialValue="0"><InputNumber onChange={changed} /></Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24} align="right">
            <Button type="default" style={{ margin: 6 }} onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType='submit' style={{ margin: 6 }}>OK</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Storage;