import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Input, Layout, Row, Col } from 'antd';
import { isAdmin } from 'services/authServices';
import service from 'services/apiServices';
import { Curtain, Delete, Title, Buttons } from "common";

const layout = { labelCol: { span: 4 }, wrapperCol: { span: 19 } };

export default function ({ history }) {

  if (!isAdmin()) history.push("/langs");

  const [id] = useState(parseInt(useParams().id));
  const [typeId] = useState(parseInt(useParams().type));
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetch = async (id) => {
      if (id === 0) {
        form.setFieldsValue({ id: 0, type: typeId });
      } else {
        const result = await service.list('tags?tag=' + id);
        form.setFieldsValue({ ...result, type: typeId });
      }
      setLoading(false);
    };
    if (id !== '0') fetch(id);
    setLoading(false);
  }, [id, form, typeId]);

  const onFailed = () => history.goBack();

  const onFinish = async (values) => {
    setLoading(true);
    if (values.id === 0)
      await service.insert('tags', values);
    else
      await service.update('tags', values, values.id);
    setLoading(false);
    history.push('/langs');
  }

  const onDelete = () => {
    history.push('/langs');
  }

  if (loading) return <Curtain />;
  return (
    <Layout className='container'>
      <Title align="center">Language</Title>
      <hr style={{margin:'24px'}} />
      <Form form={form} {...layout} onFinish={onFinish} onFinishFailed={onFailed}>
        <Form.Item name='id' hidden><Input /></Form.Item>
        <Form.Item name='order' ><Input hidden /></Form.Item>
        <Row>
          <Col span={12} offset={6}>
            <Form.Item label='Value' name='value'><Input /></Form.Item>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={24}>
            <Delete dataSet='tags' id={id} onFinish={onDelete} />
            <Button style={Buttons.close} onClick={() => history.goBack()}>Close</Button>
            <Button style={Buttons.confirm} htmlType='submit'>SAVE</Button>
          </Col>
        </Row>
        <Form.Item name='type' hidden><Input /></Form.Item>
        <Form.Item label='Description' name='description' hidden><Input.TextArea rows={8} /></Form.Item>
        <Form.Item label='BH Value' name='bhValue' hidden><Input /></Form.Item>
        <Form.Item label='BH Description' name='bhDescription' hidden><Input.TextArea rows={6} /></Form.Item>
      </Form>
    </Layout>);
};