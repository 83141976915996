import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondOptions, SingleField, MultiField } from "../fields";
import { Curtain } from "common";

const Page11 = () => {

  const page = 11;
  const [loading, setLoading] = useState(false);
  const qn = ["3.67", "3.68", "3.681", "3.69", "3.70", "3.701"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent);
  const [v368, setV368] = useState("No");
  const [v370, setV370] = useState("No");

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV368(result.answers[1].choice);
      setV370(result.answers[4].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      };
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      if (typeof (c) === 'object') c = c.join(',');
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <MultiField form={form} label="67. Which kind of transportation did you use during armed conflict?"
            question="c3.67" other="Other:" extension="e3.67"
            options={["I went everywhere on foot", "Bicycle", "Car", "Tram"]} />

          <CondOptions form={form} label="68.	Did you receive any packages from abroad?"
            question="c3.68" options={["Yes", "No", "I don’t remember"]}
            trigger="Yes" current={v368}
            subLabel="68.1 If yes, what did the package contain?" subQuestion="c3.681" subExtension="e3.681"
            subOther="Other:" subMulti={true}
            subOptions={[
              "Food",
              "Sanitary products",
              "Cosmetics",
              "School supplies",
              "Toys",
              "Candy",
              "Clothing",
              "I don’t remember"
            ]} />

          <SingleField form={form} label="69.	How did you obtain clothing and shoes?"
            question="c3.69" other="Other:" extension="e3.69"
            options={[
              "I wore my older brother/sister’s clothes and shoes",
              "My neighbors/friends gave them to me",
              "I received them in humanitarian aid",
              "I received them in a package from abroad",
              "I was wearing military clothing and boots",
              "We sewed our clothes and wore improvised shoes",
              "I bought my clothes and shoes",
              "I don’t remember"
            ]} />

          <CondOptions form={form} label="70.	Were you a member of any military/paramilitary organization as a minor?"
            question="c3.70" options={["Yes", "No"]}
            trigger="Yes" current={v370}
            subLabel="70.1 If yes, how would you describe your willingness to join this military/paramilitary?" subQuestion="c3.701"
            subOptions={["Completely voluntary", "Somewhat voluntary", "Somewhat involuntary", "Completely involuntary"]} />
            
        </Form>
        <Navigator prev={page-1} next={110} save={() => form.submit()} />
      </div>
    </>
  );
}

export default Page11;
