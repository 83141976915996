import React from 'react';
import { EditOutlined } from '@ant-design/icons';

const LocationItem = ({ item, onEdit }) => {

  return (
    <>
      <div className="left">
        {item.code}: {item.value}
      </div>
      <div className="right">
        <EditOutlined style={{ fontSize: 24 }} onClick={() => onEdit(item.id)} />
      </div>
    </>
  );
}

export default LocationItem;