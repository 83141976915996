import React from 'react';

const Curtain = () => {
  return (
    <div className="curtain">
      <div className="circle" />
    </div>
  );
};

export default Curtain;