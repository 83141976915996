import React, { useEffect, useState } from "react";
import { Divider, Form, Input, InputNumber, Radio, Row, Col, Button } from "antd";
import apiServices from "services/apiServices";
import { setLocation, setPage, setPlace } from "store/pageSlice";
import { setRespondent } from "store/respondentSlice";
import { useDispatch, useSelector } from "react-redux";
import { Curtain } from "common";

const Locations = () => {

  const page = 110;
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent);

  const fetchData = async () => {
    setLoading(true);
    form.setFieldValue("id", id);
    const result = await apiServices.read('locations', id);
    setLoading(false);
    if (result) {
      if (result.length !== 0) {
        form.setFieldValue("c371", "Yes");
        form.setFieldValue("c3711", result.length);
      } else {
        form.setFieldValue("c371", "No");
        form.setFieldValue("c3711", 0);
      }
      setCount(result.length);
      for (let i = 0; i < result.length; i++) {
        if (result[i] !== undefined) {
          form.setFieldValue(`locid${i}`, result[i].id);
          form.setFieldValue(`inside${i}`, result[i].inside);
          form.setFieldValue(`affected${i}`, result[i].affected);
          form.setFieldValue(`city${i}`, result[i].city);
          form.setFieldValue(`period${i}`, result[i].period);
        }
      }
    }
  }

  useEffect(() => {
    fetchData(id);
    // eslint-disable-next-line
  }, []);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < count; i++) {
      let locId = form.getFieldValue(`locid${i}`);
      let inside = form.getFieldValue(`inside${i}`);
      let city = form.getFieldValue(`city${i}`);
      let period = form.getFieldValue(`period${i}`);
      let affected = form.getFieldValue(`affected${i}`);
      answers.push({
        id: locId,
        order: i + 1,
        inside,
        city,
        period,
        affected
      });
    }
    setLoading(true);
    const result = await apiServices.update("locations", answers, id);
    setLoading(false);
    dispatch(setRespondent(result));
    if(result.locations.length === 0){
      dispatch(setPage(30));
      dispatch(setPlace(result.place));
    } else {
      dispatch(setLocation(result.locations[0].id));
      dispatch(setPlace(result.locations[0].city + ", period " + result.locations[0].period));  
      if(result.locations[0].affected){
        dispatch(setPage(12));
      } else {
        dispatch(setPage(23));
      }
    }
  };

  const locationsChanged = e => {
    if (e.target.value === "No") {
      form.setFieldValue("c3711", 0);
      setCount(0);
    }
  }

  const changeCount = e => {
    if (e > 0) setCount(e); else setCount(0);
  }

  const goPrev = async () => {
    form.submit();
  }

  const goNext = async () => {
    form.submit();
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <Form.Item label="71.	Have you had to change a place of residence because of armed conflict?" name="c371">
            <Radio.Group size="small" onChange={locationsChanged}>
              <Radio key="Yes" value="Yes">Yes</Radio>
              <Radio key="No" value="No">No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="71.1 If yes, how many times?" name="c3711">
            <InputNumber size="small" onChange={changeCount} />
          </Form.Item>

          {
            [...Array(count)].map((e, index) => <div key={index}>
              <Divider />
              <h3>Location {index + 1}</h3>
              <Form.Item label="" name={`locid${index}`} hidden>
                <Input />
              </Form.Item>
              <Form.Item label="" name={`inside${index}`}>
                <Radio.Group size="small">
                  <Radio key="Yes" value="Yes">Within home country during the armed conflict</Radio>
                  <Radio key="No" value="No">Outside home country</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="City, neighborhood:" name={`city${index}`}>
                <Input className="quest-input" />
              </Form.Item>
              <Form.Item label="Time period spent in this location:" name={`period${index}`}>
                <Input className="quest-input" />
              </Form.Item>
              <Form.Item label="Was this location affected by armed conflict?" name={`affected${index}`}>
                <Radio.Group size="small">
                  <Radio key="Yes" value="Yes">Yes</Radio>
                  <Radio key="No" value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>)
          }
          <div className="quest-nav">
            <Row>
              <Col xs={24} md={8} align='center'>
                <Button type="primary" className="quest-button" onClick={goPrev}>Previous page</Button>
              </Col>
              <Col xs={24} md={8} />
              <Col xs={24} md={8} align='center'>
                <Button type="primary" className="quest-button" onClick={goNext}>Next page</Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  )
}

export default Locations