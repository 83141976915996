import React, { useState, useEffect, useCallback } from "react";
import { Table, Layout, Drawer, Modal, Button, message, Pagination, Row, Col } from "antd";
import service from "services/apiServices";
import { Filter } from "common";
import { isAdmin } from "services/authServices";
import config from "config";
import columns from "./columns";
import objects from "store/objects";
import ObjectView from "./objectView";
import PdfGenerator from "./PdfGenerator";

const { Content } = Layout;

const Objects = ({ participant, story, canEdit, dataSource }) => {

  canEdit = isAdmin();

  const [data, setData] = useState(objects);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);
  const [prev, setPrev] = useState({ id: 0, index: -1 });
  const [next, setNext] = useState({ id: 0, index: -1 });
  const [selectedId, setId] = useState(0);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(config.headers.page);
  const [modal, setModal] = useState(false);
  const [linkT, setLinkT] = useState('');
  const [linkD, setLinkD] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onChange = o => {
    config.headers.page = o.current;
    setCurrentPage(config.headers.page);
  };

  const fetch = useCallback(async () => {
    try {
      let url;
      setIsLoading(true);

      if (participant) {
        url = `participants/${participant}/objects`;
      } else if (story) {
        url = `stories/${story}/objects`;
      } else {
        url = `pieces?page=${config.headers.page}&size=${config.headers.pageSize}&filter=${config.filter}`;
      }

      if (dataSource) {
        setData(dataSource);
      } else {
        const result = await service.list(url);
        if (result.total) {
          setData(result);
        } else {
          alert('No data found');
//          setData({ ...data, items: result, total: result.length });
        }
        setCurrentPage(result.page);
        config.headers.page = result.page;
      }
      setIsLoading(false);
    }
    catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [participant, story, dataSource]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const refreshPage = () => {
    fetch();
  }

  const printPdf = async () => {
    const request = {
      ids: selectedRowKeys
    }
    setIsLoading(true);
    const response = await service.insert("pdf/objects", request);
    // const response = await service.insert("pdf2/objects", request);
    setIsLoading(false);
    if (response) setLinkD(config.documentPath + response);
  }

  const tableExport = async () => {
    const request = {
      ids: data.items.map(({ id }) => id)
    }
    setIsLoading(true);
    const response = await service.insert("pdf/table", request);
    setIsLoading(false);
    if (response) setLinkT(config.documentPath + response);
  }

  const TableTitle = () => {
    return (
      <Row>
        <Col span={8} align='left'>
          <h2>Objects / {data.total}</h2>
        </Col>
        <Col span={4} align='center'>
          {!!selectedRowKeys.length && <h3 style={{ marginTop: '12px' }}>{selectedRowKeys.length} selected</h3>}
        </Col>
        {!!(participant === undefined && dataSource === undefined) &&
          <Col span={12} align='right'>
            <Filter onChange={refreshPage} title='Objects' extension={story !== undefined} />
          </Col>
        }
        {!!dataSource && config.currentUser.role !== "Intern" &&
          <>
            <Col span={6} align='center'>
              <Button type='primary' htmlType="button"
                className='tableButton'
                onClick={tableExport}
                style={{ margin: '12px', height: 36, width: 164 }}>
                Entire table PDF
              </Button>
              {!!linkT &&
                <a href={linkT} target="_blank" rel="noopener noreferrer" download>
                  <div className="link-button">VIEW TABLE</div>
                </a>
              }
            </Col>
            <Col span={6} align='center'>
              <Button type='primary' htmlType="button"
                className='tableButton'
                onClick={printPdf}
                disabled={selectedRowKeys.length === 0}
                style={{ margin: '12px', height: 36, width: 164 }}>
                Objects' PDF files
              </Button>
              {!!linkD &&
                <a href={linkD} target="_blank" rel="noopener noreferrer" download>
                  <div className="link-button">VIEW PDF</div></a>
              }
            </Col>
          </>
        }
      </Row>
    )
  }

  const changePage = (page, size) => {
    config.headers.page = page;
    config.headers.pageSize = size;
    //setData({...data, page:page});
    fetch();
  };

  const setView = (id, index) => {
    setId(id);
    if (index > 0) {
      setPrev({ id: data.items[index - 1].id, index: index - 1 });
    } else {
      setPrev({ id: 0, index: -1 });
    }
    if (index < data.items.length - 1) {
      setNext({ id: data.items[index + 1].id, index: index + 1 });
    } else {
      setNext({ id: 0, index: -1 });
    }
    setShowDetailView(true);
  }

  const navstyle = {
    float: 'right',
    marginRight: '24px',
    marginTop: '-18px'
  }

  const DrawerTitle = () => {
    return (
      <div>
        <h3>Object Detail View</h3>
        <Button style={navstyle} type='primary' size='small' disabled={!next.id} onClick={() => setView(next.id, next.index)}>NEXT</Button>
        <Button style={navstyle} type='primary' size='small' disabled={!prev.id} onClick={() => setView(prev.id, prev.index)}>PREV</Button>
      </div>
    )
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  return (
    <Layout className='container'>
      <Content>
        <Table
          loading={isLoading}
          title={TableTitle}
          size="small"
          tableLayout={"fixed"}
          scroll={{ y: '59vh' }}
          rowSelection={dataSource && rowSelection}
          columns={columns({
            canEdit: canEdit,
            editUrl: 'objects'
          })}
          dataSource={data.items}
          onChange={onChange}
          //onRow={(record) => ({ onClick: () => { setId(record.id); setShowDetailView(true); } })}
          onRow={(record, index) => ({ onClick: () => { setView(record.id, index); } })}
          pagination={false}
          // pagination={{
          //   current: currentPage,
          //   size: 'small',
          //   hideOnSinglePage: true,
          //   showSizeChanger: true,
          //   defaultPageSize: 100,
          //   pageSizeOptions: ['100', '200', '500'],
          //   showQuickJumper: true
          // }}
          rowKey={(row) => row.id} />


        <Pagination size='small'
          current={data.page}
          total={data.total}
          pageSize={data.size}
          showSizeChanger={true}
          showQuickJumper={true}
          onChange={changePage}
          hideOnSinglePage={true}
          pageSizeOptions={[100, 200, 500, 1000]}
          style={{ marginTop: '24px' }}
        />

      </Content>

      <Drawer
        title={<DrawerTitle />}
        width={'75%'}
        placement="right"
        onClose={() => setShowDetailView(false)}
        destroyOnClose={true}
        open={showDetailView}>
        <ObjectView id={selectedId} />
      </Drawer>

      <Modal width={'92%'}
        title="Create PDF files"
        open={modal} footer={[]}
        onCancel={() => setModal(false)}
        style={{ top: 20 }}
        destroyOnClose={true}>
        <PdfGenerator data={data.items} />
      </Modal>

    </Layout>
  );
};

export default Objects;
