import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page29 = () => {
  const page = 29;
  const [loading, setLoading] = useState(false);
  const qn = ["5.31", "5.311", "5.32", "5.33", "5.331", "5.34", "5.35", "5.36", "5.361",];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v531, setV531] = useState("No");
  const [v533, setV533] = useState("No");
  const [v536, setV536] = useState("No");

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV531(result.answers[0].choice);
      setV533(result.answers[3].choice);
      setV536(result.answers[7].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <CondField
            form={form}
            label="31.	Did you experience any difficulties related to differences in religion/culture?"
            question="c5.31"
            trigger="Yes"
            current={v531}
            options={["Yes", "No"]}
            subQuestion="c5.311"
            subLabel="110.1 If yes, please describe."
          />

          <SingleField
            form={form}
            label="32.	Did you feel isolated from your host community?"
            question="c5.32"
            options={["Yes", "Somewhat", "No", "I don't remember"]}
          />

          <CondField
            form={form}
            label="33. Did you feel discriminated against in your new place of residence?"
            question="c5.33"
            trigger="Yes"
            current={v533}
            options={["Yes", "No", "I don't know"]}
            subQuestion="c5.331"
            subLabel="33.1 If yes, please describe."
          />

          <SingleField
            form={form}
            label="34. How often were you thinking of returning home?"
            question="c5.34"
            options={[
              "Frequently (multiple times a day)",
              "Often (Multiple times a week)",
              "Occasionally",
              "Rarely",
              "Never",
              "I don’t remember",
            ]}
          />

          <TextField
            form={form}
            label="35. What made you think or miss home?"
            question="c5.35"
          />

          <CondField
            form={form}
            label="36. Did you have to help your parents adjust to life in your new place of residence (i.e. in translation, accessing services, etc.)?"
            question="c5.36"
            trigger="Yes"
            current={v536}
            options={["Yes", "No", "I don't know"]}
            subQuestion="c5.361"
            subLabel="36.1 If yes, please describe."
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page29;
