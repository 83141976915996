import React, { useState, useCallback, useEffect } from "react";
import { Drawer, Divider, message, Card, Row, Col } from "antd";
import service from "services/apiServices";
import moment from 'moment';
import config from "config";
import { ParticipantView } from "components/participants";
import { StoryView } from "components/stories";
import { EditHistory, Info, Spinner, ParticipantInfo, StoryInfo, Condition, ShowImage } from "common";

const styles = {
  card: {
    border: '1px solid black',
    marginBottom: '12px'
  },
  list: {
    border: '1px solid black',
    marginBottom: '12px',
    maxHeight: '250px',
    overflow: 'hidden'
  },
  photo: {
    padding: 0,
    cursor: 'pointer',
    overflow: 'hidden'
  }
}

const PartView = ({ id }) => {

  const [loading, setLoading] = useState(true);
  const [object, setObject] = useState({});
  const [showPariticipantDetailView, setShowPariticipantDetailView] = useState(false);
  const [participantId, setParticipantId] = useState(0);
  const [showStoryDetailView, setShowStoryDetailView] = useState(false);
  const [storyId, setStoryId] = useState(0);
  const [view, setView] = useState({ show: false, id: 0, type: 'X' });

  const getObject = useCallback(async (id) => {
    try {
      const result = await service.read("parts", id);
      setObject({ ...result });
      setLoading(false);
    } catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experienciing some network related issues. Please try again in a minute."
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id && parseInt(id)) {
      setLoading(true);
      getObject(id);
    }
  }, [id, getObject]);

  const cardTitle = (name, id) => (
    <div style={{ fontSize: '1.4em', fontWeight: '400' }}>
      <div style={{ float: 'left' }}>{name}</div>
      <div style={{ float: 'right' }}>{id}</div>
      <Divider className='divider' />
    </div>
  )

  const conditionTitle = (report, count) => {
    const ShowReport = () => {
      if (!report) return <div className="no-report">NO</div>;
      if (report && !count) return <div className="req-report">YES</div>
      return <div className="yes-report">YES</div>
    }
    return (
      <Row style={{ fontSize: '1.4em', fontWeight: '400' }}>
        <Col span={16}>Object Condition</Col>
        <Col span={8}>Condition Report <ShowReport /></Col>
        <Divider />
      </Row>);
  }

  if (!id || loading) return <Spinner />;
  return (
    <>
      <Card style={styles.card}>
        {cardTitle(object.name, 'Id: ' + object.partId)}
        <EditHistory lastUpdate={object.lastUpdate} editHistory={object.editHistory} />
        <p>{object.description}</p>
        <div className='right' style={styles.photo}>
          {object.photo !== 'noimg' &&
            <img style={{ border: '1px black solid', maxHeight: '150px' }}
              src={`${config.imagePath}${object.photo}.jpg`} alt={object.photo}
              onClick={() => {
                setView({
                  show: true,
                  type: 'part',
                  id: object.id
                });
              }} />}
        </div>
      </Card>

      <Card style={styles.card}>
        <Row>
          <Col span={6}>
            <ParticipantInfo
              id={object.participant.id}
              name={object.participant.name}
              onClick={() => {
                setShowPariticipantDetailView(true);
                setParticipantId(object.participant.id);
              }} />
          </Col>
          <Col span={6}>
            <StoryInfo
              id={object.story.id}
              title={object.story.name}
              onClick={() => {
                setShowStoryDetailView(true);
                setStoryId(object.story.id);
              }} />
          </Col>
          <Col span={6}><Info label='Object Type' value={object.objectType} /></Col>
          <Col span={6}><Info label='Accession Date' value={moment(object.accessionDate).format("YYYY-MM-DD")} /></Col>
        </Row>
        <Divider />
        <Row>
          <Col span={6}><Info label='Current Legal Status' value={object.legalStatus} /></Col>
          <Col span={6}><Info label='Loan Information' value={object.loanStatus} /></Col>
          <Col span={6}><Info label='Location' value={object.location} /></Col>
          <Col span={6}><Info label='Quantity' value={object.quantity} /></Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}><Info label='Material' value={object.material} /></Col>
          <Col span={12}>
            <Info label='Storage Location' tooltip={object.tooltip}
              value={object.storageCode ? object.storageCode : object.storage} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}><Info label='Handling requirements' value={object.handlingRequirements} /></Col>
          <Col span={6}><Info label='Digital/Copy' value={object.digitalCopy} /></Col>
          <Col span={6}><Info label='Mount' value={object.standLocation} /></Col>
        </Row>
      </Card>

      <Card style={styles.card}>
        {conditionTitle(object.conditionReport, !!object.report)}
        <Row>
          <Col span={14} style={{ borderRight: '1px solid gray' }}>
            <Row>
              <Col span={6}><Info label='Handmade' value={object.handmade.toString()} /></Col>
              <Col span={6}><Info label='Broken' value={object.broken.toString()} /></Col>
              <Col span={6}><Info label='Conservation' value={object.conservation.toString()} /></Col>
              <Col span={6}><Info label='Shape' value={object.shape} /></Col>
            </Row>
            <Divider />
            <Row>
              <Col span={6}><Info label='Length' value={object.length} /></Col>
              <Col span={6}><Info label='Width' value={object.width} /></Col>
              <Col span={6}><Info label='Height' value={object.height} /></Col>
              <Col span={6}><Info label='Other' value={object.other} /></Col>
            </Row>
          </Col>
          <Col span={10}>
            <Condition document={object.report} />
          </Col>
        </Row>
      </Card>

      <Card style={styles.card}>
        {cardTitle('Notes', '')}
        <div>{object.institution}</div>
      </Card>

      {view.show && <ShowImage id={view.id} url={view.photo} type="part" alt="" visible={view.show} onCancel={() => setView({ show: false, url: '', alt: '' })} />}

      <Drawer title={<h2>Contributor Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowPariticipantDetailView(false)}
        open={showPariticipantDetailView}>
        <ParticipantView id={participantId} />
      </Drawer>

      <Drawer title={<h2>Story Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowStoryDetailView(false)}
        open={showStoryDetailView}>
        <StoryView id={storyId} />
      </Drawer>
    </>
  );
};

export default PartView;
