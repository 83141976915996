import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page19 = () => {
  const page = 19;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["4.45", "4.46", "4.47", "4.471", "4.48", "4.49", "4.50", "4.51"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v447, setV447] = useState("No");
  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV447(result.answers[2].choice);

      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <TextField
            form={form}
            label="45. What was your favorite meal?"
            question="c4.45"
          />
          <TextField
            form={form}
            label="46. Which food did you miss the most?"
            question="c4.46"
          />
          <CondField
            form={form}
            label="47. Were your meals provided for by the communal kitchen?"
            question="c4.47"
            trigger="Yes"
            current={v447}
            options={["Yes", "No"]}
            subQuestion="c4.471"
            subLabel="47.1 If yes, how many meals per day were you provided with?"
          />
          <SingleField
            form={form}
            label="48.	Did you have to queue for a meal?"
            question="c4.48"
            options={["Yes", "No", "I don't remember"]}
          />
          <SingleField
            form={form}
            label="49. Did you have any access to medical care?"
            question="c4.49"
            options={["Yes", "No", "I don't remember"]}
          />
          <SingleField
            form={form}
            label="50.	How did you manage to obtain medicine/medical supplies?"
            question="c4.50"
            extension="e4.50"
            options={[
              "Home supplies",
              "Neighbors’ supplies",
              "We received them in humanitarian aid",
              "We received medicine in a package from abroad",
              "We received them in a local medical institution",
              "We bought medicine in a pharmacy",
              "We bought medicine on the black market",
              "I don’t remember",
            ]}
            other="Other:"
          />

          <SingleField
            form={form}
            label="51. Did you ever have a chance to visit a hospital during armed conflict?"
            question="c4.51"
            extension="e4.51"
            options={[
              "Yes, because of some health problems that were not related to wounding",
              "As a volunteer/employee",
              "As a visitor to a member of my family/friend",
              "No, there was no need",
            ]}
            other="Other:"
          />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page19;
