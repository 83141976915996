import React, { useState, useEffect } from "react";
import { Table, Layout } from "antd";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import Bih from 'assets/images/bih.png';
import Eng from 'assets/images/eng.png';

import TableHead from "services/TableHead";
import service from "services/apiServices";
import { isAdmin } from "services/authServices";
import config from "config";

const imgStyle = {
  width: '32px',
  marginLeft: '12px',
  cursor: 'pointer'
}

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'move', color: '#666' }} />
));

const Tags = ({ history }) => {

  const [data, setData] = useState([]);
  const [lang, setLang] = useState('eng');
  const [colProps, setCol] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async () => {
    try {
      setIsLoading(true);
      config.tagType = 4;
      config.tagDesc = 'Oral History Tags';
      const result = await service.list('tags/4');
      setCol(engProps);
      if (result) setData(result.list);
      setIsLoading(false);
    } catch (error) {
      console.error(config.errorMessage);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const TableTitle = () => (
    <div>
      <h2 className='left'>Oral History Tags ({lang})</h2>
      <span className='right'>
        Language:
        <img src={Bih} style={imgStyle} alt='BCS' onClick={() => { setLang('bih'); setCol(bihProps); }} />
        <img src={Eng} style={imgStyle} alt='English' onClick={() => { setLang('eng'); setCol(engProps); }} />
      </span>
    </div>
  );

  const engProps = [
    { title: '', dataIndex: 'sort', width: '5%', className: 'drag-visible', render: () => config.currentUser.role.includes('SuperAdmin') ? <DragHandle /> : <div /> },
    { key: "value", title: "Main Tag", className: 'drag-visible', width: '25%' },
    { key: "description", title: "Subtags", className: 'drag-visible', width: '60%' }
  ];

  const bihProps = [
    { title: '', dataIndex: 'sort', width: '5%', className: 'drag-visible', render: () => config.currentUser.role.includes('SuperAdmin') ? <DragHandle /> : <div /> },
    { key: "bhValue", title: "Main Tag", className: 'drag-visible', width: '25%' },
    { key: "bhDescription", title: "Subtags", className: 'drag-visible', width: '60%' }
  ];

  const columns = () =>
    TableHead(
      colProps,
      isAdmin(),
      `tags/4`,
      true
    );

  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);

  const onSortEnd = async ({ newIndex, oldIndex }) => {
    if (oldIndex !== newIndex) {
      const dataSource = data;
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      setIsLoading(true);
      const result = await service.insert('tags/order', newData);
      setIsLoading(false);
      setData(result);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll={false}
      useWindowAsScrollContainer
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = data;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Layout className="container">
      <Table
        loading={isLoading}
        title={TableTitle}
        size="small"
        tableLayout={"fixed"}
        columns={columns()}
        dataSource={data}
        pagination={false}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          }
        }}
      />
    </Layout>
  );
};

export default Tags;
