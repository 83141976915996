import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';

import { isLogged, isSuper, notIntern } from 'services/authServices';
import config from 'config';

const { SubMenu } = Menu;

const isDash = () => config.currentUser.role === 'SuperAdmin' || config.currentUser.id === 37;

const NavigationMenu = ({ history, location }) => {
  const handleClick = e => {
    if (location.pathname !== e.key) {
      config.filter = '';
      config.headers.page = 1;
    }
    history.push(`/${e.key}`);
  };

  return (
    <>
      {isLogged &&
        <Menu onClick={handleClick} selectedKeys={location.pathname} mode='horizontal' style={{ height: '52px' }} theme="dark">
          <Menu.Item key='home'>Home</Menu.Item>
          {notIntern() && <Menu.Item key='participants'><Link to="/participants">Contributors</Link></Menu.Item>}
          {(notIntern()  || config.currentUser.id === 76) && <Menu.Item key='stories'><Link to="/stories">Stories</Link></Menu.Item>}
          <Menu.Item key='objects'><Link to="/objects">Objects</Link></Menu.Item>
          {notIntern() && <Menu.Item key='oral'><Link to="/oral">Oral History</Link></Menu.Item>}
          <SubMenu title='Navigation' key='navigation'>
            <Menu.Item key='classes'><Link to="/classes">Classifications</Link></Menu.Item>
            <Menu.Item key='objtypes'><Link to="/objtypes">Object Types</Link></Menu.Item>
            <Menu.Item key='maters'><Link to="/maters">Materials</Link></Menu.Item>
            <Menu.Item key='tags'><Link to="/tags">Oral History Tags</Link></Menu.Item>
            <Menu.Item key='conflicts'><Link to="/conflicts">Armed Conflicts</Link></Menu.Item>
            <Menu.Item key='cells'><Link to="/cells">Research Cells</Link></Menu.Item>
            <Menu.Item key='langs'><Link to="/langs">Languages</Link></Menu.Item>
            <Menu.Item key='locationcodes'><Link to="/locationcodes">Location Codes</Link></Menu.Item>
          </SubMenu>
          <Menu.Item key='exhibitions'><Link to="/exhibitions">Exhibitions</Link></Menu.Item>
          <SubMenu title={config.currentUser.name} key='operater'>
            {isSuper() && (<Menu.Item key='users'><Link to="/users">Users</Link></Menu.Item>)}
            {isDash() && (<Menu.Item key='dashboard'><Link to="/dashboard">Reports Dashboard</Link></Menu.Item>)}
            {/* <Menu.Item key='help'><Link to="/help">Help</Link></Menu.Item> */}
            <Menu.Item key='profile'><Link to="/profile">Profile</Link></Menu.Item>
            {/* <Menu.Item key='photos'><Link to="/photos">Thumbnail test</Link></Menu.Item> */}
            <Menu.Item key='logout' className='logOutButton'><Link to="/logout">Logout</Link></Menu.Item>
          </SubMenu>
        </Menu>
      }
    </>
  );
};

export default withRouter(NavigationMenu);