import React, { useState } from 'react';
import { Table, Modal } from 'antd';
import { PlayCircleTwoTone } from '@ant-design/icons';
import ViewTrans from 'components/stories/components/viewTrans';

const columns = [
  { key: 'status', title: 'Status', ellipsis: true, dataIndex: 'status', width: '10%' },
  { key: 'language', title: 'Language', ellipsis: true, dataIndex: 'language', width: '10%' },
  { key: 'audio', title: 'Audio', ellipsis: true, dataIndex: 'audio', width: '5%', render: x => x !== 'n/a' ? <PlayCircleTwoTone /> : "" },
  { key: 'title', title: 'Title', ellipsis: true, dataIndex: 'title', width: '30%' },
  { key: 'text', title: 'Text', ellipsis: true, dataIndex: 'text', width: '45%', render: x => <div dangerouslySetInnerHTML={{ __html: x }} /> }
]

const Translations = ({ list }) => {

  const [view, setView] = useState({ visible: false, title: '', text: '' });

  const TableTitle = () => <div><h2 align='left'>Translations</h2></div>

  return (
    <div>
      {list.length !== 0 && (
        <Table
          size="small"
          title={TableTitle}
          tableLayout={"fixed"}
          columns={columns}
          dataSource={list}
          pagination={false}
          onRow={record => {
            return {
              onClick: e => { setView({ visible: true, title: record.title, text: record.text, audio: record.audio, language: record.language, status: record.status }) }
            }
          }}
          rowKey={row => row.id}
        />
      )}
      <Modal width={'90%'}
        open={view.visible} footer={[]}
        onCancel={() => setView({ visible: false, title: '', text: '' })} destroyOnClose={true}>
        <ViewTrans item={view} onOk={() => setView({ visible: false, title: '', text: '' })} />
      </Modal>
    </div>
  )
};

export default Translations;

//.replace('\n', '<br />')
//style={{whiteSpace:'pre-wrap'}}