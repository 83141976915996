import React, { useState, useEffect, useCallback } from "react";
import { Table, Layout, Drawer, Button, message } from "antd";

import service from "services/apiServices";
import { Filter } from "common";
import { isAdmin } from "services/authServices";
import config from "config";
import columns from "./columns";
import ParticipantView from "./participantView";

const { Content } = Layout;

const Participants = ({ history, canEdit, dataSource }) => {

  if (config.currentUser.role === "Intern") history.push('/home');

  if (canEdit === undefined) canEdit = isAdmin();

  const [participants, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);
  const [currentPage, setCurrentPage] = useState(config.headers.page);
  const [selectedId, setId] = useState(0);
  const [prev, setPrev] = useState({ id: 0, index: -1 });
  const [next, setNext] = useState({ id: 0, index: -1 });

  const onChange = o => {
    config.headers.page = o.current;
    setCurrentPage(config.headers.page);
  };

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      if (dataSource) {
        setData(dataSource);
      } else {
        if (config.filter === '' && config.participants.length !== 0) {
          setData(config.participants);
        } else {
          const result = await service.list('participants');
          if (result) {
            if (config.filter === '') config.participants = result;
            setData(result);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [dataSource]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const refreshPage = () => fetch();

  const TableTitle = () => {
    return (
      <div>
        <div style={{ float: 'left' }}>
          <h2 align='left'>Contributors / {participants.length}</h2>
        </div>
        {!!dataSource && <div style={{ clear: 'both' }} />}
        {!dataSource && <Filter onChange={refreshPage} title='Contributors' />}
      </div>
    )
  }

  const setView = (id, index) => {
    if (index < 10) index = 10 * (currentPage - 1) + index;
    setId(id);
    if (index > 0) {
      setPrev({ id: participants[index - 1].id, index: index - 1 });
    } else {
      setPrev({ id: 0, index: -1 });
    }
    if (index < participants.length - 1) {
      setNext({ id: participants[index + 1].id, index: index + 1 });
    } else {
      setNext({ id: 0, index: -1 });
    }
    setShowDetailView(true);
  }

  const navstyle = {
    float: 'right',
    marginRight: '24px',
    marginTop: '-18px'
  }

  const DrawerTitle = () => {
    return (
      <div>
        <h3>Contributor Detail View</h3>
        <Button style={navstyle} type='primary' size='small' disabled={!next.id} onClick={() => setView(next.id, next.index)}>NEXT</Button>
        <Button style={navstyle} type='primary' size='small' disabled={!prev.id} onClick={() => setView(prev.id, prev.index)}>PREV</Button>
      </div>
    )
  }

  return (
    <Layout className="container">
      <Drawer
        title={<DrawerTitle />}
        width={'60%'}
        placement="right"
        onClose={() => setShowDetailView(false)}
        destroyOnClose={true}
        open={showDetailView}>
        <ParticipantView id={selectedId} />
      </Drawer>

      <Content>
        <Table
          loading={isLoading}
          title={TableTitle}
          scroll={{ y: '59vh' }}
          size="small"
          tableLayout={"fixed"}
          columns={columns({
            canEdit: canEdit,
            editUrl: 'participants'
          })}
          dataSource={participants}
          onChange={onChange}
          onRow={(record, index) => ({ onClick: () => { setView(record.id, index); } })}
          pagination={{
            current: currentPage,
            size: 'small',
            hideOnSinglePage: true,
            showSizeChanger: true,
            defaultPageSize: 100,
            pageSizeOptions: ['100', '200', '500'],
            showQuickJumper: true
          }}
          rowKey={(row) => row.id}
        />
      </Content>
    </Layout>
  );
};

export default Participants;