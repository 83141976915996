import React from "react";
import { Form, Input, InputNumber, Switch, Layout, Row, Col, Radio } from "antd";
import _ from 'lodash';
import config from "config";
import service from "services/apiServices";

const { TextArea } = Input;


const Personal = ({ form, labels }) => {

  const restricted = async checked => {
    if (checked) {
      const id = form.getFieldValue('id');
      if(id === '0') return;

      const result = await service.update('participants/rest', {  }, id);
      let i = _.findIndex(config.participants, { id: result.id });
      config.participants.splice(i, 1, result);
    };
  }
  
  return (
    <Layout style={{ paddingTop: '24px' }}>
      <Form form={form} layout='vertical'>
        <Form.Item label={labels.ID} name="id" hidden><Input className="stepFormField" disabled /></Form.Item>
        <Row>
          <Col span={6} offset={2}>
            <Form.Item label={labels.FIRST_NAME} name="firstName">
              <Input className="stepFormField" autoFocus />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.LAST_NAME} name="lastName"><TextArea className="stepFormField" autoSize={{ minRows: 1, maxRows: 3 }} /></Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label={labels.GENDER} name='gender' style={{ textAlign: 'left' }}>
              <Radio.Group buttonStyle='solid' size='small'>
                <Radio.Button value="Female">Female</Radio.Button>
                <Radio.Button value="Male">Male</Radio.Button>
                <Radio.Button value="Other">Other</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label='Restrictions on use' name='restrictions' valuePropName='checked' style={{ textAlign: 'left' }}>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" onChange={restricted} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Form.Item label={labels.YEAR_OF_BIRTH} name="year"><InputNumber className="stepFormField" /></Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.BIRTH_PLACE} name="birthPlace"><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.LEGAL_GUARDIAN} name="legalGuardian"><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Form.Item label={labels.CURRENT_RESIDENCE} name="currentResidence"><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.PHONE} name="phone"><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.EMAIL} name="email"><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
};

export default Personal;
