import React from "react";
import { Button } from "antd";

const blank = {
  id: 0,
  time: "",
  text: "",
  tags: []
}

const TableOral = (colProps, canEdit, editModal) => {

  const AddNew = () => <Button className='tableButton' size='small' style={{ backgroundColor: 'black', color: 'white' }} onClick={() => editModal(blank)}>ADD NEW</Button>;

  const Edit = ({ record }) => <Button className='tableButton' size='small' onClick={() => editModal(record)}>EDIT</Button>;

  const colArray = colProps.map((col) => {
    return {
      title: col.title,
      ellipsis: true,
      dataIndex: col.key,
      sorter: col.sorter,
      key: col.key,
      defaultSortOrder: col.sort,
      width: col.width,
      render: col.render,
      align: col.align
    };
  });

  if (canEdit) {
    colArray.push({
      title: <AddNew />,
      key: "action",
      align: "center",
      render: (record) => (<Edit record={record} />)
    });
  }

  return colArray;
};

export default TableOral;