import React, { useState, useEffect } from "react";
import { Form, Input, Layout, Row, Col, DatePicker, Select, Radio, Switch, InputNumber } from "antd";
import service from 'services/apiServices'

const { TextArea } = Input;

const Institution = ({ form, labels, getNext }) => {

  const [partId, setPartId] = useState('0');
  const [conflicts, setConflicts] = useState([]);
  const [cells, setCells] = useState([]);

  const getConflicts = async () => {
    const result = await service.list('master/conflicts');
    if (result) {
      setConflicts(result);
    }
  }

  const conflictSelected = async value => {
    if (form.getFieldValue('id') === '0') {
      let next = await service.read('participants/next', value);
      if (next) {
        getNext(next);
      }
    }
  };

  const getCells = async () => {
    const result = await service.list('master/researchCells');
    if (result) {
      setCells(result);
    }
  }

  useEffect(() => {
    getConflicts();
    getCells();
    setPartId(form.getFieldValue('id'));
    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <Form form={form} layout='vertical'>
        <Form.Item label="" name="pid" hidden><Input /></Form.Item>
        <Row>
          <Col span={6} offset={2}>
            <Form.Item label={labels.CONFLICT} labelAlign="top" name="conflictId">
              <Select className="stepFormField" style={{ textAlign: 'left' }} mode='combobox'
                disabled={partId !== '0'}
                showSearch optionFilterProp='children' onSelect={conflictSelected}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {conflicts.map(c => (<Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.RESEARCH_CELL} labelAlign="top" name="researchCellId">
              <Select className="stepFormField" style={{ textAlign: 'left' }} mode='combobox'
                showSearch optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {cells.map(c => (<Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.INSTITUTION} name="institution"><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Form.Item label={labels.PARTICIPATION_DATE} name="participationDate" defaultValue={new Date()} ><DatePicker className="stepFormField" /></Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.STATEMENT_ID} name="statementId"><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label={labels.NOTES} name="notes"><TextArea className="stepFormField" autoSize /></Form.Item>
          </Col>
        </Row>

        <div style={{ display: 'none' }}>
          <Row>
            <Col span={6} offset={2}>
              <Form.Item label={labels.FIRST_NAME} name="firstName">
                <Input className="stepFormField" autoFocus />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={labels.LAST_NAME} name="lastName"><TextArea className="stepFormField" autoSize={{ minRows: 1, maxRows: 3 }} /></Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item label={labels.GENDER} name='gender' style={{ textAlign: 'left' }}>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value="Female">Female</Radio.Button>
                  <Radio.Button value="Male">Male</Radio.Button>
                  <Radio.Button value="Other">Other</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Restrictions on use' name='restrictions' valuePropName='checked' style={{ textAlign: 'left' }}>
                <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6} offset={2}>
              <Form.Item label={labels.YEAR_OF_BIRTH} name="year"><InputNumber className="stepFormField" /></Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={labels.BIRTH_PLACE} name="birthPlace"><Input className="stepFormField" /></Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={labels.LEGAL_GUARDIAN} name="legalGuardian"><Input className="stepFormField" /></Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6} offset={2}>
              <Form.Item label={labels.CURRENT_RESIDENCE} name="currentResidence"><Input className="stepFormField" /></Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={labels.PHONE} name="phone"><Input className="stepFormField" /></Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={labels.EMAIL} name="email"><Input className="stepFormField" /></Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Layout>
  );
};

export default Institution;