import React from 'react';
import { Row, Col, Button } from 'antd';

const FilterNavigator = ({ preview, submit, showSaved, takeSnap, viewSnap, snapshot, onReset }) => {

  return (
    <div>
      <div className='filter-navigator'>
        <Row>
          <Col span={4} align='center'>
            <Button className='filterButton filterConfirm' onClick={preview}>Show Results</Button>
          </Col>
          <Col span={4} align='center'>
            <Button className='filterButton' onClick={submit}>Save Filter</Button>
          </Col>
          <Col span={4} align='center'>
            <Button className='filterButton' onClick={showSaved}>Saved Filters</Button>
          </Col>
          <Col span={4} align='center'>
            <Button className='filterButton' onClick={takeSnap}>Take Snapshot</Button>
          </Col>
          <Col span={4} align='center'>
            <Button className='filterButton' onClick={viewSnap} disabled={!snapshot}>View Snapshot</Button>
          </Col>
          <Col span={4} align='center'>
            <Button className='filterButton filterReset' onClick={onReset}>Reset Form</Button>
          </Col>
        </Row>
      </div>
      <hr style={{ margin: '8px 0' }} />
    </div>
  )
};

export default FilterNavigator;