import React from 'react'
import TableHead from "services/TableHead";
import { WarningOutlined } from '@ant-design/icons'

const noName = name => {
  return (name === '') ? <WarningOutlined style={{ fontSize: '1.5em', color: 'red' }} /> : name;
}

const colProps = [
  // { key: "id", title: "", width: '0' },
  { key: "partId", title: "Id", width: '8%' },
  { key: "name", title: "Name", width: '30%', ellipsis: true, render: name => noName(name) },
  { key: "description", title: "Description", width: '30%', ellipsis: true },
  { key: "objectType", title: "Object Type", width: '12%' },
  { key: "material", title: "Material", width: '12%' }
];

const columns = ({ canEdit, editUrl, editModal }) =>
  TableHead(
    colProps,
    canEdit,
    editUrl,
    false,
    editModal
  );

export default columns;
