import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, CondOptions, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page10 = () => {

  const page = 10;
  const [loading, setLoading] = useState(false);
  const qn = ["3.59", "3.60", "3.61", "3.611", "3.62", "3.63", "3.64", "3.641", "3.65", "3.66"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent);
  const [v361, setV361] = useState("No");
  const [v364, setV364] = useState("No");

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV361(result.answers[2].choice);
      setV364(result.answers[6].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      };
    }
  };
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <TextField form={form} label="59. How did you spend your free time?" question="c3.59" />

          <SingleField form={form} label="60. Were you enrolled in any extracurricular activities (music, sport, art…etc.)?"
            question="c3.60"
            options={["Yes", "No", "Don't know"]} />

          <CondField form={form} label="61. Did you collect anything?"
            question="c3.61" options={["Yes", "No"]}
            trigger="Yes" current={v361}
            subLabel="61.1 If yes, what?" subQuestion="c3.611" />

          <SingleField form={form} label="62. Did you have a pet?"
            question="c3.62" options={["Yes", "No"]} />

          <TextField form={form} label="63. Do you remember any games from that period? If yes, please describe them shortly." question="c3.63" />

          <CondOptions form={form} label="64. Did you have any toys?"
            question="c3.64" other={false} options={["Yes", "No"]}
            trigger="Yes" current={v364}
            subLabel="64.1 If yes, how did you obtain those toys?" subQuestion="c3.641" subOther="Other:" subExtension="e3.641"
            subOptions={[
              "From my older brother/sister",
              "From my older cousins or friends from the neighborhood",
              "I made my toys",
              "I bought my toys",
              "I received toys in a package from abroad",
              "I don’t remember"
            ]} />

          <TextField form={form} label="65. List the places where you played the most (in front of the house, basement, street, etc.)."
            question="c3.65" />

          <TextField form={form} label="66. Whom did you mostly play with?" question="c3.66" />

        </Form>
        <Navigator prev={page-1} next={page+1} save={() => form.submit()} />
      </div>
    </>
  );
}

export default Page10;
