import React, { useState, useCallback, useEffect } from "react";
import { Table, Drawer, Divider, message, Button, Card, Row, Col } from "antd";
import moment from 'moment';
import config from 'config';
import service from "services/apiServices";
import { ParticipantView } from "components/participants";
import { ObjectView } from "components/objects";
import { EditHistory, Info, Spinner, ParticipantInfo } from "common";
import Translations from '../../views/translations';
import Restrictions from "common/restrictions";
import TableView from "services/TableView";

const styles = {
  cardItem: {
    border: '1px solid black',
    marginBottom: '12px'
  },
  listItem: {
    border: '1px solid black',
    marginBottom: '12px',
    maxHeight: '250px',
    overflow: 'hidden'
  },
  imgStyle: {
    border: '1px solid black'
  },
  audio: {
    fontSize: '2em',
    position: 'absolute',
    //right: '12px',
    bottom: 0
  }
}

const StoryView = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [story, setStory] = useState({});
  const [showPariticipantDetailView, setShowPariticipantDetailView] = useState(false);
  const [participantId, setParticipantId] = useState(0);
  const [showObjectDetailView, setShowObjectDetailView] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState({});

  const getStory = useCallback(async (storyId) => {
    try {
      const result = await service.read("stories", storyId);
      setStory({ ...result });
      setLoading(false);
    } catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experienciing some network related issues. Please try again in a minute.",
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id && parseInt(id)) {
      setLoading(true);
      getStory(id);
    }
  }, [id, getStory]);

  const cardTitle = (name, id) => (
    <div style={{ fontSize: '1.4em', fontWeight: '400' }}>
      <div style={{ float: 'left' }}>{name}</div>
      <div style={{ float: 'right' }}>{id}</div>
      <Divider className='divider' />
    </div>
  )

  const pieceColumns = () => {
    const colProps = [
      { key: 'objectId', dataIndex: 'objectId', title: "ID", width: '10%' },
      { key: 'name', title: 'Name', width: '40%' },
      { key: 'description', title: 'Description', width: '50%', render: x => x.replace('<p>', '').replace('</p>', '') }
    ];
    return TableView(colProps);
  }
  const PiecesTitle = () => <div style={{ float: 'left' }}><h2 align='left'>Objects / {story.pieces.length}</h2></div>;

  const wordCount = (label, value) => {
    let wc = 0;
    let valuex = value.replace('<p>', '').replace('</p>', '');
    if (valuex) wc = valuex.split(' ').length;
    return <div style={{ fontSize:'1.2em', fontWeight:'600'}}>{label}{!!wc && <span>: [{wc} words]</span>}</div>
  }

  const present = (value) => {
    if (value === null || value === undefined || value === '<p></p>' || value === '') return false;
    return true;
  }

  if (!id || loading) return <Spinner />;
  return (
    <>
      {!!story.restrictions && <Restrictions />}
      <EditHistory lastUpdate={story.lastUpdate} editHistory={story.editHistory} />
      <Card style={styles.cardItem}>
        {present(story.text) && (
          <div>
            {cardTitle(wordCount(story.title, story.text), 'Id: ' + story.id)}
            <div dangerouslySetInnerHTML={{ __html: story.text }} />
          </div>
        )}
        {!present(story.text) && (
          <div>
            {cardTitle(wordCount('Working version', story.workVersion), 'Id: ' + story.id)}
            <div dangerouslySetInnerHTML={{ __html: story.workVersion }} />
          </div>
        )}

        {(story.audio !== 'n/a') &&
          (
            <div>
              <audio controls controlsList='nodownload'>
                <source src={`${config.mediaPath}${story.audio}`} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
      </Card>
      <Card style={styles.cardItem}>
        <Row>
          <Col span={8}>
            <ParticipantInfo
              id={story.participant.id}
              name={story.participant.name}
              show={config.currentUser.role !== 'Intern'}
              onClick={() => {
                setShowPariticipantDetailView(true);
                setParticipantId(story.participant.id);
              }} />
            <Divider />
            <Info label='Classification' value={story.class1} />
            <Info label='' value={story.class2} />
            <Info label='' value={story.class3} />
          </Col>
          <Col span={8}>
            <Info label='Status' value={story.status} />
            <Divider />
            <Info label='Location' value={story.location} />
            <Divider />
            <Info label='Native language' value={story.nativeLanguage} />
          </Col>
          <Col span={8} >
            <img src={`${config.imagePath}/${story.photo}`} alt={story.photo} style={styles.imgStyle} />
            <Divider />
            <Info label='Additional information source' value={story.additional} />
          </Col>
        </Row>
      </Card>

      {story.pieces.length !== 0 && (
        <Table
          title={PiecesTitle}
          size="small"
          tableLayout={"fixed"}
          columns={pieceColumns()}
          dataSource={story.pieces}
          pagination={{
            size: 'small',
            hideOnSinglePage: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showQuickJumper: true
          }}
          rowKey={(row) => row.id}
          onRow={(record, index) => ({ onClick: () => { setShowObjectDetailView(true); setSelectedObjectId(record.id); } })}
        />
      )}

      {story.translations && story.translations.length !== 0 && <Translations list={story.translations} />}

      {story.notes.length !== 0 && (
        <Card style={styles.cardItem}>
          {cardTitle('Notes', '')}
          {story.notes.map(n => (<div key={n.id}>
            <Row key={n.id}>
              <Col span={3}>{moment(n.time).format('YYYY-MM-DD')}</Col>
              <Col span={5}>| {n.user}</Col>
              <Col span={16}>| {n.comment}</Col>
            </Row>
            <hr style={{ border: '1px solid #eee' }} />
          </div>
          ))}
        </Card>
      )}

      {story.attachments.length !== 0 && (
        <Card style={styles.cardItem}>
          {cardTitle('Attachments', '')}
          {story.attachments.map(a => (<div key={a.id}>
            <Row key={a.id}>
              <Col span={5}>
                <Button
                  className='tableButton'
                  style={{ marginTop: 0 }}
                  onClick={() => window.open(a.url, "_blank")}>
                  View Attachment
                </Button>
              </Col>
              <Col span={16}>| {a.comment}</Col>
            </Row>
            <hr style={{ border: '1px solid #eee' }} />
          </div>
          ))}
        </Card>
      )}

      <Drawer title="Contributor Detail View"
        width={'60%'}
        closable={false}
        onClose={() => setShowPariticipantDetailView(false)}
        open={showPariticipantDetailView}>
        <ParticipantView id={participantId} />
      </Drawer>

      <Drawer title="Object Detail View"
        width={'60%'}
        closable={false}
        onClose={() => setShowObjectDetailView(false)}
        open={showObjectDetailView}>
        <ObjectView id={selectedObjectId} />
      </Drawer>
    </>
  );
};

export default StoryView;
