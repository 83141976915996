import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Radio, DatePicker, Select, Switch, Button, Layout, Upload, Row, Col, message, Divider, Modal } from "antd";
import moment from 'moment';
import labels from 'assets/labels/object.json';
import config from 'config';
import service from "services/apiServices";
import { EditHistory, Spinner, Buttons, Delete } from "common";
import Storage from 'components/objects/steps/storage';
import PdfView from 'common/ViewPdf';
import { UploadOutlined} from '@ant-design/icons';

const { TextArea } = Input;

const modout = { labelCol: { span: 0 }, wrapperCol: { span: 24 } };
const divide = { margin: '4px 0' };

const PartEdit = ({ object, part, onClose }) => {

  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const id = part; // useState(parseInt(useParams().id));
  const obj = object; // useState(useParams().obj);
  const [pid, setPid] = useState('');
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [desc, setDesc] = useState(false);
  const [mast, setMast] = useState({ participant: {}, story: {}, object: {} });
  const [mater, setMater] = useState([]);
  const [edit, setEdit] = useState({});
  const [onLoan, setOnLoan] = useState(form.getFieldValue("legalStatus") === 'OnLoan');
  const [visStor, setVisStor] = useState(false);
  const [codes, setCodes] = useState({});

  const getMater = useCallback(async () => {
    try {
      const result = await service.list("master/tags/3");
      setMater(result);
    } catch (error) {
      console.error(error);
      message.error("We are experiencing some network related issues. Please try again in a minute.");
    }
  }, []);

  const getPart = useCallback(async (id) => {
    try {
      setLoading(true);
      const result = await service.read('parts', id);
      if (result) {
        setPid(result.partId);
        setEdit({ lastUpdate: result.lastUpdate, editHistory: result.editHistory });
        form.setFieldsValue({
          ...result,
          accessionDate: moment(result.accessionDate),
          material: result.material.split(',')
        });
        setLink(result.report);
      }
      setLoading(false);
    }
    catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
  }, [form]);

  const getObject = useCallback(async (obj) => {
    try {
      setLoading(true);
      const result = await service.read('pieces', `${obj}/master`);
      if (result) {
        setMast({
          participant: result.participant,
          story: result.story,
          object: result.piece
        });
        form.setFieldsValue({
          participantId: result.participant.id,
          storyId: result.story.id,
          objectId: result.piece.id
        });
      }
      setLoading(false);
    }
    catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
  }, [form]);

  const getTags = useCallback(async () => {
    try {
      const result = await service.list("master/tags/2");
      setTags(result);
    } catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
    }
  }, []);

  const getCodes = async () => {
    try {
      const response = await service.list("locationcodes");
      setCodes(response);
    }
    catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
    }
  }

  useEffect(() => {
    if (id === 0) {
      form.setFieldsValue({ id: 0 })
    } else {
      getPart(id);
    }
    getCodes();
    getTags();
    getMater();
    getObject(obj);
    //window.onbeforeunload = onBlur;
  }, [form, id, obj, getPart, getObject, getMater, getTags]);

  const onFinish = async function (values) {
    let { id } = values;
    if (id === undefined) id = 0;
    values.accessionDate = moment(values.accessionDate).format('YYYY-MM-DD');
    if (values.material !== undefined) values.material = values.material.join();
    values.participant = { id: values.participantId };
    values.story = { id: values.storyId };
    values.piece = { id: values.objectId };
    values.digitalCopy = values.digitalCopy === undefined ? '' : values.digitalCopy.join();

    let result;
    if (id === 0) {
      setLoading(true);
      result = await service.insert('parts', values);
      if (result) {
        setLoading(false);
        message.success("Part's info succesfully inserted.");
        window.close();
      } else {
        setLoading(false);
        message.error("Error updating participant's info!");
      }
    } else {
      setLoading(true);
      result = await service.update('parts', values, id);
      if (result) {
        setLoading(false);
        message.success("Object's info succesfully updated.");
        window.close();
      } else {
        setLoading(false);
        message.error("Error updating participant's info!");
      }
    }
  };

  const modalTitle = subTitle => <h3>{subTitle}</h3>;

  // const PdfView = ({link, id}) => {
  //   if (!link) return <div />;
  // return (
  //   <Button style={{ ...Buttons.button, width: '90%', height: '56px', marginLeft: '36px', marginTop: '-12px' }}>
  //     <a href={config.reportPath + link} target='_blank' rel="noopener noreferrer">VIEW</a>
  //   </Button>
  // );
  // }

  const options = {
    name: 'file',
    multiple: false,
    action: config.uploadPath + 'report?id=' + form.getFieldValue('id'),
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onSuccess(body) {
      form.setFieldsValue({ report: body, conditionReport: true });
      setLink(body);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
      }
    }
  }

  const getId = () => {
    let objId = form.getFieldValue('partId').replace('.M', '').replace('.D', '').replace('.C', '');
    if (mast.object.alter.includes('.M')) objId += '.M';
    if (form.getFieldValue('digital')) objId += '.D';
    if (form.getFieldValue('copy')) objId += '.C';
    form.setFieldsValue({ partId: objId });
  }

  const handleCode = async (code) => {
    form.setFieldsValue({ storageCode: code });
    const result = await service.update("parts/storage", { storageCode: code }, form.getFieldValue('id'));
    form.setFieldsValue({ tooltip: result.tooltip });
    setVisStor(false);
  }

  if (loading) return <Spinner />;
  return (
    <Layout>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <Divider style={divide} />
        <Row>
          <Col span={2} offset={2} align='right'>Part ID:&nbsp;</Col>
          <Col span={14}><b>{pid}</b></Col>
        </Row>
        <Row>
          <Col span={2} offset={2} align='right'>Participant:&nbsp;</Col>
          <Col span={14} align='left'><b>{mast.participant.name} ({mast.participant.alter})</b><Form.Item label='' name='participantId' hidden><Input /></Form.Item></Col>
        </Row>
        <Row>
          <Col span={2} offset={2} align='right'>Story:&nbsp;</Col>
          <Col span={14} align='left'><b>{mast.story.name}</b><Form.Item label='' name='storyId' hidden><Input /></Form.Item></Col>
        </Row>
        <Row>
          <Col span={2} offset={2} align='right'>Object:&nbsp;</Col>
          <Col span={14} align='left'><b>{mast.object.alter}: {mast.object.name}</b><Form.Item label='' name='objectId' hidden><Input /></Form.Item></Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label='Id' name='id' hidden><Input /></Form.Item>
            <Form.Item label='Order' name='order' hidden><Input /></Form.Item>
            <EditHistory lastUpdate={edit.lastUpdate} editHistory={edit.editHistory} />
          </Col>
        </Row>
        <Divider style={divide} />
        <Row>
          <Col span={4}>
            <Form.Item label='Part ID' name='partId'><Input size='middle' /></Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item label={labels.NAME} name='name'><Input /></Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={9}>
            <Form.Item label={labels.DESCRIPTION} name='description' onClick={() => setDesc(true)}><Input.TextArea rows={16} /></Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label={labels.TYPE} name='objectType'>
              <Select mode="combobox"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => service.anything(input, option.children)}
                style={{ textAlign: 'left' }}>
                {tags.map(t => (<Select.Option key={t.id} value={t.name}>{t.name}</Select.Option>))}
              </Select>
            </Form.Item>
            <Form.Item label={labels.MATERIAL} name='material'>
              <Select mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => service.anything(input, option.children)}
                style={{ textAlign: 'left' }}>
                {mater.map(m => (<Select.Option key={m.id} value={m.name}>{m.name}</Select.Option>))}
              </Select>
            </Form.Item>
            <Divider style={divide}>Dimensions</Divider>
            <Row>
              <Col span={6}><Form.Item label={labels.LENGTH} name="length"><Input /></Form.Item></Col>
              <Col span={6}><Form.Item label={labels.WIDTH} name="width"><Input /></Form.Item></Col>
              <Col span={6}><Form.Item label={labels.HEIGHT} name="height"><Input /></Form.Item></Col>
              <Col span={6}><Form.Item label={labels.OTHER} name="other"><Input /></Form.Item></Col>
            </Row>
            <Row>
              <Col span={9}>
                <Form.Item label="Shape" name="shape" initialValue=''>
                  <Radio.Group size="small" buttonStyle='solid' style={{ float: 'left' }}>
                    <Radio.Button value='unknown'>n/a</Radio.Button>
                    <Radio.Button value='2D'>2D</Radio.Button>
                    <Radio.Button value='3D'>3D</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label={labels.HANDLING} name="handlingRequirements"><Input /></Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={16} align='right'>Digital: </Col>
              <Col span={8}>
                <Form.Item label='' style={{ marginTop: '-6px' }} name='digital' valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" onChange={getId} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} align='right'>Copy: </Col>
              <Col span={8}>
                <Form.Item label='' style={{ marginTop: '-6px' }} name='copy' valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" onChange={getId} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} align='right'>Handmade: </Col>
              <Col span={8}>
                <Form.Item label='' style={{ marginTop: '-6px' }} name="handmade" valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} align='right'>Broken: </Col>
              <Col span={8}>
                <Form.Item label='' style={{ marginTop: '-6px' }} name="broken" valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} align='right'>Conservation: </Col>
              <Col span={8}>
                <Form.Item label='' style={{ marginTop: '-6px' }} name="conservation" valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} align='right'>Condition report: </Col>
              <Col span={8}>
                <Form.Item label='' style={{ marginTop: '-6px' }} name="conditionReport" valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} align="right">
                <Form.Item style={{ marginTop: '-6px', textAlign: 'left' }} label='' name="report" hidden>
                  <Input style={{ width: '100%', marginLeft: '24px' }} />
                </Form.Item>
                <Upload {...options}>
                  <Button type="primary" icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <PdfView link={link} comment="" part />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ height: '24px' }} />
        <Row>
          <Col span={11}>
            <Form.Item label={labels.LEGAL_STATUS} name='legalStatus' style={{ textAlign: 'left' }}
              onChange={e => setOnLoan(e.target.value === 'OnLoan')}>
              <Radio.Group buttonStyle='solid'>
                <Radio.Button value='Permanent'>Permanent</Radio.Button>
                <Radio.Button value='OnLoan'>On Loan</Radio.Button>
                <Radio.Button value='LoanEnd'>Loan Ended</Radio.Button>
                <Radio.Button value='Deaccessioned'>Deaccessioned</Radio.Button>
                <Radio.Button value='Unknown'>Unknown</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={labels.ACCESSION} name='accessionDate'><DatePicker /></Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={labels.EXHIBITION} name='exhibitionHistory'><Input /></Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={labels.QUANTITY} name="quantity"><Input /></Form.Item>
          </Col>
        </Row>
        {onLoan &&
          <>
            <Divider style={divide} />
            <Row>
              <Col span={9}>
                <Form.Item label={labels.LOAN_STATUS} name='loanStatus' style={{ textAlign: 'left' }}>
                  <Radio.Group buttonStyle='solid'>
                    <Radio.Button value='Null'>None</Radio.Button>
                    <Radio.Button value='OnLoan'>On Loan</Radio.Button>
                    <Radio.Button value='Returned'>Returned</Radio.Button>
                    <Radio.Button value='Extended'>Extended</Radio.Button>
                    <Radio.Button value='Unknown'>Unknown</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label={labels.LOAN_DATE} name='loanDate'><Input /></Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label={labels.LOAN_PERIOD} name='loanPeriod'><Input /></Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label={labels.RETURN_DATE} name='returnDate'><Input /></Form.Item>
              </Col>
            </Row>
          </>
        }
        <Divider style={divide} />
        <Row>
          <Col span={15}>
            <Form.Item label='Notes' name='institution'><TextArea autoSize={{ minRows: 1, maxRows: 3 }} /></Form.Item>
          </Col>
          <Col span={8}>
            {!!form.getFieldValue("storageCode") ?
              <>
                <Form.Item name="tooltip" hidden><Input /></Form.Item>
                <Form.Item label="Storage Code" name="storageCode" initialValue='' tooltip={form.getFieldValue("tooltip")}>
                  <Input className="stepFormField" onClick={() => setVisStor(true)} />
                </Form.Item>
              </> :
              <Row>
                <Col span={20}>
                  <Form.Item label={labels.STORAGE} name="storage" initialValue=''><Input className="stepFormField" /></Form.Item>
                </Col>
                <Col span={4}>
                  <Button type="primary" style={{ marginTop: '28px' }} onClick={() => setVisStor(true)}>Build Code</Button>
                </Col>
              </Row>
            }
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={8} align='left'><Delete dataSet='parts' id={id} onFinish={onClose} /></Col>
          <Col span={8} align='center'><Button style={{ ...Buttons.button, ...Buttons.close }} onClick={onClose}>Close</Button></Col>
          <Col span={8} align='right'><Button style={{ ...Buttons.button, ...Buttons.confirm }} onClick={() => form.submit()}>Save</Button></Col>
        </Row>

        <Modal width={'70%'} open={desc} title={modalTitle('Part Description')} footer={[]} onCancel={() => setDesc(false)} style={{ top: 20 }}>
          <Form.Item {...modout} label='' name="description"><Input.TextArea rows={24} /></Form.Item>
        </Modal>

        <Modal width={'50%'}
          open={visStor}
          title={modalTitle('Storage Location Code')}
          footer={[]}
          style={{ top: 20 }}>
          <Storage
            codes={codes}
            code={form.getFieldValue("storageCode")}
            onOk={handleCode}
            onCancel={() => setVisStor(false)}
          />
        </Modal>

      </Form>
    </Layout >
  );
}

export default PartEdit;
