import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { Form, Select, Input, Radio, Switch, Layout, DatePicker, Modal, Divider, message, Row, Col, Button } from "antd";
import moment from 'moment';
import config from 'config';
import { EditHistory, Curtain, Delete, Title, Buttons } from "common";
import TranscriptList from './TranscriptList';
import { isAdmin } from 'services/authServices';
import labels from "assets/labels/participant.json";
import service from 'services/apiServices';

const { Content } = Layout;

const styles = {
  switch: {
    marginTop: '-12px',
    marginBottom: '18px'
  },
  divV: {
    backgroundColor: '#999',
    height: '420px',
    margin: '0 12px'
  },
  divH: {
    border: '1px solid #efe',
    margin: '-12px 0 4px 0'
  },
  button: {
    width: '150px',
    height: "36px",
    margin: "8px 16px",
    borderRadius: "6px",
    fontWeight: "500",
    border: "none",
    backgroundColor: "#DDC7B0",
    color: "black"
  }
}

const Oral = ({ history }) => {

  if (!isAdmin()) history.push('/oral');

  const [oralId] = useState(parseInt(useParams().id));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const [lang, setLang] = useState([]);
  const [lastEdit, setLastEdit] = useState({});
  const [visib, setVisib] = useState(false);
  const [trans, setTrans] = useState([]);
  const [participant, setParticipant] = useState("");

  const getPeople = useCallback(async () => {
    try {
      let result = await service.list("master/participants"); setPeople(result);
      result = await service.list("master/tags/5"); setLang(result);
      // result = await service.list("master/tags/4"); setTags(result);
    } catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
  }, []);

  const getOral = useCallback(async (id) => {
    try {
      const result = await service.read("oralhistory", id);
      setLastEdit({ lastUpdate: result.lastUpdate, editHistory: result.editHistory });
      form.setFieldsValue({
        ...result,
        participantId: service.toFormat(result.participantId),
        interDate: moment(result.interDate)
      });
      setTrans(result.transcripts);
      setParticipant(result.participant);
      setLoading(false);
    } catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experiencing some network related issues. Please try again in a minute.",
      });
      setLoading(false);
    }
  }, [form]);

  useEffect(() => {
    getPeople();
    if (oralId !== 0) getOral(oralId);
    setLoading(false);
  }, [oralId, getOral, getPeople]);

  const onFinish = async (values) => {
    const data = {
      ...values,
      participant: { id: service.toNumber(values.participantId) },
      //interDate: values.interDate ? moment(values.interDate).format('LL') : new Date(0, 0)
    };
    setLoading(true);
    const result = data.id === undefined ? await service.insert('oralhistory', data) : await service.update('oralhistory', data, data.id);
    if (result) message.success("Oral history successfully updated in the database.");
    setLoading(false);
    history.push('/oral');
  }

  const nameOpt = (id, name) => name + ' (' + service.toFormat(id) + ')';

  if (loading) return <Curtain />;
  return (
    <Layout className="container">
      <Content align="center">
        <Title align="center">Oral History for Participant {form.getFieldValue('participantId')}</Title>
        <div style={{ textAlign: 'center' }}>
          <EditHistory lastUpdate={lastEdit.lastUpdate} editHistory={lastEdit.editHistory} />
        </div>
        <div style={{ clear: 'both' }} />
        <Form form={form} layout='vertical' size='small' onFinish={onFinish}>
          {config.currentUser.delHistories && <Delete dataSet='oralHistory' id={form.getFieldValue('id')} redirect='/oral' />}
          <Button style={Buttons.close} onClick={() => history.push('/oral')}>Close</Button>
          <Button style={Buttons.confirm} htmlType='submit'>S A V E</Button>
          <hr />

          <Form.Item name="id" hidden><Input /></Form.Item>
          <Row>
            <Col span={7} style={{ borderRight: '2px solid lightgrey', padding: '8px' }}>
              <Form.Item label="Contributor" name="participantId">
                <Select className="oralFormField" mode="combobox"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}
                  style={{ textAlign: 'left' }}>
                  {people.map(p => (<Select.Option key={p.id} value={service.toFormat(p.id)}>{nameOpt(p.id, p.name)}</Select.Option>))}
                </Select>
              </Form.Item>
              <Divider style={styles.divH} />
              <Row>
                <Col span={16}>
                  <Form.Item label={labels.MEDIA_TYPE} name='mediaType' style={{ textAlign: 'left' }}>
                    <Radio.Group buttonStyle='solid' size='small'>
                      <Radio.Button value='Audio'>Audio</Radio.Button>
                      <Radio.Button value='Video'>Video</Radio.Button>
                      <Radio.Button value='Other'>Other</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={labels.LENGTH} name="length" initialValue=""><Input className="oralFormField" /></Form.Item>
                </Col>
              </Row>
              <Divider style={styles.divH} />
              <Form.Item label={labels.INTERVIEWER} name="interviewer" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.LOCATION} name="location" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.INTER_DATE} name="interDate"><DatePicker className="stepFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.INTER_NOTES} name="interNotes" initialValue=""><Input className="stepFormField" /></Form.Item>
            </Col>

            <Col span={7} style={{ borderRight: '2px solid lightgrey', padding: '8px' }}>
              <Form.Item style={{ textAlign: 'left' }} label=' ' name="transcribed" valuePropName="checked" hidden><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Form.Item label={labels.TRANSCRIBER} name="transcriber" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label=' ' name="translated" valuePropName="checked" hidden><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Form.Item label={labels.TRANSLATOR} name="translator" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label=' ' name="editedBHS" valuePropName="checked" hidden><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Form.Item label="Editor" name="editorBHS" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label=' ' name="editedEng" valuePropName="checked" hidden><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Form.Item label={labels.EDITOR_ENG} name="editorEng" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.NATIVE_LANGUAGE} name="nativeLanguage" initialValue="">
                <Select mode="combobox" className="oralFormField"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => service.anything(input, option.children)}
                  style={{ textAlign: 'left' }}>
                  {lang.map(p => (<Select.Option key={p.id} value={p.name}>{p.name}</Select.Option>))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={7} style={{ borderRight: '2px solid lightgrey', padding: '8px' }}>
              <Form.Item label={labels.TRANSCRIPT_URL} name="transcriptUrl" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.MEDIA_URL} name="mediaUrl" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.NOTES} name="notes" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.TRANS_STORAGE} name="storageTran" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label={labels.TEST_STORAGE} name="storageTest" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <div style={{ height: '24px' }} />
              <Button style={styles.button} onClick={() => setVisib(true)}>T r a n s c r i p t</Button>
            </Col>
            <Col span={3} style={{ padding: '8px' }}>
              <Form.Item label={labels.PAGES} name="pages" initialValue=""><Input className="oralFormField" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label={labels.SENT} name="sent" valuePropName="checked"><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label={labels.RECORDING} name="recording" valuePropName="checked"><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label={labels.EDITED_VIDEO} name="editedVideo" valuePropName="checked"><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label={labels.SUBTITLES} name="subtitles" valuePropName="checked"><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item style={{ textAlign: 'left' }} label={labels.TAGGED} name="tagged" valuePropName="checked"><Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Divider style={styles.divH} />
              <Form.Item label='Restrictions on use' name='restrictions'
                valuePropName='checked' style={{ textAlign: 'left' }}>
                <Switch style={styles.switch} checkedChildren="YES" unCheckedChildren="NO" />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Modal
          width={'90%'}
          style={{ top: '20px' }}
          open={visib} title={<h2>{participant}: <small>Oral History Transcript</small></h2>}
          footer={[]}
          onCancel={() => setVisib(false)}
          destroyOnClose={true}>
          <TranscriptList data={trans} oral={form.getFieldValue('id')} />
        </Modal>
      </Content>
    </Layout >
  );
}

export default Oral;
