import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  CondField,
  SingleField,
  TextField
} from "../fields";
import { Curtain } from "common";

const Page15 = () => {
  const page = 15;
  const [loading, setLoading] = useState(false);
  const qn = ["4.23", "4.24", "4.25", "4.26", "4.27", "4.271"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);
    
  const [v427, setV427] = useState("No");

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV427(result.answers[4].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <SingleField
            form={form}
            label="23. Did you have running water in your accommodation? "
            question="c4.23"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="24. How did you obtain water?"
            question="c4.24"
            extension="e4.24"
            options={[
              "Well in a backyard",
              "Well in a neighborhood",
              "Spring (river)",
              "Cistern",
              "I don't remember",
            ]}
            other="Other:"
          />
          <SingleField
            form={form}
            label="25. How far away was the water source from your home?"
            question="c4.25"
            extension="e4.25"
            options={[
              "Up to 300 m (3 min)",
              "Up to 500 m (5 min)",
              "Up to 1000 m (10 min)",
              "Up to 2000 m (20 min)",
              "More than 2000 m",
              "I don't remember",
            ]}
            other="Other:"
          />

          <TextField
            form={form}
            label="26. What did you use to carry the water?"
            question="c4.26"
          />
          <CondField
            form={form}
            label="27. Did you have any equipment for transporting water (cart)?"
            question="c4.27"
            trigger="Yes"
            current={v427}
            options={["Yes", "No"]}
            subQuestion="c4.271"
            subLabel="27.1 If yes, what did you use?"
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page15;
