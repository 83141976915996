import React, { useEffect, useState } from "react";
import { Form, Input, Layout, Row, Col, message } from "antd";
import moment from 'moment';
import _ from 'lodash';
import config from "config";
import service from "services/apiServices";

const Confirmation = ({ form, labels, finalSave }) => {

  const [confName, setConf] = useState('');
  const [cellName, setCell] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getMasters() {
      const confId = form.getFieldValue('conflictId');
      const cellId = form.getFieldValue('researchCellId');
      var result = await service.read("master/conflicts", confId);
      if (result) setConf(result.name);
      result = await service.read("master/researchcells", cellId);
      if (result) setCell(result.name);
    }
    getMasters();
  }, [form]);

  const onUpdate = async function (values) {
    let result = null;
    let { id } = values;
    values.participationDate = moment(values.participationDate).format('YYYY-MM-DD');
    if (id === undefined) id = '0';
    setLoading(true);
    if (id === '0') {
      result = await service.insert('participants', values);
      config.participants.push(result);
      config.participants = _.orderBy(config.participants, 'id', 'asc');
    } else {
      result = await service.update('participants', values, id);
      let i = _.findIndex(config.participants, { id: result.id });
      config.participants.splice(i, 1, result);
    }
    let i = _.findIndex(config.participants, { id: result.id });
    config.headers.page = Math.ceil((i + 1) / 10);
    setLoading(false);
    if (result) {
      message.success("Participant's info succesfully updated");
      finalSave();
    } else {
      message.error("Error updating participant's info!");
    }
  };

  const Show = ({ label, value, type }) => {
    let x = form.getFieldValue(value);
    switch (type) {
      case "date": x = moment(x).format("YYYY-MM-DD"); break;
      case "gend": x = config.gender[x]; break;
      case "conf": x = confName; break;
      case "cell": x = cellName; break;
      default: break;
    }
    return (<Row style={{ margin: '6px 0' }}>
      <Col span={12} style={{ textAlign: 'right' }}>
        {label}:&nbsp;
      </Col>
      <Col span={12} style={{ textAlign: 'left' }}>
        <b>{x}</b>
      </Col>
    </Row>);
  }

  const Curtain = () => {
    return (
      <div className="curtain">
        <div className="circle" />
      </div>
    );
  };

  return (
    <Layout className='container'>
      {loading && <Curtain />}
      <Form form={form} onFinish={onUpdate}>
        <Show key={0} label={labels.ID} value="id" />
        <Show key={19} label={labels.ID} value="pid" />
        <Show key={12} label={labels.STATEMENT_ID} value="statementId" />
        <Show key={1} label={labels.FIRST_NAME} value="firstName" />
        <Show key={2} label={labels.LAST_NAME} value="lastName" />
        <Show key={3} label={labels.GENDER} value='gender' type='gend' />
        <Show key={4} label={labels.YEAR_OF_BIRTH} value="year" />
        <Show key={5} label={labels.BIRTH_PLACE} value="birthPlace" />
        <Show key={20} label="Hometown/home country" value="homeTownCountry" />
        <Show key={6} label={labels.LEGAL_GUARDIAN} value="legalGuardian" />
        <Show key={7} label={labels.CONFLICT} value="conflictId" type="conf" />
        <Show key={9} label={labels.RESEARCH_CELL} value="researchCellId" type="cell" />
        <Show key={10} label={labels.INSTITUTION} value="institution" />
        <Show key={11} label={labels.PARTICIPATION_DATE} value="participationDate" type="date" />
        <Show key={13} label={labels.NOTES} value="notes" />
        <Show key={14} label={labels.RETURN_COUNTRY} value="returnHomeCountry" />
        <Show key={15} label={labels.RETURN_TOWN} value="returnHomeTown" />
        <Show key={16} label={labels.CURRENT_RESIDENCE} value="currentResidence" />
        <Show key={17} label={labels.PHONE} value="phone" />
        <Show key={18} label={labels.EMAIL} value="email" />

        <div hidden>
          <Form.Item label={labels.ID} name="id"><Input /></Form.Item>
          <Form.Item label={labels.ID} name="pid"><Input /></Form.Item>
          <Form.Item label={labels.STATEMENT_ID} name="statementId"><Input /></Form.Item>
          <Form.Item label={labels.FIRST_NAME} name="firstName"><Input /></Form.Item>
          <Form.Item label={labels.LAST_NAME} name="lastName"><Input /></Form.Item>
          <Form.Item label={labels.GENDER} name='gender'><Input /></Form.Item>
          <Form.Item label={labels.YEAR_OF_BIRTH} name="year"><Input /></Form.Item>
          <Form.Item label={labels.BIRTH_PLACE} name="birthPlace"><Input /></Form.Item>
          <Form.Item label={labels.CONFLICT} labelAlign="top" name="conflictId"><Input /></Form.Item>
          <Form.Item label={labels.LEGAL_GUARDIAN} name="legalGuardian"><Input /></Form.Item>
          <Form.Item label="Hometown/home country" name="homeTownCountry"><Input /></Form.Item>
          <Form.Item label={labels.RETURN_COUNTRY} name="returnHomeCountry"><Input /></Form.Item>
          <Form.Item label={labels.RETURN_TOWN} name="returnHomeTown"><Input /></Form.Item>
          <Form.Item label={labels.CURRENT_RESIDENCE} name="currentResidence"><Input /></Form.Item>
          <Form.Item label={labels.PHONE} name="phone"><Input /></Form.Item>
          <Form.Item label={labels.EMAIL} name="email"><Input /></Form.Item>
          <Form.Item label={labels.PARTICIPATION_DATE} name="participationDate"><Input /></Form.Item>
          <Form.Item label={labels.RESEARCH_CELL} labelAlign="top" name="researchCellId"><Input /></Form.Item>
          <Form.Item label={labels.INSTITUTION} name="institution"><Input /></Form.Item>
          <Form.Item label={labels.NOTES} name="notes"><Input /></Form.Item>
          <Form.Item label="Initial Survey" name="initialSurvey"><Input /></Form.Item>
          <Form.Item label="Statement" name="statement"><Input /></Form.Item>
          <Form.Item label="Refugee" name="refugee"><Input /></Form.Item>
          <Form.Item label="Returnee" name="returnee"><Input /></Form.Item>
          <Form.Item label="Idp" name="idp"><Input /></Form.Item>
          <Form.Item label="Stateless" name="stateless"><Input /></Form.Item>
          <Form.Item label="unChild" name="unChild"><Input /></Form.Item>
          <Form.Item label="Restrictions" name="restrictions"><Input /></Form.Item>
        </div>
      </Form >
    </Layout >
  );
};

export default Confirmation;
