import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

import service from 'services/apiServices';
import logobw from 'assets/images/logobw@3x.png';
import Curtain from 'common/curtain';

const Reset = ({ onClose }) => {

  const [form] = Form.useForm();
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);

  const onFinish = async function (values) {
    try {
      setLoad(true);
      let result = await service.reset(values.address);
      setMessage(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  }

  const onFailed = error => console.error(error);

  return (
    <>
      {load && <Curtain />}
      <img src={logobw} alt="logo" className="logo-bw" />
      <div className='login-form'>

        {!!message &&
          <>
            <div className='pin-title'>Thank you!</div>
            <div style={{ margin: '24px 0' }} />
            <div className='rst-text'>
              If your email address is in our database,<br />
              we'll send you a message for password reset.<br />
              You can change your password within next 30 minutes.
            </div>
          </>
        }

        {!message &&
          <div>
            <div className='pin-title'>Reset Your Password</div>
            <div style={{ margin: '24px 0' }} />
            <div className='rst-text'>
              If you forgot your password, we can send you a link<br />to reset password and get back into your account!
            </div>
            <div style={{ margin: '24px 0' }} />
            <Form form={form} onFinish={onFinish} onFinishFailed={onFailed}>
              <Form.Item name="address" label="">
                <Input
                  type="email"
                  placeholder='Enter your email address please'
                  style={{ width: '90%' }} />
              </Form.Item>
              <Button
                type="primary"
                className="buttonLogIn"
                htmlType="submit">
                Send me email link to reset Password!
              </Button>
            </Form>
          </div>}
        <div style={{ margin: '24px 0' }} />
        <Button
          type="primary"
          className="buttonLogIn"
          onClick={onClose}
          htmlType="button">
          Back to Login Form
        </Button>
      </div>
    </>
  );
}

export default Reset;