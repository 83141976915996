export default {
  participant: "",
  story: "",
  page: 1,
  size: 10,
  total: 0,
  sort: 0,
  filter: "",
  items: [],
  reload: true,
  message: ""
};