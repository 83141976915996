import React from "react";
import moment from 'moment';
import { Table, Layout } from "antd";

const columns = [
  { key: "time", dataIndex: "time", title: "Time", width: '15%', render: (time) => moment(time).format('YYYY-MM-DD') },
  { key: "type", dataIndex: "type", title: "Type", width: '15%' },
  { key: "comment", dataIndex: "comment", title: "Comment", width: '45%' },
  { key: "user", dataIndex: "user", title: "User", width: '25%' }
];

const { Content } = Layout;

const NotesView = ({ data }) => {

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={false}
            size="small"
            tableLayount={"fixed"}
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
    </div>
  );
};

export default NotesView;
