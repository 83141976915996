import React from 'react';
import { Form, Radio, Input, Space } from 'antd';

const SingleField = ({ form, label, question, extension, options, other, check }) => {

  const clearExtension = e => {
    if (other) form.setFieldValue(extension, "");
    if(check && e.target.value) check(e.target.value);
  }

  const clearOption = () => { 
    form.setFieldValue(question, "Other"); 
  }

  return (
    <>
      <div className="quest-label">{label}</div>
      <Form.Item label="" name={question} onClick={clearExtension} initialValue="">
        <Radio.Group size="small">
          <Space direction="vertical">
            {options.map(o => <Radio key={o} value={o}><span style={{ fontSize: '12px', color:'black' }}>{o}</span></Radio>)}
          </Space>
        </Radio.Group>
      </Form.Item>

      {!!other && <>
        <div className="quest-label" style={{ float:'left', fontSize: '12px', backgroundColor:'white', marginTop:'-16px' }}>{other}</div>
        <Form.Item label="" name={extension} onFocus={clearOption} style={{marginTop:'-24px'}} initialValue="">
          <Input className="quest-input" />
        </Form.Item>
      </>}
    </>
  );
}

export default SingleField;
