import React from 'react';
import PdfView from "common/ViewPdf";

const Condition = ({ document, reports }) => {
  if(document) return <PdfView link={document} comment="" />

  return (
    <div style={{ width: '100%' }}>
      {reports && reports.length && reports.map(report => <PdfView key={report.id} link={report.name} comment={report.note} />) }
    </div>
  );
}

export default Condition;
