import React, { useState, useEffect } from 'react';
import { Divider, Row, Col, Button } from 'antd';
import apiServices from 'services/apiServices';
import { Spinner } from 'common';

const P2S = ({ id, onTrans }) => {

  const [object, setObject] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchObject = async () => {
    setLoading(true);
    const response = await apiServices.read('transfer/p2s', id);
    setLoading(false);
    setObject(response);
  }

  useEffect(() => {
    fetchObject();
    // eslint-disable-next-line
  }, []);

  const transfer = e => {
    onTrans(object);
  }

  if (loading) return <Spinner />;
  return (
    <>
      <h2>{object.name}</h2>
      <Divider />
      <Row>
        <Col span={8} align="center">
          <h4>Old object ID</h4>
          {<h1>{object.oldId}</h1>}
        </Col>
        <Col span={8} align="center">
          <Button type="primary" onClick={transfer}>CONFIRM TRANSFER</Button>
        </Col>
        <Col span={8} align="center">
          <h4>New object ID</h4>
          {<h1>{object.newId}</h1>}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={8} offset={4}>
          <h4>Object Contributor<br />{object.objectContributor}</h4>
        </Col>
        <Col span={12}>
          <h4>Contributor Contact<br />{object.contributorContact}</h4>
        </Col>
      </Row>
    </>
  );
}

export default P2S;