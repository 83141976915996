import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Row, Col, Divider, message } from 'antd';
import service from 'services/apiServices';
import TrancriptTagForm from './TrancriptTagForm';

const layout = { labelCol: { span: 3 }, wrapperCol: { span: 21 } }

const TranscriptForm = ({ transcriptId, oralId, onClose }) => {

  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [tagList, setTagList] = useState([]);

  const getTags = async () => {
    const result = await service.list('master/otags');
    setTags(result);
  }

  const fetch = async () => {
    const record = await service.list(`transcripts/${oralId}/${transcriptId}`);
    form.setFieldsValue(record);
    setTagList(record.tags);
  }

  useEffect(() => {
    fetch();
    if (tags.length === 0) getTags();
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    const response = values.id === 0 ? await service.insert('transcripts', values) : await service.update('transcripts', values, values.id);
    form.setFieldsValue(response);
  }

  const onSubmitTag = async values => {
    let transcriptId = form.getFieldValue('id');
    if (transcriptId === 0) {
      message.error('Please save the transcript first');
    } else {
      if (!values.subtag) values.subtag = 0;
      values = { ...values, transcriptId };
      await service.insert('transcripts/tags', values);
      await fetch();
    }
  }

  const remove = async id => {
    await service.remove('transcripts/tags', id);
    await fetch();
  }

  return (
    <Row>
      <Col span={14} style={{ borderRight: '1px solid darkgrey' }}>
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>
          <Form.Item label="Oral History" name="oralHistoryId" hidden><Input /></Form.Item>
          <Form.Item label="Time" name="time"><Input style={{ width: '50%' }} /></Form.Item>
          <Form.Item label="Text" name="text"><Input.TextArea autoSize={{ minRows: 12, maxRows: 24 }} /></Form.Item>
          <Button type="primary" className="right" htmlType="submit">Submit</Button>
          <Button type="primary" style={{ marginRight: '23px' }} className="right" htmlType="button" onClick={onClose}>Close</Button>
        </Form>
      </Col>
      <Col span={10} style={{ borderTop: '1px solid darkgrey', padding: '0 8px' }}>
        <TrancriptTagForm tags={tags} onSubmit={onSubmitTag} />
        <Divider style={{ margin: '4px 0' }} />
        {!!tagList && tagList.length > 0 && tagList.map(t =>
          <div className="row" key={t.id}>
            {t.tag}: {t.subtag}
            <Button type='primary' style={{marginTop:'-6px'}} htmlType='button' onClick={() => remove(t.id)}>DELETE</Button>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default TranscriptForm;