import React from 'react';

let lightStep = 0;
let darkStep = 0;
const ra = [];
const ga = [];
const ba = [];
for (let i = 0; i < 21; i++) {
  ra[i] = 0;
  ga[i] = 0;
  ba[i] = 0;
}

const hex = hNum => {
  const x = '0123456789abcdef';
  const y = hNum.substring(0, 1);
  const z = hNum.substring(1, 2);
  return x.indexOf(y) * 16 + x.indexOf(z);
}

const clr = dNum => {
  const x = '0123456789abcdef';
  const a = Math.floor(dNum / 16);
  const b = dNum % 16;
  return x.substring(a, a + 1) + x.substring(b, b + 1);
}

const getArray = (arr, value) => {
  lightStep = (255 - value) / 10;
  darkStep = value / 10;
  arr[10] = value;
  for (let i = 1; i <= 10; i++) {
    arr[10 - i] = (value + i * lightStep).toFixed(0);
    arr[10 + i] = (value - i * darkStep).toFixed(0);
  }
}

const Color = ({ color }) => {

  if (color.substring(0, 1) === '#') color = color.substring(1);
  if (color.length === 3) color = color.substring(0,1) + color.substring(0,1) + color.substring(1,2) + color.substring(1,2) + color.substring(2,3) + color.substring(2,3);

  getArray(ra, hex(color.substring(0, 2)));
  getArray(ga, hex(color.substring(2, 4)));
  getArray(ba, hex(color.substring(4, 6)))

  return (
    <div>
      <h1>PALETTE {color}</h1>
      <hr />
      <div>
        <div className='card-container' style={{height:'20vh'}}>
          {[...Array(10)].map((e, i) =>
            <div className='card-item' key={i} style={{ height:'120px', border: '1px solid black', backgroundColor: 'rgb(ra[i], ga[i], ba[i])' }}>
              <h4>#{clr(ra[i])}{clr(ga[i])}{clr(ba[i])}</h4>
              <div style={{ width: '100%', height:'58%', margin: 0, padding: 0, backgroundColor: `#${clr(ra[i])}${clr(ga[i])}${clr(ba[i])}` }} />
            </div>
          )}
        </div>

        <div className='card-container' style={{height:'20vh'}}>
          <div className='card-item' style={{ border: '1px solid black', backgroundColor: 'rgb(ra[10], ga[10], ba[10])' }}>
            <h3>#{clr(ra[10])}{clr(ga[10])}{clr(ba[10])}</h3>
            <div style={{ width: '100%', height:'63%', margin: 0, padding: 0, backgroundColor: `#${clr(ra[10])}${clr(ga[10])}${clr(ba[10])}` }} />
          </div>
        </div>

        <div className='card-container' style={{height:'20vh'}}>
          {[...Array(10)].map((e, i) =>
            <div className='card-item' key={11 + i} style={{ border: '1px solid black', backgroundColor: 'rgb(ra[i+11], ga[i+11], ba[i+11])' }}>
              <h4>#{clr(ra[i + 11])}{clr(ga[i + 11])}{clr(ba[i + 11])}</h4>
              <div style={{ width: '100%', height:'68%', margin: 0, padding: 0, backgroundColor: `#${clr(ra[i + 11])}${clr(ga[i + 11])}${clr(ba[i + 11])}` }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Color;
