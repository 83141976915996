import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  CondField,
  SingleField,
  TextField
} from "../fields";
import { Curtain } from "common";

const Page18 = () => {
  const page = 18;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["4.40", "4.41", "4.411", "4.42", "4.421", "4.43", "4.44"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);
  const [v441, setV441] = useState("No");
  const [v442, setV442] = useState("No");

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV441(result.answers[1].choice);
      setV442(result.answers[3].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <SingleField
            form={form}
            label="40. What were the prices of groceries?"
            question="c4.40"
            extension="e4.40"
            options={[
              "Much higher than the price in the pre-conflict period",
              "Slightly higher than the price in the pre-conflict period",
              "Equal price to the one in the pre-conflict period",
              "Lower price than the price in the pre-conflict period",
              "We would exchange other merchandise for groceries",

              "I don’t remember",
            ]}
            other="Other:"
          />
          <CondField
            form={form}
            label="41. Did you grow fruits and vegetables during the conflict?"
            question="c4.41"
            trigger="Yes"
            current={v441}
            options={["Yes", "No", "I don't remember"]}
            subQuestion="c4.411"
            subLabel="41.1 If yes, where?"
          />
          <CondField
            form={form}
            label="42. Did you receive humanitarian aid?"
            question="c4.42"
            trigger="Yes"
            current={v442}
            options={["Yes", "No"]}
            subQuestion="c4.421"
            subLabel="42.1 If yes, what did you usually receive?"
          />
          <SingleField
            form={form}
            label="43.	What did you use for cooking your food?"
            question="c4.43"
            extension="e4.43"
            options={[
              "Gas stove",
              "Wood stove",
              "Electric stove",
              "I don’t remember",
            ]}
            other="Other:"
          />
  <TextField
            form={form}
            label="44. What did you usually eat during armed conflict?"
            question="c4.44"
          />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page18;
