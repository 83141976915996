import React, { useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, CondOptions, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page20 = () => {
  const page = 20;
  const [loading, setLoading] = useState(false);
  const qn = ["4.53", "4.54", "4.55", "4.56", "4.57", "4.58", "4.59", "4.60", "4.61", "4.62", "4.63", "4.631", "4.64", "4.641", "4.65", "4.66", "4.67", "4.671"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v463, setV463] = useState("No");
  const [v464, setV464] = useState("No");
  const [v467, setV467] = useState("No");
  const [visible, setVisible] = useState(false);

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setVisible(result.answers[0].choice === "Yes")
      if(result.answers[10]) setV463(result.answers[10].choice);
      if(result.answers[12]) setV464(result.answers[12].choice);
      if(result.answers[16]) setV467(result.answers[16].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  const YesOrNo = e => {
    setVisible(e.target.value === "Yes");
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item label={<div className="quest-label">53. Were you attending school?</div>} name="c4.53" onChange={YesOrNo}>
            <Radio.Group>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>

          {visible && <div>
            <TextField form={form} label="54. At how many different locations did you attend school?" question="c4.54" />

            <TextField form={form} label="55. How far was your school from home?" question="c4.55" />

            <SingleField form={form} label="56. Where were your classes organized?"
              question="c4.56" other="Other:" extension="e4.56"
              options={[
                "In classrooms of a school building",
                "In a basement of a house/apartment"
              ]} />

            <SingleField form={form} label="57. How often did you go to school?"
              question="c4.57" other="Other:" extension="e4.57"
              options={[
                "Every work day",
                "Every work day except for days of intensive shelling",
                "Two or three days per week",
                "From time to time", "I don't remember"
              ]} />

            <SingleField form={form} label="58. Did you experience bombing/shelling of school building(s) during classes?"
              question="c4.58" options={["Yes", "No", "I don't remember"]} />

            <SingleField form={form} label="59. Was a teacher/student wounded or killed during classes?"
              question="c4.59" options={["Yes", "No", "I don't remember"]} />

            <SingleField form={form} label="60. Were some of the locations near your school shelled/hit by a missile while you were in class?"
              question="c4.60" options={["Yes", "No"]} />

            <SingleField form={form} label="61. How did you obtain food while at school?"
              question="c4.61" other="Other:" extension="e4.61"
              options={[
                "I would bring my own lunch",
                "We received lunch at school",
                "I didn't have lunch",
                "I don't remember"
              ]} />

            <SingleField form={form} label="62. How many children were in your class?"
              question="c4.62"
              options={[
                "Around 10",
                "Around 15",
                "Around 20",
                "Around 25",
                "Around 30",
                "Around 35",
                "I don't remember"
              ]} />

            <CondField form={form} label="63. Were there any extracurricular activities organized in school or by the school?"
              question="c4.63" options={["Yes", "No"]} trigger="Yes" current={v463}
              subLabel="63.1 If yes, which ones?" subQuestion="c4.631"
              subOther={false} subOptions={[]} />

            <CondOptions form={form} label="64. Did you have textbooks?" question="c4.64"
              options={["Yes", "No", "I don't remember"]} trigger="Yes" current={v464}
              subLabel="64.1 If yes, how did you obtain them?" subQuestion="c4.641" subOther="Other:" subExtension="e4.641"
              subOptions={[
                "I received them in school/humanitarian aid",
                "I used my older brother/sister/friend's textbooks",
                "I bought my textbooks",
                "I don't remember"
              ]} />

            <SingleField form={form} label="65. Did you have notebooks and school supplies?" question="c4.65"
              options={["Yes, some basic things", "Yes, everything", "No"]} />

            <SingleField form={form} label="66. Who were the teachers at your school?"
              question="c4.66" other="Other:" extension="e4.66"
              options={[
                "Teacher (by profession)",
                "Teacher (with no training as a teacher)"
              ]} />

            <CondField form={form} label="67. The curriculum you were following was:" question="c4.67"
              options={[
                "Same as the curriculum before armed conflict",
                "Different from the curriculum before armed conflict"
              ]}
              trigger="Different" current={v467}
              subLabel="67.1 If different, please describe:" subQuestion="c4.671" />
          </div>}

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page20;
