import React, { useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, CondOptions, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page9 = () => {
  const page = 9;
  const [loading, setLoading] = useState(false);
  const qn = ["3.44", "3.45", "3.46", "3.47", "3.48", "3.49", "3.50", "3.51", "3.52", "3.53", "3.54", "3.541", "3.55", "3.551", "3.56", "3.57", "3.58", "3.581"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent);
  const [v354, setV354] = useState("No");
  const [v355, setV355] = useState("No");
  const [v358, setV358] = useState("No");
  const [visible, setVisible] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setVisible(result.answers[0].choice === "Yes")
      setV354(result.answers[10].choice);
      setV355(result.answers[12].choice);
      setV358(result.answers[16].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      };
      setVisible(result.answers[0].choice === "Yes");
    }
  };
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }

  const YesOrNo = e => {
    setVisible(e.target.value === "Yes");
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <Form.Item label={<div className="quest-label">44. Were you attending school?</div>} name="c3.44" onChange={YesOrNo}>
            <Radio.Group>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>

          {visible && <div>
            <TextField form={form} label="45. How far was your school from home?" question="c3.45" />

            <SingleField form={form} label="46. Where were your classes organized?"
              question="c3.46" other="Other:" extension="e3.46"
              options={[
                "In classrooms of a school building",
                "In a basement of a house/apartment"
              ]} />

            <SingleField form={form} label="47. How often did you go to school?"
              question="c3.47" other="Other:" extension="e3.47"
              options={[
                "Every work day",
                "Every work day except for days of intensive shelling",
                "Two or three days per week",
                "From time to time", "I don't remember"
              ]} />

            <TextField form={form} label="48. At how many different locations did you attend school?" question="c3.48" />

            <SingleField form={form} label="49. Did you experience bombing/shelling of school building(s) during classes?"
              question="c3.49" options={["Yes", "No", "I don't remember"]} />

            <SingleField form={form} label="50. Was a teacher/student wounded or killed during classes?"
              question="c3.50" options={["Yes", "No", "I don't remember"]} />

            <SingleField form={form} label="51. Were some of the locations near your school shelled/hit by a missile while you were in class?"
              question="c3.51" options={["Yes", "No"]} />

            <SingleField form={form} label="52. How did you obtain food while at school?"
              question="c3.52" other="Other:" extension="e3.52"
              options={[
                "I would bring my own lunch",
                "We received lunch at school",
                "I didn't have lunch",
                "I don't remember"
              ]} />

            <SingleField form={form} label="53. How many children were in your class?"
              question="c3.53"
              options={[
                "Around 10",
                "Around 15",
                "Around 20",
                "Around 25",
                "Around 30",
                "Around 35",
                "I don't remember"
              ]} />

            <CondField form={form} label="54. Were there any extracurricular activities organized in school or by the school?"
              question="c3.54" options={["Yes", "No"]} trigger="Yes" current={v354}
              subLabel="54.1 If yes, which ones?" subQuestion="c3.541"
              subOther={false} subOptions={[]} />

            <CondOptions form={form} label="55. Did you have textbooks?" question="c3.55"
              options={["Yes", "No", "I don't remember"]} trigger="Yes" current={v355}
              subLabel="55.1 If yes, how did you obtain them?" subQuestion="c3.551" subOther="Other:" subExtension="e3.551"
              subOptions={[
                "I received them in school/humanitarian aid",
                "I used my older brother/sister/friend's textbooks",
                "I bought my textbooks",
                "I don't remember"
              ]} />

            <SingleField form={form} label="56. Did you have notebooks and school supplies?" question="c3.56"
              options={["Yes, some basic things", "Yes, everything", "No"]} />

            <SingleField form={form} label="57. Who were the teachers at your school?"
              question="c3.57" other="Other:" extension="e3.57"
              options={[
                "The same teachers as before the outbreak of armed conflict",
                "New teachers (teachers by profession)",
                "New teachers (with no training as teachers)"
              ]} />

            <CondField form={form} label="58. The curriculum you were following was:" question="c3.58"
              options={[
                "Same as the curriculum before armed conflict",
                "Different from the curriculum before armed conflict"
              ]}
              trigger="Different" current={v358}
              subLabel="58.1 If different, please describe:" subQuestion="c3.581" />
          </div>}
        </Form>

        <Navigator prev={page-1} next={page+1} save={() => form.submit()} />
      </div>

    </>
  );
}

export default Page9;
