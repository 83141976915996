import React from "react";
import { Button } from "antd";
import { Link } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import config from "config";
import restrict from 'assets/images/restricted.png'

const TableHead = (colProps, canEdit, editUrl, canAdd, editModal) => {

  const canView = 'parts,participants,stories,objects,oralHistory'.includes(editUrl);
  canAdd = canAdd && !(config.currentUser.role === 'Intern' || config.currentUser.role === 'User');
  const elipse = editUrl && (
    editUrl === 'participants' ||
    editUrl === 'stories' ||
    editUrl === 'objects' ||
    editUrl.substring(0, 5) === 'parts');

  const target = editUrl.substring(0, 5) === 'parts' ? "_blank" : "_self";

  const AddNew = () => {
    if (hasAccess(0, 0, 0) || canAdd) {

      if (editModal) {
        return (<Button className='tableButton' size='small' style={{ backgroundColor: 'black', color: 'white' }} onClick={() => editModal(0)}>ADD NEW</Button>);
      }

      return (<Link to={`/${editUrl}/0`} target={target}>
        <Button className='tableButton' size='small' style={{ backgroundColor: 'black', color: 'white' }}>
          ADD NEW
        </Button>
      </Link>);
    } else {
      return (<div />);
    }
  }

  const Edit = ({ id }) => {

    if (editModal) {
      return (<Button className='tableButton' size='small' onClick={() => editModal(id)}>EDIT</Button>);
    }

    return (
      <Link to={`/${editUrl}/${id}`} target={target} onClick={e => e.stopPropagation()}>
        <Button className='tableButton' size="small">
          EDIT
        </Button>
      </Link>
    );
  }

  const Correct = ({ id }) => {
    return (
      <Link to={`/${editUrl}/${id}`} onClick={e => e.stopPropagation()}>
        <CheckCircleOutlined style={{color:'green', fontSize:'1.2rem'}} title="correct the wrong report" />
      </Link>
    );
  }

  const View = ({ id }) =>
    <Button className='tableButton' size="small">
      VIEW
    </Button>;

  const Restrict = () => <img src={restrict} style={{ width: 24 }} alt='restrict' title="Use of some materials is restricted upon Participant's request." />;

  const hasAccess = (id, creator, conflict, participantId) => {
    if (config.currentUser.role === 'SuperAdmin') return true;
    if (config.currentUser.role === 'Admin' && editUrl === 'oral') {
      const sid = Math.floor(participantId / 10000).toString();
      return config.currentUser.conflict.includes(`,${sid},`);
    }
    if (config.currentUser.role === 'User') return false;
    if (elipse) {
      if (editUrl.substring(0, 6) === 'parts/') {
        //const pid = editUrl.substring(6);
        return true;
      }
      if (config.currentUser.role === "Intern") {
        if (!(editUrl.substring(0, 7) === "objects" || editUrl.substring(0, 5) === "parts")) return false;
        if (id === 0) return true;
        // const sid = Math.floor(id / 10000).toString();
        return config.currentUser.id === creator;
      }
      if (config.currentUser.role === "Admin") {
        if (id === 0 && elipse) return true;
        if ('objects'.includes(editUrl)) {
          return config.currentUser.conflict.includes(`,${conflict},`);
        } else {
          const sid = Math.floor(id / 10000).toString();
          return config.currentUser.conflict.includes(`,${sid},`);
        }
      }
    }
    return false;
  }

  const colArray = colProps.map((col) => {
    return {
      title: col.title,
      ellipsis: !(editUrl.includes('class') || editUrl.includes('type') || editUrl.includes('mater') || editUrl.includes('lang') || editUrl.includes('parts') || editUrl.includes('tags') || editUrl.includes('users') || editUrl.includes('roles') || editUrl.includes('conflict') || editUrl.includes('tag')) || col.ellipsis,
      dataIndex: col.key,
      sorter: col.sorter,
      key: col.key,
      defaultSortOrder: col.sort,
      width: col.width,
      render: col.render,
      align: col.align
    };
  });

  if (canView) {
    colArray.push({
      title: "R",
      width: "5%",
      key: "restrict",
      align: "center",
      render: (record) => (<div>{record.restrictions && <Restrict />}</div>)
    });
  }

  if (canEdit) {
    colArray.push({
      title: <AddNew />,
      key: "action",
      align: "center",
      render: (record) => (
        <div>
          {canView && <View />}
          {hasAccess(record.id, record.creator, record.conflict, record.participantId) && <Edit id={record.id} name={record.name} />}
        </div>)
    });
  }

  if (editUrl === 'correct') {
    colArray.push({
      width: "5%",
      title: 'C',
      key: "action",
      align: "center",
      render: (record) => <Correct id={record.id} name={record.name} />
    });
  }

  return colArray;
};

export default TableHead;

// () => (<Tooltip title='Click to view'>{col.render}</Tooltip>)