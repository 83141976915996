import React, { useState } from "react";
import { Layout, Tabs, Row, Col } from "antd";
import { Survey, Media, /*OralHistory,*/ Statement } from '../components';
import Stories from 'components/stories/stories';
import Objects from 'components/objects/objects';

const { TabPane } = Tabs;

const TabHead = ({ children }) => {
  return <span style={{ fontSize: '1.5em' }}>{children}</span>;
}

const Related = ({ form, labels }) => {

  const [participantId] = useState(form.getFieldValue('id'));
  //const [hidden] = useState(participantId === '0');

  return (
    <Layout style={{ width:'100%', textAlign: 'left', padding: '24px 0' }}>
      <Row>
        <Col span={23} offset={1}>
          <Tabs type='card' className='tabFrame' >
            <TabPane tab={<TabHead>Stories</TabHead>} key="1" className='tabPane'>
              <Stories participant={participantId} />
            </TabPane>
            <TabPane tab={<TabHead>Objects</TabHead>} key="2" className='tabPane'>
              <Objects participant={participantId} />
            </TabPane>
            <TabPane tab={<TabHead>Initial Survey</TabHead>} key="3" className='tabPane'>
              <Survey participant={participantId} />
            </TabPane>
            <TabPane tab={<TabHead>Media Attachments</TabHead>} key="4">
              <Media participant={participantId} />
            </TabPane>
            {/* <TabPane tab={<TabHead>Oral History</TabHead>} key="5">
              <OralHistory id={participantId} />
            </TabPane> */}
            <TabPane tab={<TabHead>Statement</TabHead>} key="6">
              <Statement statId={participantId} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Layout>
  );
};

export default Related;
