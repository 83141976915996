import React, { useState, useEffect } from "react";
import { Form, Input, Switch, Select, Layout, Row, Col, Radio } from "antd";
import service from "services/apiServices";

const Structural = ({ form, labels }) => {

  const [mater, setMater] = useState([]);

  const getMater = async () => {
    const result = await service.list('master/tags/3');
    if (result) setMater(result);
  }

  useEffect(() => {
    getMater();
  }, []);

  return (
    <Layout style={{ paddingTop: '24px' }}>
      <Form form={form} layout='vertical'>
        <Row>
          <Col span={4} offset={2}>
            <Form.Item style={{ textAlign: 'left' }} label={labels.PART} name="part" valuePropName="checked">
              <Switch checkedChildren="YES" unCheckedChildren="NO" disabled />
            </Form.Item>
            {/* <Form.Item style={{ textAlign: 'left' }} label={labels.PART} name="partt" valuePropName="checked" defaultValue={hasParts}>
              <Switch checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item> */}
          </Col>
          <Col span={4}>
            <Form.Item style={{ textAlign: 'left' }} label={labels.HANDMADE} name="handmade" valuePropName="checked">
              <Switch checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item style={{ textAlign: 'left' }} label={labels.BROKEN} name="broken" valuePropName="checked">
              <Switch checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
          <Col span={4} offset={4}>
            <Form.Item label={labels.QUANTITY} name="quantity" initialValue={0}><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10} offset={2}>
            <Form.Item label={labels.MATERIAL} name='material' initialValue={[]}>
              <Select className="stepFormField" mode="multiple"
                showSearch
                optionFilterProp="children"
                //onSelect={participantSelected}
                filterOption={(input, option) => service.anything(input, option.children)}
                style={{ textAlign: 'left' }}>
                {mater.map(m => (<Select.Option key={m.id} value={m.name}>{m.name}</Select.Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={labels.HANDLING} name="handlingRequirements" initialValue=''><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={2}>
            <Form.Item label="Shape" name="shape" initialValue=''>
              <Radio.Group buttonStyle='solid' style={{ float: 'left' }}>
                <Radio.Button value='unknown'>n/a</Radio.Button>
                <Radio.Button value='2D'>2D</Radio.Button>
                <Radio.Button value='3D'>3D</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={labels.LENGTH} name="length" initialValue=''><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={labels.WIDTH} name="width" initialValue=''><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={labels.HEIGHT} name="height" initialValue=''><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={labels.OTHER} name="other" initialValue=''><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>
      </Form >
    </Layout >
  );
}

export default Structural;
