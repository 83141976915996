import React from 'react'

function Page32() {
  return (
    <>
      <h1 style={{ marginTop: '48px' }}>
        Thank you for putting in your response and adding value<br />to the work of the War Childhood Museum.
      </h1>
      <h1>
        If you have comments or suggestions, please email us<br />with any information you would like to share at &nbsp;
        <a href="mailto:info@warchildhood.org">info@warchildhood.org</a>.
      </h1>
      <h1>
        <small>Your War Childhood Museum Team</small>
      </h1 >
    </>
  )
};

export default Page32;