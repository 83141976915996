import React, { useEffect, useState } from 'react';
import { Layout, Typography, Row, Col, Button, Divider } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { Curtain } from 'common';
import apiServices from 'services/apiServices';
import config from 'config';

const { Title } = Typography;

const Correct = () => {

  const history = useHistory();
  const id = parseInt(useParams().id);
  const [load, setLoad] = useState(false);
  const [item, setItem] = useState({});

  const getObject = async id => {
    setLoad(true);
    const result = await apiServices.read('reports/wrong-objects', id);
    setItem(result);
    setLoad(false);
  }

  useEffect(() => {
    if (id) getObject(id);
    // eslint-disable-next-line
  }, []);

  if (load) return <Curtain />;

  return (
    <Layout className="container">
      <Typography>
        <Title level={2} style={{ marginTop: '24px' }}>Object {item.conflict}.{item.order}</Title>
        <Title level={3}>{item.name}</Title>
      </Typography>
      <hr />
      <Row>
        <Col span={12}>
          {!!item.lightPhoto &&
            <img style={{ border: '1px black solid', margin: '0 auto' }}
              src={`${config.webImagePath}${item.lightPhoto}.jpg`} alt={item.lightPhoto} />}
              <Divider />
              <Button type="primary" onClick={() => history.goBack()}>CLOSE</Button>
<span style={{ marginLeft: '10px' }}></span>
              <Button type="primary" onClick={() => history.goBack()}>REPORT IS CORRECT</Button>
              <span style={{ marginLeft: '10px' }}></span>
              <Button type="primary" onClick={() => history.goBack()}>REMOVE REPORT</Button>
        </Col>
        <Col span={12}>
        <h2>{item.report}</h2>
          <hr />
          <iframe
            title={item.report}
            src={`${config.reportPath}${item.report}#toolbar=0#view=fitH`}
            style={frame}
          />
        </Col>
      </Row>
    </Layout>
  )
}

export default Correct;

const frame = {
  display: 'block',
  height: '100vh',
  width: '100%',
  border: 'none'
}