import React, { useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';

const Error = ({ message, onClose }) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="error">
      {message}
      <CloseOutlined style={{cursor:'pointer', marginTop:'6px'}} onClick={onClose} />
    </div>
  );
}

export default Error;