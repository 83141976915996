import React from 'react';
import { Button, Tooltip } from "antd";
import { InfoOutlined } from "@ant-design/icons";
import services from 'services/apiServices';

const InfoButton = ({ id, onClick }) => (
  <Tooltip title="Story details">
    &nbsp;<Button className='infoButton'
      shape="circle"
      size="small"
      icon={<InfoOutlined />}
      onClick={onClick}
    />
  </Tooltip>
);

const StoryInfo = ({ id, title, show, onClick }) => {
  return (
    <div>
      <b>Story</b>
      {show && id !== 0 && (
        <span>
          <InfoButton id={id} onClick={onClick} />
          <br />
          <span style={{ fontSize: '1.1em' }}>{title}</span>
        </span>
      )}
      {!show && (
      <div style={{ fontSize: '1.1em' }}>
        {!!id ? services.toFormat(id) : <span>missing</span>}
      </div>
      )}
    </div>
  );
}

export default StoryInfo;