import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { Table, Layout, Modal, message } from "antd";

import { isAdmin } from "services/authServices";
import service from "services/apiServices";
import tableHeader from "services/tableService";
import config from "config";
import Translation from './translation';
import ViewTrans from './viewTrans';

const colProps = [
  { key: "language", title: "Language", width: '12%' },
  { key: "status", title: "Status", width: '12%' },
  { key: "title", title: "Title", width: '55%' }
];

const columns = ({ canView, canEdit, onViewClick, onEditClick }) =>
  tableHeader(
    colProps,
    canView,
    canEdit,
    onViewClick,
    onEditClick
  );

const { Content } = Layout;

const Translations = ({ story, langs }) => {

  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visView, setVisView] = useState(false);
  const viewModal = () => setVisView(true);
  const hideView = () => setVisView(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list(`stories/${story}/translations`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [story]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const editTranslation = id => {
    if (id === 0) {
      setItem({ id: 0, language: '', status: 0, title: '', text: '', storyId: story });
    } else {
      const translation = _.find(data, { id: id });
      setItem({
        id: translation.id,
        language: translation.language,
        audio: translation.audio,
        status: translation.status,
        title: translation.title,
        text: translation.text,
        storyId: story
      });
    }
    showModal();
  }

  const viewTranslation = id => {
    const translation = _.find(data, { id: id });
    setItem({
      id: translation.id,
      language: translation.language,
      audio: translation.audio,
      status: translation.status,
      title: translation.title,
      text: translation.text,
      storyId: story
    });
    viewModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={isLoading}
            size="small"
            tableLayount={"fixed"}
            columns={columns({
              canView: true,
              canEdit: isAdmin(),
              onViewClick: (id) => viewTranslation(id),
              onEditClick: (id) => editTranslation(id),
            })}
            dataSource={data}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
      <Modal
        width={'80%'}
        style={{ top: '20px' }}
        open={visible} title={<h2>STORY TRANSLATIONS</h2>}
        footer={[]}
        onCancel={hideModal}
        destroyOnClose={true}>
        <Translation item={item} onOk={refresh} langs={langs} />
      </Modal>
      <Modal width={'60%'}
        style={{ top: '20px' }}
        open={visView} title={<h2>STORY TRANSLATIONS</h2>}
        footer={[]}
        onCancel={hideView}
        destroyOnClose={true}>
        <ViewTrans item={item} onOk={hideView} />
      </Modal>
    </div>
  );
};

export default Translations;
