import React from 'react';
import { Tooltip } from 'antd';
import config from 'config';
import moment from 'moment';

const Info = ({ label, value, link, tooltip }) => {
  if (value === 'false') value = 'NO';
  if (value === 'true') value = 'YES';
  const title = tooltip ? tooltip : "";
  return (
    <div>
      <u>{label}</u>
      <br />
      <Tooltip title={title}>
        <span style={{ fontSize: '1.1em', color: '#369' }}>{value || "-"}</span>
        {link && (
          <span style={{ padding: '4px', marginLeft: '24px', border: '1px solid black' }}><a href={config.reportPath + link} target='_blank' rel="noopener noreferrer">View</a></span>
        )}
      </Tooltip>
    </div>
  );
};

const DateInfo = ({ label, value }) => {
  let date = moment(value).format("DD.MM.YYYY");
  if (date === '01.01.0001') date = '-';
  return (
    <div>
      <u>{label}</u>:&nbsp;<span style={{ fontSize: '1.1em', fontWeight:'600', color: '#369' }}>{date}</span>
    </div>
  );
};

const TextInfo = ({ label, value }) => {
  return (
    <div>
      <u>{label}</u>:&nbsp;<span style={{ fontSize: '1.1em', fontWeight:'600', color: '#369' }}>{value}</span>
    </div>
  );
};

const Flag = ({ label, value, flag, note }) => {
  if (flag) {
    return (
      <div>
        <u>{label}</u>
        <br />
        <span style={{ fontSize: '1.1em', color: '#369' }}>{value || "-"}</span>
      </div>
    );
  } else {
    return (
      <div>
        <u>{label}</u>
        <br />
        <span style={{ color: '#936' }}>{note}</span>
      </div>
    );
  }
};

export { Info, DateInfo, TextInfo, Flag };