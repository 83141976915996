import React, { useEffect } from 'react';
import { useState } from 'react';
import SingleField from './SingleField';
import TextField from './TextField';

const CondField = ({ 
  form, label, question, options, extension, other, 
  trigger, current,
  subLabel, subQuestion 
}) => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(!!current && current.includes(trigger));
    // eslint-disable-next-line
  }, [current, trigger]);

  const checkChoice = e => {
    setVisible(e.includes(trigger));
  }

  return (
    <>
    <SingleField form={form} label={label} question={question} options={options} other={other} extension={extension} check={checkChoice} />
    {visible && <TextField form={form} label={subLabel} question={subQuestion} />}
    </>
  );
}

export default CondField;