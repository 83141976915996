import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { SingleField, CondField, TextField } from "../fields";
import { Curtain } from "common";

const Page7 = () => {

  const page = 7;
  const [loading, setLoading] = useState(false);
  const qn = ["3.25", "3.26", "3.27", "3.28", "3.29", "3.30", "3.31", "3.32", "3.321", 
              "3.33", "3.331", "3.34", "3.35", "3.36", "3.37", "3.38", "3.381", "3.39"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent)
  const [v332, setV332] = useState("No");
  const [v333, setV333] = useState("No");
  const [v338, setV338] = useState("No");

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV332(result.answers[7].choice);
      setV333(result.answers[9].choice);
      setV338(result.answers[15].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      };
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <SingleField form={form} label="25. What type of heating system did you have at home?"
            question="c3.25" other="Other:" extension="e3.25"
            options={["Electric heating", "Gas heating", "Wood stove", "I don't remember"]} />

          <SingleField form={form} label="26. What did you use as burning materials?"
            question="c3.26" other="Other:" extension="e3.26"
            options={["Wood", "Fuel", "Old clothes and shoes", "Toys", "Books", "Garbage"]} />

          <SingleField form={form} label="27. Were you able to cook meals at home?"
            question="c3.27" other="Other:" extension="e3.27"
            options={["Yes", "Not at home, but there was a common stove in the neighborhood", "No", "I don't remember"]} />

          <TextField form={form} label="28. How many meals per day did you have?" question="c3.28" />

          <SingleField form={form} label="29. How did you secure groceries?"
            question="c3.29" other="Other:" extension="e3.29"
            options={[
              "We were buying them at the supermarket/market and so on",
              "We were getting them through humanitarian aid",
              "We had our own garden with fruits and vegetables",
              "I don't remember"
            ]} />

          <TextField form={form} label="30. What kind of groceries were you most likely to buy?" question="c3.30" />

          <SingleField form={form} label="31. What were the prices of groceries?" question="c3.31" other="Other:" extension="e3.31"
            options={[
              "Much higher than the price in the pre-conflict period",
              "Slightly higher than the price in the pre-conflict period",
              "Equal price to the one in the pre-conflict period",
              "Lower price than the price in the pre-conflict period",
              "We would exchange other merchandise for groceries",
              "I don't remember"
            ]} />

          <CondField form={form} label="32. Did you grow fruits and vegetables during the conflict?"
            question="c3.32" trigger="Yes" current={v332}
            options={["Yes", "No", "I don't remember"]} 
            subLabel="32.1 If yes, where?" subQuestion="c3.321" />

          <CondField form={form} label="33. Did you receive humanitarian aid?"
            question="c3.33" trigger="Yes" current={v333}
            options={["Yes", "No"]} 
            subLabel="33.1 If yes, what did you usually receive?" subQuestion="c3.331" />

          <SingleField form={form} label="34. What did you use for cooking your food?" question="c3.34" other="Other:" extension="e3.34"
            options={["Gas stove", "Wood stove", "Electric stove", "I don't remember"]} />

          <TextField form={form} label="35. What did you usually eat during armed conflict?" question="c3.35" />

          <TextField form={form} label="36. What was your favorite meal?" question="c3.36" />

          <TextField form={form} label="37. Which food did you miss the most?" question="c3.37" />

          <CondField form={form} label="38. Were your meals provided for by the communal kitchen?" question="c3.38" 
            options={["Yes", "No"]} trigger="Yes" current={v338}
            subLabel="38.1 If yes, how many meals per day were you provided with?" subQuestion="c3.381" />

          <SingleField form={form} label="39. Did you have to queue for a meal?" question="c3.39" options={["Yes", "No", "I don't remember"]} />

        </Form>
        <Navigator prev={page-1} next={page+1} save={() => form.submit()} />
      </div>
    </>
  );
}

export default Page7;
