import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  CondField,
  CondOptions,
  SingleField,
  TextField
} from "../fields";
import { Curtain } from "common";

const Page21 = () => {
  const page = 21;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["4.68", "4.69", "4.70", "4.701", "4.71", "4.72", "4.721"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v470, setV470] = useState("No");
  const [v472, setV472] = useState("No");

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV470(result.answers[2].choice);
      setV472(result.answers[5].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>
          <TextField
            form={form}
            label="68. How did you spend your free time?"
            question="c4.68"
          />
          <SingleField
            form={form}
            label="69. Were you enrolled in any extracurricular activities? (music, sport, art…etc.)"
            question="c4.69"
            options={["Yes", "No", "I don't remember"]}
          />

          <CondField
            form={form}
            label="70. Did you collect anything?"
            question="c4.70"
            trigger="Yes"
            current={v470}
            options={["Yes", "No"]}
            subQuestion="c4.701"
            subLabel="70.1 If yes, what?"
          />

          <TextField
            form={form}
            label="71. Do you remember any games from that period? If yes, please describe them shortly."
            question="c4.71"
          />

          <CondOptions form={form} label="72. Did you have any toys?"
            question="c4.72" other={false} options={["Yes", "No"]}
            trigger="Yes" current={v472}
            subLabel="72.1 If yes, how did you obtain them?" subQuestion="c4.721" subOther="Other:" subExtension="e4.721"
            subOptions={[
              "From my older brother/sister",
              "From my older cousins or friends from the neighborhood",
              "I made my toys",
              "I bought my toys",
              "I received toys in a package from abroad",
              "I don’t remember"
            ]} />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page21;
