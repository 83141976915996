import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute, AdminRoute } from './common/private';
import { Login, Logout, SetPassword, Reset } from 'auth';
import { User, Users, Profile } from 'components/users';
import { Home, Conflicts, ConflictEdit, Cells, CellEdit } from 'components/conflicts';
import { Participants, ParticipantEdit } from 'components/participants';
import { Stories, StoryEdit } from 'components/stories';
import { Objects, ObjectEdit } from 'components/objects';
import { OralHistory, Oral } from 'components/oralHistory';
import { PartEdit } from 'components/parts';
import Help from 'core/Help';
import { Exhibitions, ExhibitionEdit } from 'components/exhibitions';
import { Tags, Tag, SubTag, Classes, OneClass, Maters, Mater, Langs, Lang, ObjectTypes, ObjectType, LocationCodes } from 'components/tags';
import Colors from 'core/Colors';
import Questionnaire from 'quest/questionnaire';
import Dashboard from 'components/dashboard/Dashboard';
import Correct from 'components/dashboard/Correct';

export default function MainRouter() {
  return (
    <div>
      <Switch>
        <PrivateRoute exact path='/' component={Home} />
        <PrivateRoute exact path='/home' component={Home} />
        <PrivateRoute exact path='/users' component={Users} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/correct/:id' component={Correct} />
        <PrivateRoute exact path='/profile' component={Profile} />
        <PrivateRoute exact path='/help' component={Help} />
        <PrivateRoute exact path='/tags' component={Tags} />
        <PrivateRoute exact path='/tags/:type/:id' component={Tag} />
        <PrivateRoute exact path='/subtag/:parent/:id' component={SubTag} />
        <PrivateRoute exact path='/classes' component={Classes} />
        <PrivateRoute exact path='/classes/:type/:id' component={OneClass} />
        <PrivateRoute exact path='/maters' component={Maters} />
        <PrivateRoute exact path='/maters/:type/:id' component={Mater} />
        <PrivateRoute exact path='/langs' component={Langs} />
        <PrivateRoute exact path='/langs/:type/:id' component={Lang} />
        <PrivateRoute exact path='/objtypes' component={ObjectTypes} />
        <PrivateRoute exact path='/objtypes/:type/:id' component={ObjectType} />
        <PrivateRoute exact path='/conflicts' component={Conflicts} />
        <PrivateRoute exact path='/conflicts/:id' component={ConflictEdit} />
        <PrivateRoute exact path='/cells' component={Cells} />
        <PrivateRoute exact path='/cells/:id' component={CellEdit} />
        <PrivateRoute exact path='/locationcodes' component={LocationCodes} />

        <PrivateRoute exact path='/users/:id' component={User} />
        <AdminRoute exact path='/users/:id/edit' component={User} />
        <PrivateRoute exact path='/oral' component={OralHistory} />
        <PrivateRoute exact path='/oral/:id' component={Oral} />
        <PrivateRoute exact path='/participants' component={Participants} />
        <AdminRoute exact path='/participants/:id' component={ParticipantEdit} />
        <PrivateRoute exact path='/stories' component={Stories} />
        <AdminRoute exact path='/stories/:id' component={StoryEdit} />
        <PrivateRoute exact path='/objects' component={Objects} />
        <AdminRoute exact path='/objects/:id' component={ObjectEdit} />
        <AdminRoute exact path='/parts/:obj/:id' component={PartEdit} />
        {/* <AdminRoute exact path='/photos' component={PhotoTest} /> */}

        <Route path='/login' component={Login} />
        <Route path='/logout' component={Logout} />
        <Route path='/reset' component={Reset} />
        <Route path='/colors' component={Colors} />
        <Route exact path='/qa' component={Questionnaire} />
        <Route exact path='/qa/:code' component={Questionnaire} />
        <Route path='/pass/:t' component={SetPassword} />

        <PrivateRoute exact path='/exhibitions' component={Exhibitions} />
        <PrivateRoute exact path='/exhibitions/:id' component={ExhibitionEdit} />
      </Switch>
    </div>
  );
}
