import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import apiServices from 'services/apiServices';
import config from 'config';
import Spinner from './spinner';

const ShowImage = ({ id, url, type, visible, onCancel }) => {

  const canDown = config.currentUser.name === "Charles Games" || config.currentUser.role !== "intern";

  const [loading, setLoading] = useState(false);
  const title = url; //.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
  const [photo, setPhoto] = useState('');
  const [down, setDown] = useState({ show: false, url: '' });

  const getPhoto = async id => {
    setLoading(true);
    const result = type === 'part' ? await apiServices.read("images/parts", id) : await apiServices.read("images/photos", id);
    if (result) setPhoto(config.srvUrl + result);
    setLoading(false);
  }

  useEffect(() => {
    getPhoto(id);
    // eslint-disable-next-line
  }, [id]);

  const ObjectTitle = () =>
  (
    <>
      <h2>
        <span style={{ margin: '0 24px' }}>Object {title}</span>
      </h2>
      {canDown && <Button
        type='primary'
        style={{ fontSize: '1.1em', height: '36px', border: '1px solid black' }}
        onClick={download}>
        <CloudDownloadOutlined title='download' /> Click here for full HD image
      </Button>}
    </>
  );

  const download = async () => {
    let url = `images/download/${id}`;
    url = type === "part" ? await apiServices.read(url, "part") : await apiServices.read(url, "photo");
    setDown({ show: true, url: config.srvUrl + url });
  }

  return (
    <Modal width='90%'
      aria-hidden
      style={{
        top: 10,
        textAlign: 'center'
      }}
      open={visible}
      title={<ObjectTitle />}
      footer={[]} onCancel={onCancel}>
      <>
        {loading && <Spinner />}
        <img src={photo} alt="" style={{ height: '80vh' }} />
      </>
      <Modal width='90%'
        aria-hidden
        style={{
          top: 10,
          textAlign: 'center'
        }}
        open={down.show}
        title="FULL IMAGE"
        footer={[]}
        onCancel={() => setDown({ show: false, url: '' })}>
        {loading && <Spinner />}
        <img src={down.url} alt="" style={{ height: '80vh' }} />
      </Modal>
    </Modal>);
}

export default ShowImage;