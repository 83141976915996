import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import apiServices from 'services/apiServices';
import config from 'config';
import Spinner from './spinner';

const ViewImage = ({ id, visible, type, onCancel }) => {

  const canDown = config.currentUser.name === "Charles Games" || config.currentUser.role !== "intern";

  const [loading, setLoading] = useState(false);
  const [ph1, setPh1] = useState(null);
  const [ph2, setPh2] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [paths, setPaths] = useState([]);
  const [down, setDown] = useState({ show: false, url: '' });

  const toggle = () => {
    setPhoto(photo === ph1 ? ph2 : ph1);
  }

  const getPhotos = async id => {
    setLoading(true);
    const result = await apiServices.getFile(config.apiUrl + "images/view/" + id + "/" + type);
    if (result) setPhoto(config.srvUrl + result.path1);
    setPaths([result.path1, result.path2]);
    setPh1(config.srvUrl + result.path1);
    setPh2(config.srvUrl + result.path2);
    setLoading(false);
  }

  useEffect(() => {
    getPhotos(id);
    // eslint-disable-next-line
  }, [id]);

  const closeModal = async () => {
    await apiServices.insert("images/photos", { paths: paths });
    onCancel();
  }

  const download = async () => {
    let url = `images/download/${id}`;
    url = await apiServices.read(url, photo.substring(photo.length - 5, photo.length - 4));
    setDown({ show: true, url: config.srvUrl + url });
  }

  const ObjectTitle = () =>
  (
    <>
      <h2>
        <LeftCircleOutlined onClick={toggle} />
        <span style={{ margin: '0 24px' }}>Object {id}</span>
        <RightCircleOutlined onClick={toggle} />
      </h2>
      {canDown && <Button
        type='primary'
        style={{ fontSize: '1.1em', height: '36px', border: '1px solid black' }}
        onClick={download}>
        <CloudDownloadOutlined title='download' /> Click here for full HD image
      </Button>}
    </>
  );

  return (<>
    <Modal width='90%'
      aria-hidden
      style={{
        top: 10,
        textAlign: 'center'
      }}
      open={visible}
      title={<ObjectTitle />}
      footer={[]}
      onCancel={closeModal}>
      <>
        {loading && <Spinner />}
        <img src={photo} alt="" style={{ height: '80vh' }} />
      </>
    </Modal>
    <Modal width='90%'
      aria-hidden
      style={{
        top: 10,
        textAlign: 'center'
      }}
      open={down.show}
      title="FULL IMAGE"
      footer={[]}
      onCancel={() => setDown({ show: false, url: '' })}>
      {loading && <Spinner />}
      <img src={down.url} alt="" style={{ height: '80vh' }} />
    </Modal>
  </>);
}

export default ViewImage;