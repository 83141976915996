import React, { useEffect } from 'react';
import { Form, Input, Layout, Button, Row, Col, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import service from 'services/apiServices';
import config from 'config';
import { Buttons, Delete } from 'common';

const { Content } = Layout;
const { Dragger } = Upload;

const styles = {
  userField: {
    borderRadius: '4px',
    border: '1px solid #211E1A',
    backgroundColor: '#faffff',
    color: 'black'
  }
}

const Attachment = ({ item, onOk }) => {

  const [form] = Form.useForm();

  useEffect(() => { form.setFieldsValue({ ...item }) }, [form, item]);

  const options = {
    name: 'file',
    multiple: true,
    action: config.uploadPath + 'document',
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        const infoFile = config.documentPath + info.file.name;
        form.setFieldsValue({ url: infoFile });
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
      }
    }
  }

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    if (item.id === 0) {
      await service.insert('storyAttachments', { ...values, story: { id: service.toNumber(item.storyId) } });
    } else {
      await service.update('storyAttachments', { ...values, story: { id: service.toNumber(item.storyId) } }, item.id);
    }
    onOk();
  };

  return (
    <Layout>
      <Content align="center">
        <Form layout='vertical' form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Row>
            <Col span={18}>
              <Form.Item label='Id' name='id' hidden><Input style={styles.userField} /></Form.Item>
              <Form.Item label='Url' name='url'><Input style={styles.userField} /></Form.Item>
              <Form.Item label='Comment' name='comment'>
                <Input.TextArea style={styles.userField} rows={6} />
              </Form.Item>
              <Form.Item label='Story' name='storyId' hidden><Input style={styles.userField} /></Form.Item>
            </Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              <Dragger {...options} className='dragger' style={{ top: '30px' }}>
                <p className='ant-upload-drag-icon'><InboxOutlined /><br />drag here</p>
              </Dragger>
              <Button style={{ ...Buttons.button, marginTop: '42px' }} htmlType='submit'>SAVE</Button>
              <Delete dataSet='storyAttachments' id={item.id} onFinish={onOk} />
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default Attachment;
