import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { SingleField, CondField } from "../fields";
import { Curtain } from "common";

const Page5 = () => {

  const page = 5;
  const [loading, setLoading] = useState(false);
  const qn = ["3.10", "3.11", "3.12", "3.13", "3.14", "3.15", "3.151"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent);
  const [v315, setV315] = useState("No");

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV315(result.answers[5].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      };
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }

  return (
    <>
      <div className="quest-card">
        {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <SingleField form={form} label="10. Where did you hide during shelling/bombing?"
            question="c3.10" extension="e3.10" other="Other:"
            options={[
              "Bomb shelter",
              "Basement in a house or building",
              "Basement in the neighbourhood",
              "The safest room in the house (bathroom, hallway…)",
              "We were not hiding"
            ]} />

          <SingleField form={form} label="11. Did you have electricity?" question="c3.11" options={["Yes", "No"]} />

          <SingleField form={form} label="12.	How often did the power outages occur?" question="c3.12"
            options={[
              "Frequently (multiple times a day)",
              "Often (Multiple times a week)",
              "Occasionally",
              "Rarely",
              "Never",
              "I don't remember"
            ]} />

          <SingleField form={form} label="13. Did you have a source of light?" question="c3.13" options={["Yes", "No"]} />

          <SingleField form={form} label="14. What did you usually use as a source of light?"
            question="c3.14" extension="e3.14" other="Other (specify):"
            options={[
              "Candle",
              "Light bulb connected to a car battery",
              "Improvised oil/fat lamp",
              "Electric lightning",
              "Open fire",
              "Torch"
            ]} />

          <CondField form={form} label="15. Did you get electricity from a source that did not belong to the infrastructure of your house/apartment/accommodation?"
            question="c3.15" options={["Yes", "No", "I don't remember"]} trigger="Yes" current={v315}
            subLabel="15.1. If yes, in what way?" subQuestion="c3.151"
          />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
}

export default Page5;
