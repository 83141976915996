const baseUrl = process.env.REACT_APP_BACKEND || 'https://localhost:44300/';
const frontUrl = process.env.REACT_APP_FRONTEND || 'http://localhost:3000/';
const subtitle = process.env.REACT_APP_SUBTITLE || 'missing environment';
const copyright = process.env.REACT_APP_COPYRIGHT || 'missing copyright';

export default {
  subtitle: subtitle,
  copyright: copyright,
  baseUrl: frontUrl,
  srvUrl: baseUrl,
  apiUrl: baseUrl + 'api/',
  uploadPath: baseUrl + 'api/upload/',
  tempoPath: baseUrl + 'tempora/',
  imagePath: baseUrl + 'thumbs/',
  fullPath: baseUrl + 'full/',
  webImagePath: baseUrl + 'static/images/',
  mediaPath: baseUrl + 'media/',
  documentPath: baseUrl + 'documents/',
  surveyPath: baseUrl + 'surveys/',
  reportPath: baseUrl + 'static/reports/',
  token: '',
  currentUser: {
    id: 0,
    name: '',
    role: '',
    landing: '',
    conflict: 0,
    canDelete: false,
    delParticipants: false,
    delStories: false,
    delObjects: false,
    delParts: false,
    delHistories: false
  },
  headers: {
    page: 1,
    totalPages: 0,
    totalItems: 0,
    pageSize: 100,
    sortBy: 'id',
    sortDir: 'asc',
    filter: ''
  },
  page: 1,
  ohPage: 1,
  filter: '',
  prefix: '',
  attemptRoute: '',
  gender: ['Null', 'Female', 'Male', 'Other'],
  participants: [],
  stories: [],
  objects: [],
  orals: [],
  tagType: 0,
  tagDesc: '',
  errorMessage: 'We have problems communicating with database. Please try again in 2 minutes.',
  resp: {
    id: 0,
    name: '',
    code: ''
  }
}

// const baseUrl = 'https://api.teatar.org/';
// const baseUrl = 'https://api.warchildhood.com/';
// const baseUrl = 'https://localhost:44300/';