import React, { useState, useEffect, useCallback } from 'react';
import { Form, Layout, Steps, message } from 'antd';
import { useParams } from "react-router-dom";
import _ from 'lodash';
import moment from 'moment';

import config from 'config';
import service from 'services/apiServices';
import { EditHistory, FormNavigation, Title, Spinner } from 'common';
import { Personal, Institution, Related, Residence, Confirmation } from './steps';
import labels from "assets/labels/participant.json";
import { useHistory } from 'react-router-dom';

const { Step } = Steps;

const ParticipantEdit = () => {

  const history = useHistory();
  if (config.currentUser.role === "Intern") history.push("/home");

  const [id] = useState(useParams().id);
  const isNew = id === '0';
  const [pid, setPid] = useState('');
  const [fullName, setFullName] = useState('');
  const [lastEdit, setLastEdit] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [steps] = useState([
    { title: "Personal information", content: () => (<Personal form={form} labels={labels} />) },
    { title: "Institutional information", content: () => (<Institution form={form} labels={labels} getNext={getNext} />) },
    { title: "Residential information", content: () => (<Residence form={form} labels={labels} />) },
    { title: "Related Items", content: () => (<Related form={form} labels={labels} />) },
    { title: "Confirm & Save", content: () => (<Confirmation form={form} labels={labels} finalSave={finalSave} />) }
  ]);

  const getParticipant = useCallback(async (participantId) => {
    try {
      const result = await service.read("participants", participantId + "/edit");
      if (result) {
        setPid(result.id);
        setFullName(result.fullName);
        setLastEdit({lastUpdate:result.lastUpdate, editHistory:result.editHistory});
        form.setFieldsValue({
          ...result,
          participationDate: moment(result.participationDate),
          pid: result.id
        });
        setLoading(false);
      }
    } catch (error) {
      message.error("We are experiencing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
  }, [form]);

  useEffect(() => {
    if (id === '0') {
      form.setFieldsValue({ id: '0' })
    } else {
      getParticipant(id);
    }
    setLoading(false);
  }, [form, id, getParticipant]);

  const getNext = next => {
    setPid(service.toFormat(next));
    form.setFieldValue('pid', service.toFormat(next));
    setFullName(form.getFieldValue('firstName') + ' ' + form.getFieldValue('lastName'));
  }

  const onDelete = () => {
    const z = parseInt(id);
    _.remove(config.participants, item => item.id === z);
    history.push("/participants");
  }

  const onChange = async step => {
    if (step === 2 && isNew) {
      const values = form.getFieldsValue();
      const result = await service.insert("participants", values);
      if (result) {
        form.setFieldsValue({ id: result.id });
      }
    }
    setStep(step);
  }
  const onCancel = async () => {
    // if(isNew) await service.remove("participants", service.toNumber(pid));
    history.push("/participants");
  }

  const Single = () => {
    return (
      <div>
        <Steps type='navigation' current={step} onChange={onChange}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} style={{ width: '20%' }} />
          ))}
        </Steps>
        <div className="steps-content">
          {steps[step].content()}
        </div>
      </div>
    );
  }

  const finalSave = () => {
    history.push('/participants');
  }

  if (loading) return <Spinner />;
  return (
    <Layout className='container'>
      {/* <Prompt when={formDirty} message={'Are you sure you wish to leave this page\r\nwithout saving the changes?'} /> */}
      <Title align="center">Contributor Info {!!pid && <span>[{pid}: {fullName}]</span>}</Title>
      {!!lastEdit.editHistory && <EditHistory lastUpdate={lastEdit.lastUpdate} editHistory={lastEdit.editHistory} />}
      <div className="divider" />
      <Form>
        <FormNavigation
          form={form}
          step={step}
          canDelete={config.currentUser.delParticipants}
          limit={steps.length - 1}
          onCancel={onCancel}
          onChange={onChange}
          dataSet='participants'
          id={id}
          onFinish={onDelete} />
      </Form>
      <Single />
      <hr style={{ marginBottom: '24px' }} />
    </Layout>
  );
}

export default ParticipantEdit;
