import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Delete, Buttons, Spinner } from 'common';
import apiServices from "services/apiServices";
import P2S from "components/objects/P2S";
import S2P from "components/objects/S2P";
import config from "config";

const FormNavigation = ({ form, canDelete, onCancel, onChange, limit, step, dataSet, id, study, redirect, onFinish, transfer }) => {

  const [visTran, setVisTran] = useState(false);
  const [loading, setLoading] = useState(false);

  const isSuper = !!transfer && config.currentUser.role === 'SuperAdmin';

  const prev = () => {
    if (step > 0) {
      step--;
      onChange(step);
    }
  };

  const next = () => {
    if (step < limit) {
      step++;
      onChange(step);
    }
  };

  const save = () => {
    step = limit;
    onChange(step);
  }

  const title = study ? "Transfer to PERMANENT collection" : "Transfer to STUDY collection";

  const doTransfer = async object => {
    setLoading(true);
    const url = study ? 'transfer/s2p' : 'transfer/p2s';
    await apiServices.insert(url, object);
    setLoading(false);
    setVisTran(false);
    transfer();
  }

  if(loading) return <Spinner />
  return (
    <div style={{ textAlign: 'center', marginBottom: '24px' }}>
      {canDelete && (<Delete dataSet={dataSet} id={id} redirect={redirect} onFinish={onFinish} />)}
      <Button style={{ ...Buttons.button, ...Buttons.close }} onClick={onCancel}>Close</Button>
      {!!isSuper && <Button style={{ ...Buttons.button, ...Buttons.button }} onClick={() => setVisTran(true)} title={title}>Transfer</Button>}
      {step > 0 && <Button style={Buttons.button} onClick={prev}>Previous</Button>}
      {step < limit && (<span>
        <Button style={Buttons.button} onClick={next}>Next</Button>
        <Button style={{ ...Buttons.button, ...Buttons.confirm, ...Buttons.save }} onClick={save}>Review and Save</Button>
      </span>)}
      {step === limit && <Button style={{ ...Buttons.button, ...Buttons.confirm, ...Buttons.save }} onClick={() => form.submit()}>Confirm and Save</Button>}

      <Modal open={visTran}
        style={{ padding: '12px' }}
        width={'80%'} 
        title={<h2>{title}</h2>}
        destroyOnClose={true}
        elevation={20}
        footer={[]}
        onCancel={() => setVisTran(false)}>
        {study ? <S2P id={id} onTrans={doTransfer} /> : <P2S id={id} onTrans={doTransfer} />}
      </Modal>
    </div>
  );
};

export default FormNavigation;
