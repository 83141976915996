import React, { useState } from 'react';
import { Form, Layout, Input, Button } from 'antd';

import service from 'services/apiServices';
import logobw from 'assets/images/logobw@3x.png';
import { Curtain } from 'common';

const Reset = () => {

  const [form] = Form.useForm();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onFinish = async function (values) {
    setLoading(true);
    let result = await service.reset(values.address);
    setLoading(false);
    setMessage(result);
  }

  const onFailed = error => {
    console.error('Failed:', error);
  };

  return (
    <>
      {loading && <Curtain />}
      <Layout className="bgReset">
        <div className="rst-outer">
          <div className="inner">
            <img className="reset-logo" src={logobw} alt="logo" />
            <div className="reset-form">
              {!message &&
                <div className="resetText">
                  <h2 style={styles.headings}>Reset Your Password</h2>
                  <p style={styles.reset}>
                    If you forgot your password, we can send you a link<br />to reset password and get back into your account!
                  </p>
                </div>
              }

              {message &&
                <div className="resetText">
                  <h2 style={styles.headings}>Thank you!</h2>
                  <p style={styles.reset}>
                    If your email address is in our database,<br />
                    we'll send you a message for password reset.<br />
                    You can change your password within next 30 minutes.
                  </p>
                </div>
              }

              <Form form={form} onFinish={onFinish} onFinishFailed={onFailed}>
                <Form.Item name="address" label="">
                  <Input type="email" placeholder='Enter your email address please' style={{ width: '58%' }} />
                </Form.Item>
                {!message && <Button type="primary" htmlType="submit">Send me email link to reset Password!</Button>}
              </Form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Reset;

const styles = {
  subtitle: {
    display: 'table',
    fontSize: '2.5em',
    color: '#369',
    margin: '24px auto'
  },

  right: {
    textAlign: 'right',
    verticalAlign: 'top',
    paddingTop: '12px'
  },

  text: {
    textAlign: 'center',
    marginTop: '24px',
    whiteSpace: 'pre-wrap'
  },

  headings: {
    color: 'white'
  },

  reset: {
    color: 'white',
    fontWeight: '500',
    fontSize: '16px'
  }
};