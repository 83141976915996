import React from "react";
import tableHeader from "services/tableService";
import { CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from "@ant-design/icons";
import superAdmin from "assets/images/super_f.svg";
import admin from "assets/images/admin_m.svg";
import user from "assets/images/user_f.svg";
import intern from "assets/images/intern_m.svg";

const iconStatus = x => {
  if (x === "Active") return (<><CheckCircleOutlined style={{ color: '#52c41a', fontSize: '1.2rem' }} /> Active</>);
  if (x === "Locked") return (<><CloseCircleOutlined style={{ color: '#f5222d', fontSize: '1.2rem' }} /> Locked</>);
  if (x === "Inactive") return (<><WarningOutlined style={{ color: '#faad14', fontSize: '1.2rem' }} /> Inactive</>);
}

const iconRole = (x, y) => {
  if (x === "SuperAdmin") return (<><img src={superAdmin} alt='SuperAdmin' style={{ width: '27px' }} /> Super Admin</>);
  if (x === "Admin") return (<><img src={admin} alt='Admin' style={{ width: '27px' }} /> Admin</>);
  if (x === "User") return (<><img src={user} alt='User' style={{ width: '27px' }} /> User</>);
  if (x === "Intern") return (<><img src={intern} alt='Intern' style={{ width: '27px' }} /> Intern</>);
}

const colProps = [
  { key: "username", title: "Username", width: '15%' },
  { key: "fullName", title: "Full Name", width: '15%' },
  { key: "status", title: "Status", width: '15%', render: x => iconStatus(x) },
  { key: "institution", title: "Institution", width: '25%' },
  { key: "role", title: "Role", width: '15%', render: (x, y) => iconRole(x, y) },
];

const columns = ({ canEdit, onEditClick }) =>
  tableHeader(
    colProps,
    false,
    canEdit,
    false,
    onEditClick
  );

const statusOptions = [
  {
    label: <span className="side-label">Active</span>,
    value: "Active"
  },
  {
    label: <span className="side-label">Inactive</span>,
    value: "Inactive"
  },
  {
    label: <span className="side-label">Locked</span>,
    value: "Locked"
  },
];

const roleOptions = [
  {
    label: <span className="side-label">SuperAdmin</span>,
    value: "1"
  },
  {
    label: <span className="side-label">Admin</span>,
    value: "2"
  }, {
    label: <span className="side-label">User</span>,
    value: "3"
  },
  {
    label: <span className="side-label">Intern</span>,
    value: "4"
  }
];

export { columns, statusOptions, roleOptions };
