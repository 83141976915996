import React from 'react';
import { Form, Input, Space, Checkbox } from 'antd';

const MultiField = ({ form, label, question, extension, options, other }) => {

  return (
    <>
      <div className='quest-label'>{label}</div>
      <Form.Item label="" name={question} initialValue="">
        <Checkbox.Group size="small">
          <Space direction="vertical">
            {options.map(o =>
              <Checkbox key={o} value={o}>
                <span style={{ fontSize: '12px', color: 'black' }}>{o}</span>
              </Checkbox>
            )}
          </Space>
        </Checkbox.Group>
      </Form.Item>

      {!!other && <>
        <div className="quest-label" style={{ float: 'left', fontSize: '12px', backgroundColor: 'white', marginTop: '-16px' }}>{other}</div>
        <Form.Item label="" name={extension} style={{ marginTop: '-24px' }} initialValue="">
          <Input className="quest-input" />
        </Form.Item>
      </>}
    </>
  );
}

export default MultiField;
