import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Layout, Button, Row, Col, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import service from "services/apiServices";
import config from 'config';
import { Buttons, Spinner } from 'common';

const { Content } = Layout;
const { Dragger } = Upload;

const styles = {
  back: {
    backgroundColor: '#fefefe'
  },
  text: {
    marginTop: '24px',
    textAlign: 'center',
    color: '#dddddd',
    fontSize: 40
  },
  userField: {
    borderRadius: '4px',
    border: '1px solid #211E1A',
    backgroundColor: '#faffff',
    color: 'black'
  },
  iframe: {
    width: '100%',
    height: '100vh',
    marginTop: '12px'
  }
};

const Statement = ({ statId }) => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const fetch = useCallback(async () => {
    try {
      const result = await service.list(`participants/${statId}/statement`);
      if (result) {
        setData(result);
        form.setFieldsValue({ ...result, participantId: statId });
      }
      setLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setLoading(false);
    }
  }, [form, statId]);

  useEffect(() => { fetch(); }, [fetch]);

  const options = {
    name: 'file',
    multiple: true,
    action: config.uploadPath + 'statement?id=' + statId,
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        const infoFile = config.documentPath + 'statement' + statId + '.pdf';
        form.setFieldsValue({url:infoFile});
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
      }
    }
  }

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    values.participantId = service.toNumber(values.participantId);
    if (values.id === 0) {
      await service.insert('statements', { ...values, participant: { id: values.participantId } });
    } else {
      await service.update('statements', { ...values, participant: { id: values.participantId } }, values.id);
    }
    fetch();
  };

  if(loading) return <Spinner />

  return (
    <Layout className="container" style={styles.back}>
      <Content align="center">
        <div style={styles.text}>STATEMENT {statId}</div>
        <Form layout='vertical' form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Row>
            <Col span={18}>
              <Form.Item label='Id' name='id' hidden><Input style={styles.userField} /></Form.Item>
              <Form.Item label='Url' name='url'><Input style={styles.userField} /></Form.Item>
              <Form.Item label='Comment' name='comment'>
                <Input.TextArea style={styles.userField} rows={6} />
              </Form.Item>
              <Form.Item label='Participant' name='participantId' hidden><Input style={styles.userField} /></Form.Item>
            </Col>
            <Col span={6} style={{textAlign:'center'}}>
              <Dragger {...options} className='dragger'>
                <p className='ant-upload-drag-icon'><InboxOutlined /><br />drag here</p>
              </Dragger>
              <Button style={Buttons.button} htmlType='submit'>SAVE</Button>
            </Col>
          </Row>
        </Form>
        {data.url && (<iframe title='Statement' style={styles.iframe} src={data.url} />)}
      </Content>
    </Layout >
  );
}

export default Statement;
