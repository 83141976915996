import React from 'react';
import { Form, InputNumber } from 'antd';

const NumberField = ({ form, label, question }) => {

  return (
    <>
      <div className="quest-label">{label}</div>
      <Form.Item label="" name={question} initialValue={0}>
        <InputNumber type="number" className="quest-input" />
      </Form.Item>
    </>
  );
}

export default NumberField;
