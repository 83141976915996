import React from 'react';
import { withRouter } from 'react-router';
import { Layout } from 'antd';
import NavigationMenu from './navigationMenu';
import wcm2 from 'assets/images/inverse.png';
import config from 'config';
// import Timer from './Timer';

const { Header } = Layout;

const styles = {
  logo2: {
    float: 'left',
    margin: '16px 0',
    height: '27px'
  },
  header: {
    color: '#f0f2f5',
    backgroundColor: '#1d1e1f',
    margin: '0 auto',
    height: '66px',
    borderBottom: '1px solid #707070'
  }
}

const AppHeader = () => {

  const subtitle = config.subtitle;

  return (
    <Header style={styles.header}>
      <div style={{ width: '95%', margin: '0 auto' }}>
        <div className='left'>
          <img src={wcm2} style={styles.logo2} alt='logo' />
          {subtitle !== 'none' && <span className='subtitle'>{subtitle}</span>}
        </div>
        <div style={{ float: 'right' }}><NavigationMenu /></div>
      </div>
    </Header>
  );
}

export default withRouter(AppHeader);
