import React, { useEffect } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, Layout, Radio, Button, Col, Row } from 'antd';
import { Buttons, Delete } from 'common';
import service from 'services/apiServices';
import config from 'config';

const { Content } = Layout;

const styles = {
  userField: {
    borderRadius: '4px',
    border: '1px solid #211E1A',
    backgroundColor: '#fffafa',
    color: 'black'
  }
}

const Note = ({ item, onOk }) => {

  const [form] = Form.useForm();

  useEffect(() => { form.setFieldsValue({ ...item, time: moment(item.time) }) }, [form, item]);

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    let result = false;
    if (item.id === 0) {
      values = { ...values, piece: { id: item.objectId }, user: config.currentUser.name };
      result = await service.insert('piecenotes', values);
    } else {
      result = await service.update('piecenotes', { ...values, piece: { id: item.objectId }, user: config.currentUser.name }, item.id);
    }
    if (result) {
      onOk();
    }
  };

  return (
    <Layout className="container">
      <Content align="center">
        <Form layout='vertical' form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Form.Item label='Id' name='id' hidden><Input style={styles.userField} /></Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item label='Type' name='type' style={{ textAlign: 'left' }}>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value='General'>General</Radio.Button>
                  <Radio.Button value='Shipping'>Shipping</Radio.Button>
                  <Radio.Button value='ReturnShipping'>Return Shipping</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Date' name='time'><DatePicker className="stepFormField" /></Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label='Comment' name='comment'><Input.TextArea rows={4} style={styles.userField} /></Form.Item>
            </Col>
          </Row>
          <Form.Item label='Object' name='objectId' hidden><Input style={styles.userField} /></Form.Item>
          <Form.Item label='User' name='userId' hidden><Input style={styles.userField} /></Form.Item>
          <hr />
          <Row>
            <Col span={12}><Delete dataSet='piecenotes' id={item.id} onFinish={onOk} /></Col>
            <Col span={12}><Button style={Buttons.button} htmlType='submit'>SAVE</Button></Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default Note;
