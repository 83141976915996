import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import Color from './Color';
import colors from './colorList';

const styles = {
  form: {
    backgroundColor: 'grey',
    padding: '12px',
    maringTop: '60%',
    marginLeft: '6%'
  }
}

const Colors = () => {

  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  let key = 0;

  const selectColor = color => {
    form.setFieldsValue({ color: color });
    setShow(true);
  }

  return (
    <div styles={{marginTop:'64px'}}>
      <div style={styles.form}>
        <Form form={form} layout='inline' style={styles.label}>
          <Form.Item label='' name='color'><Input placeholder='Color' /></Form.Item>
          <Button type='primary' onClick={() => setShow(true)}>Get Pallete</Button>
        </Form>
      </div>
      <div className='card-container'>
          {colors.map(c =>
            <div className='card-item' key={key++}
              style={{ color: `${c.text}`, backgroundColor: `${c.color}` }}
              onClick={() => selectColor(c.color)}>
              {c.title}<br />{c.color}
            </div>
          )}
        </div>
      <Modal width={'90%'} open={show} footer={[]} onCancel={() => setShow(false)} style={{ top: 20 }}
        destroyOnClose={true}><Color color={form.getFieldValue('color')} /></Modal>
    </div>
  )
}

export default Colors;