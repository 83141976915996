import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Input, Layout, Row, Col } from 'antd';
import { isAdmin } from 'services/authServices';
import service from 'services/apiServices';
import { Curtain, Delete, Title, Buttons } from "common";

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

export default function ({ history }) {

  if (!isAdmin()) history.goBack();

  const [id] = useState(parseInt(useParams().id));
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetch = async (id) => {
      if (id === 0) {
        form.setFieldsValue({ id: 0 });
      } else {
        const result = await service.read('conflicts', id);
        form.setFieldsValue(result);
      }
      setLoading(false);
    };
    if (id !== '0') fetch(id);
    setLoading(false);
  }, [id, form]);

  const onFailed = () => history.goBack();

  const onFinish = async (values) => {
    setLoading(true);
    values.country = values.name;
    if (values.id === 0)
      await service.insert('conflicts', values);
    else
      await service.update('conflicts', values, values.id);
    setLoading(false);
    history.push('/conflicts');
  }

  const onDelete = () => {
    history.push('/conflicts');
  }

  if (loading) return <Curtain />;
  return (
    <Layout className='container'>
      <Title align="center">Armed Conflict</Title>
      <hr style={{ margin: '24px' }} />
      <Form form={form} {...layout} onFinish={onFinish} onFinishFailed={onFailed}>
        <Form.Item name='id' hidden><Input /></Form.Item>
        <Row>
          <Col span={8} offset={8}>
            <Form.Item label='Country' name='name'><Input /></Form.Item>
            <Form.Item label='Name' name='country' hidden><Input /></Form.Item>
            <Form.Item label='Start Year' name='beginYear'><Input /></Form.Item>
            <Form.Item label='End Year' name='endYear'><Input /></Form.Item>
            <Form.Item label='Latitude' name='x'><Input /></Form.Item>
            <Form.Item label='Longitude' name='y'><Input /></Form.Item>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={24}>
            <Delete dataSet='conflicts' id={id} onFinish={onDelete} />
            <Button style={Buttons.close} onClick={() => history.goBack()}>Close</Button>
            <Button style={Buttons.confirm} htmlType='submit'>SAVE</Button>
          </Col>
        </Row>
      </Form>
    </Layout>);
};