import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  SingleField,
  TextField
} from "../fields";
import { Curtain } from "common";

const Page17 = () => {
  const page = 17;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["4.34", "4.35", "4.36", "4.37", "4.38", "4.39"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <SingleField
            form={form}
            label="34.	What type of heating system did you have at your temporary accommodation? "
            question="c4.34"
            extension="e4.34"
            options={[
              "Electric heating",
              "Gas heating",
              "Wood stove",
              "I don’t remember",
            ]}
            other="Other:"
          />
          <SingleField
            form={form}
            label="35. What did you use as burning materials?"
            question="c4.35"
            extension="e4.35"
            options={[
              "Wood",
              "Fuel",
              "Old clothes and shoes",
              "Toys",
              "Books",
              "Garbage",
            ]}
            other="Other:"
          />
          <SingleField
            form={form}
            label="36.	Were you able to cook meals at home?"
            question="c4.36"
            extension="e4.36"
            options={[
              "Yes",
              "Not at home, but there was a common stove in the neighbourhood",
              "No",
              "I don’t remember",
            ]}
            other="Other:"
          />
          <TextField
            form={form}
            label="37. How many meals per day did you have?"
            question="c4.37"
          />
          <SingleField
            form={form}
            label="38. How did you secure groceries?"
            question="c4.38"
            extension="e4.38"
            options={[
              "We were buying them at the supermarket/market and so on",
              "We were getting them through humanitarian aid",
              "We had our own garden with fruits and vegetables",
              "I don’t remember",
            ]}
            other="Other:"
          />
          <TextField
            form={form}
            label="39. What kind of groceries were you most likely to buy?"
            question="c4.39"
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page17;
