import React, { useState, useEffect, useCallback } from "react";
import { Table, Layout, Drawer, Modal, message } from "antd";

import service from "services/apiServices";
import { Title } from "common";
import { isAdmin } from "services/authServices";
import config from "config";
import columns from "./columns";
import PartView from "./partView";
import PartEdit from "./partEdit";

const { Content } = Layout;

const Parts = ({ object }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState({ visible: false, part: 0 });
  const [modal, setModal] = useState({ visible: false, piece: 0, part: 0 });

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      config.filter = '';
      const result = await service.list(`parts?piece=${object}`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [object]);

  // const onFocus = () => {
  //   fetch();
  // }

  useEffect(() => {
    // window.addEventListener('focus', onFocus);
    fetch();
    // eslint-disable-next-line
  }, []);

  const editModal = id => {
    setView({visible: false});
    setModal({ visible: true, piece: object, part: id });
  }

  const closeModal = () => {
    setView({visible: false});
    setModal({ visible: false, piece: 0, part: 0 });
  }

  return (
    <Layout>
      <Content>
        <Title>Parts / {data.length}</Title>
        <Table
          loading={isLoading}
          size="small"
          tableLayout={"fixed"}
          columns={columns({
            canEdit: isAdmin(),
            editUrl: `parts/${object}`,
            editModal: editModal
          })}
          dataSource={data}
          onRow={record => ({ onClick: () => { setView({ visible: true, part: record.id }) } })}
          pagination={{
            size: 'small',
            hideOnSinglePage: true, defaultPageSize: 20, showSizeChanger: false
          }}
          rowKey={(row) => row.id} />
      </Content>

      <Drawer title={<h2>Part Detail View</h2>}
        width={'60%'}
        placement="right"
        onClose={() => setView({ visible: false, part: 0 })}
        destroyOnClose={true}
        open={view.visible}>
        <PartView id={view.part} />
      </Drawer>

      <Modal title={<h2>Part Info </h2>} visible={modal.visible} onCancel={closeModal} footer={[]} width="98%" style={{ top: 20 }} destroyOnClose={true}>
        <PartEdit object={modal.piece} part={modal.part} onClose={closeModal} />
      </Modal>

    </Layout>
  );
};

export default Parts;


// onViewClick: (id) => {
//   setSelectedObjectId(id);
//   setShowDetailView(true);
// },
// onEditClick: (id) => history.push(`objects/${id}/edit`),
