import React, { useState } from 'react';
import { Button, Row, Col, Modal } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setLocation, setPlace } from 'store/pageSlice';
import config from 'config';
import { useHistory } from 'react-router-dom';
import apiServices from 'services/apiServices';

const Navigator = ({ prev, next, save }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { page, location } = useSelector(store => store.page);
  const { code } = useSelector(store => store.respondent);
  const [open, setOpen] = useState(false);

  const goPrev = async () => {
    await save();
    if (!!code) {
      const result = await apiServices.list(`answers/prev/${code}/${page}/${location}`);
      dispatch(setPage(result.page));
      dispatch(setLocation(result.location));
      dispatch(setPlace(result.place));
    } else {
      dispatch(setPage(3));
    }
  }

  const goNext = async () => {
    await save();
    if (!!code) {
      const result = await apiServices.list(`answers/next/${code}/${page}/${location}`);
      dispatch(setPage(result.page));
      dispatch(setLocation(result.location));
      dispatch(setPlace(result.place));
    } else {
      dispatch(setPage(3));
    }
  };

  const continueLater = async () => {
    await save();
    await apiServices.list(`answers/mail/${code}`);
    setOpen(true);
  }

  const handleOk = async () => {
    setOpen(false);
    history.push('/qa');
  };

  const handleCancel = async () => {
    setOpen(false);
    history.push('/qa');
  };

  return (
    <div className="quest-nav">
      <Row>
        <Col xs={24} md={8} align='center'>
          <Button type="primary" className="quest-button" onClick={goPrev}>
            Previous page
          </Button>
        </Col>
        <Col xs={24} md={8} align='center'>
          {code &&
            <Button type="primary" className="quest-button" onClick={continueLater}>
              Save and continue later
            </Button>
          }
        </Col>
        <Col xs={24} md={8} align='center'>
          <Button type="primary" className="quest-button" onClick={goNext}>
            Next page
          </Button>
        </Col>
      </Row>


      <Modal open={open} title="Title" onOk={handleOk} onCancel={handleCancel} footer={[]}>
        <h2>Your work is saved!</h2>
        <h3>You can continue answering the form later by visiting this link: {config.baseUrl}qa/{code}</h3>
        <h3>Thank you.</h3>
      </Modal>
    </div>
  )
}

export default Navigator;