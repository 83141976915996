import React, { useState, useEffect } from "react";
import { Table, Layout, Button, message } from "antd";
import service from "services/apiServices";
import config from "config";

const FilterList = ({ selectFilter, filterType, preview, takeSnap, viewSnap }) => {

  const columns = [
    {
      key: "sqlName",
      dataIndex: "sqlName",
      title: 'Name',
      width: '40%'
    },
    {
      key: "action",
      title: 'View snapshot',
      width: '15%',
      align: 'center',
      render: record => (<div>{record.snapshot && <ViewSnap data={record} />}</div>)
    },
    {
      key: "action",
      title: 'Take Snapshot',
      width: '15%',
      align: 'center',
      render: record => <Snapshot data={record} />
    },
    {
      key: "action",
      title: 'Edit Filter',
      width: '15%', align: 'center',
      render: record => <Edit data={record} />
    },
    {
      key: "action",
      title: 'View Result',
      width: '15%',
      align: 'center',
      render: record => <View data={record} />
    }
  ];

  const [filterList, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const Snapshot = ({ data }) => (<Button className='tableButton' onClick={() => takeSnap(data)}>take</Button>);

  const Edit = ({ data }) => (<Button className='tableButton' onClick={() => { selectFilter(data) }}>edit</Button>);

  const View = ({ data }) => (<Button className='tableButton' onClick={() => preview(data)}>view</Button>);

  const ViewSnap = ({ data }) => (<div onClick={() => viewSnap(data.id)}>{data.snapshot.substring(data.snapshot.length - 8)}</div>);

  const fetch = async () => {
    try {
      setIsLoading(true);
      let result;
      if (filterType === "H") {
        result = await service.list('historyfilters');
      } else {
        result = await service.list('advancedfilters?type=' + filterType);
      }
      if (result) setList(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Table
        loading={isLoading}
        size="small"
        tableLayout={"fixed"}
        columns={columns}
        dataSource={filterList}
        pagination={false}
        rowKey={row => row.id}
      />
    </Layout>
  );
};

export default FilterList;