import tableHeader from 'services/tableService';

const columns = () => {

  const colProps = [
    { key: 'order', title: '', width: '6%' },
    { key: 'place', title: 'Place Inhabited During the Armed Conflict', width: '46%' },
    { key: 'period', title: 'Time Period Spent in the Place', width: '48%' }
  ];

  return tableHeader(colProps);
}

export default columns;
