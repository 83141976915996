import React, { useState } from "react";
import Title from "common/title";
import wcm from "assets/images/wcm@3x.png";
import { useParams } from "react-router-dom";
import Introduction from "./introduction";
import Personal from "./personal";
import Context from './context';
import * as Pages from "./pages";
import Locations from "./Locations";
import { useEffect } from "react";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setRespondent, clearRespondent } from "store/respondentSlice";
import { setPage, setLocation, setPlace } from 'store/pageSlice';
import { Curtain } from "common";

const Questionnaire = () => {

  const paraCode = useParams().code;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const name = useSelector(state => state.respondent.name.split(" ")[0]);
  const locations = useSelector(state => state.respondent.locations);
  const { page, location } = useSelector(state => state.page);
  const firstPlace = useSelector(state => state.respondent.place);
  if (page === 0) dispatch(setPage(1));

  const fetchData = async code => {
    setLoading(true);
    const result = await apiServices.read('quest', code);
    setLoading(false);
    if (result) {
      dispatch(setRespondent(result));
      dispatch(setPage(result.page));
      dispatch(setLocation(result.location));
      if (result.location === 0 || result.page < 12) {
        dispatch(setPlace(firstPlace));
      } else {
        const ind = result.locations.findIndex(l => l.id === result.location);
        if (ind >= 0) {
          const tempo = result.locations[ind].city + ", period " + result.locations[ind].period;
          dispatch(setPlace(tempo));
        }
      }
    }
  }

  useEffect(() => {
    if (paraCode)
      fetchData(paraCode);
    else
      dispatch(clearRespondent());
    // eslint-disable-next-line
  }, [paraCode]);

  return (
    <div className="quest">
      {loading && <Curtain />}
      <div className="left">
        <img
          src={wcm}
          alt="logo"
          style={{ height: "32px", marginTop: "12px" }}
        />
      </div>
      <div className="right">
        <Title align="right">
          <h3 style={{ margin: "-24px 0 -12px 0" }}>
            {!name && 'Quest'}
            {!!name && name}
            ...{page}/{location}/{!!locations && <span>{locations.length}</span>}
          </h3>
        </Title>
      </div>
      <div style={{ clear: "both" }} />
      <hr />
      {page === 1 && <Introduction />}
      {page === 2 && <Personal />}
      {page === 3 && <Context />}
      {page === 4 && <Pages.Page4 />}
      {page === 5 && <Pages.Page5 />}
      {page === 6 && <Pages.Page6 />}
      {page === 7 && <Pages.Page7 />}
      {page === 8 && <Pages.Page8 />}
      {page === 9 && <Pages.Page9 />}
      {page === 10 && <Pages.Page10 />}
      {page === 11 && <Pages.Page11 />}
      {page === 12 && <Pages.Page12 />}
      {page === 13 && <Pages.Page13 />}
      {page === 14 && <Pages.Page14 />}
      {page === 15 && <Pages.Page15 />}
      {page === 16 && <Pages.Page16 />}
      {page === 17 && <Pages.Page17 />}
      {page === 18 && <Pages.Page18 />}
      {page === 19 && <Pages.Page19 />}
      {page === 20 && <Pages.Page20 />}
      {page === 21 && <Pages.Page21 />}
      {page === 22 && <Pages.Page22 />}
      {page === 23 && <Pages.Page23 />}
      {page === 24 && <Pages.Page24 />}
      {page === 25 && <Pages.Page25 />}
      {page === 26 && <Pages.Page26 />}
      {page === 27 && <Pages.Page27 />}
      {page === 28 && <Pages.Page28 />}
      {page === 29 && <Pages.Page29 />}
      {page === 30 && <Pages.Page30 />}
      {page === 31 && <Pages.Page31 />}
      {page === 32 && <Pages.Page32 />}
      {page === 110 && <Locations />}
    </div>
  );
};

export default Questionnaire;