import React, { useEffect, useState } from 'react';
import { Form, Input, Layout, Button, Row, Col, Modal, message } from 'antd';
import Password from 'auth/Password';
import service from 'services/apiServices';
import config from 'config';
import { Title, Curtain } from 'common';

const { Content } = Layout;

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

const Profile = ({ history }) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const result = await service.list('profile');
      if (result) {
        form.setFieldsValue({ ...result });
      }
      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const onBack = () => { history.push(config.currentUser.landing); };

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    let result = await service.insert("profile", values);
    if (result) {
      message.success('Profile data updated successfully');
      config.currentUser.name = result.fullName;
      history.push(config.currentUser.landing);
    } else {
      message.error("Error updating user's profile! Please try again");
    }
  };

  const submitPass = async values => {
    const result = await service.update('profile', values, 0);
    if (result) {
      setModalVisible(false);
      message.success('Password updated successfully. Please login again');
      history.push("/logout");
    } else {
      message.error('Error updating password! Please try again');
    }
  }

  const closePass = () => setModalVisible(false);

  if (loading) return <Curtain />;
  return (
    <Layout className="container" style={{ width: '70%', margin: '8vh auto' }}>
      <Content align="center">
        <Title align='center'>Profile for {config.currentUser.name} [{config.currentUser.role}]</Title>
        <hr style={{ marginBottom: '24px' }} />
        <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Form.Item label='First name' name='firstName'><Input /></Form.Item>
          <Form.Item label='Last name' name='lastName'><Input /></Form.Item>
          <Form.Item label='Institution' name='institution'><Input /></Form.Item>
          <Form.Item label='Landing page' name='landing'><Input /></Form.Item>
          <Form.Item label='Reason for access' name='reason'><Input /></Form.Item>
          <hr style={{ marginTop: '24px' }} />
          <Row>
            <Col span={8}><Button className='tableButton' htmlType="button" onClick={() => setModalVisible(true)}>Set Password</Button></Col>
            <Col span={8}><Button className='tableButton' htmlType="button" onClick={onBack}>Back</Button></Col>
            <Col span={8}><Button className='tableButton' htmlType='submit'>SAVE</Button></Col>
          </Row>

          <Modal open={modalVisible}
            onCancel={closePass} footer={[]}
            width='37rem'
            style={{ top: 40, backgroundColor: 'green', padding: 0 }}>
            <div className="inner" style={{ backgroundColor: 'black' }}>
              <Password name={config.currentUser.name} onClose={closePass} onSubmit={submitPass} getOld />
            </div>
          </Modal>

        </Form>
      </Content>
    </Layout >
  );
}

export default Profile;