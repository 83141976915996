import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import config from 'config'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const attRoute = props.location.pathname;
      config.attemptRoute = attRoute === '/' ? '' : attRoute;
      return config.token ? (<Component {...props} />) : (<Redirect to={{ pathname: '/login' }} />)
    }
    }
  />
)

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const attRoute = props.location.pathname;
      config.attemptRoute = attRoute === '/' ? '' : attRoute;
      return (config.currentUser.role.includes('Admin') || config.currentUser.role === 'Intern') ? (<Component {...props} />) : (<Redirect to={{ pathname: '/login' }} />)
    }
    }
  />
)

export { PrivateRoute, AdminRoute }