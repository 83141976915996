import React, { useEffect } from 'react';
import { Form, Input, Radio, Layout, Button, Row, Col, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import service from 'services/apiServices';
import config from 'config';

import { Buttons, Delete } from 'common';

const { Content } = Layout;
const { Dragger } = Upload;

const styles = {
  userField: {
    borderRadius: '4px',
    border: '1px solid #211E1A',
    backgroundColor: '#faffff',
    color: 'black'
  }
}

const layout = { labelCol: { span: 4 }, wrapperCol: { span: 20 } }

const Attachment = ({ item, onOk }) => {

  const [form] = Form.useForm();

  useEffect(() => { form.setFieldsValue({ ...item }) }, [form, item]);

  const options = {
    name: 'file',
    multiple: true,
    action: config.uploadPath + 'document',
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        form.setFieldsValue({ url: info.file.name });
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
      }
    }
  }

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    if (item.id === 0) {
      await service.insert('pieceAttachments', { ...values, piece: { id: values.objectId } });
    } else {
      await service.update('pieceAttachments', { ...values, piece: { id: values.objectId } }, item.id);
    }
    onOk();
  };

  return (
    <Layout>
      <Content align="center">
        <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Form.Item label='Id' name='id' hidden><Input style={styles.userField} /></Form.Item>
          <Form.Item label='Type' name='type' style={{ textAlign: 'left' }}>
            <Radio.Group buttonStyle='solid' size='small'>
              <Radio.Button value='ConditionReport'>Condition Report</Radio.Button>
              <Radio.Button value='Conservation'>Conservation</Radio.Button>
              <Radio.Button value='ShippingContract'>Shipping Contract</Radio.Button>
              <Radio.Button value='ReturnShippingContract'>Return Shipping Contract</Radio.Button>
              <Radio.Button value='Constrictions'>Constrictions</Radio.Button>
              <Radio.Button value='Other'>Other</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='Url' name='url'><Input style={styles.userField} /></Form.Item>
          <Form.Item label='Comment' name='comment'><Input style={styles.userField} /></Form.Item>
          <Form.Item label='Object' name='objectId' hidden><Input style={styles.userField} /></Form.Item>
          <Row>
          <Col span={5} offset={4} style={{ textAlign: 'left' }}><Delete dataSet='pieceAttachments' id={item.id} onFinish={onOk} /></Col>
          <Col span={5}><Button style={Buttons.button} htmlType='submit'>SAVE</Button></Col>
            <Col span={10}>
              <Dragger {...options} style={{ border: '1px solid #369' }}>
                <p className='ant-upload-drag-icon'><InboxOutlined /></p>
                <p className='ant-upload-text'>Click or drag file(s) here</p>
              </Dragger>
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default Attachment;