import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  CondField,
  SingleField
} from "../fields";
import { Curtain } from "common";

const Page14 = () => {
  const page = 14;
  const [loading, setLoading] = useState(false);
  const qn = ["4.18", "4.19", "4.20", "4.21", "4.22", "4.221"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v422, setV422] = useState("No");

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV422(result.answers[4].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <SingleField
            form={form}
            label="18. Did you have electricity?"
            question="c4.18"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="19. How often did the power outages occur?"
            question="c4.19"
            options={[
              "Frequently (multiple times a day)",
              "Often (Multiple times a week)",
              "Occasionally",
              "Rarely",
              "Never",
              "I don't remember",
            ]}
          />

          <SingleField
            form={form}
            label="20. Did you have source of light?"
            question="c4.20"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="21. What did you usually use as a source of light?"
            question="c4.21"
            extension="e4.21"
            options={[
              "Candle",
              "Light bulb connected to a car battery",
              "Improvised oil/fat lamp",
              "Electric lighting",
              "Open fire",
              "Torch",
            ]}
            other="Other (specify):"
          />

          <CondField
            form={form}
            label="22. Did you get electricity from a source that did not belong to the infrastructure of your house/apartment/accommodation?"
            question="c4.22"
            trigger="Yes"
            current={v422}
            options={["Yes", "No", "I don't remember"]}
            subQuestion="c4.221"
            subLabel="22.1 If yes, in what way?"
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page14;
