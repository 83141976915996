import React, { useState } from 'react';
import { Layout, Button, Row, Col, Divider, Drawer } from 'antd';
import config from 'config';
import services from 'services/apiServices';
import StoryView from '../storyView';

const { Content } = Layout;

const ViewTrans = ({ item, onOk }) => {

  const [storyView, setStoryView] = useState(false);
  const [storyId, setStoryId] = useState(false);

  return (
    <Layout>
      <Content>
        <Row>
          <Col span={6}>
            <h3>Language:<br /><b>{item.language}</b></h3>
          </Col>
          <Col span={6}>
            <h3>Status:<br /><b>{item.status}</b></h3>
          </Col>
          <Col span={9}>
            <h3>Title:<br /><b>{item.title}</b></h3>
          </Col>
          <Col span={3}>
          {!!item.storyId && (
          <h3>
            Story<br />
            <button onClick={() => { setStoryView(true); setStoryId(item.storyId); }}>{services.toFormat(item.storyId)}</button>
          </h3>
          )}
          </Col>
        </Row>
        <div className='left'>
          {item.audio !== "n/a" &&
            <audio controls controlsList='nodownload'>
              <source src={`${config.mediaPath}${item.audio}`} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>}
        </div>
        <Divider />
        <div dangerouslySetInnerHTML={{ __html: item.text }} />
        <Divider />
        <div style={{ float: 'right' }}><Button className='tableButton' onClick={() => onOk()}>CLOSE</Button></div>
      </Content>

      <Drawer title={<h2>Story Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setStoryView(false)}
        open={storyView}>
        <StoryView id={storyId} />
      </Drawer>
    </Layout >
  );
}

export default ViewTrans;