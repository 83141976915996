import React, { useState, useEffect } from 'react';
import { Alert, Button, Card, Input, Drawer, Checkbox } from 'antd';
import { ObjectView } from '../objects';
import config from 'config';
import { Curtain } from 'common';
import apiServices from 'services/apiServices';

const { Search } = Input;

const PartReport = () => {

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState({});
  const [current, setCurrent] = useState(0);
  const [object, setObject] = useState({});
  const [photo, setPhoto] = useState(false);
  const [report, setReport] = useState(false);
  const [sent, setSent] = useState("null");
  const [showDetail, setShowDetail] = useState(false);

  const search = async value => {
    const url = 'reports/parts?filter=' + value;
    setLoading(true);
    let response = await apiServices.list(url);
    if (response) {
      setPhoto(response.wrongPhoto);
      setReport(response.wrongReport);
      setObject(response);
      setSent("null");
      response = await apiServices.list('reports/parts/count');
      setCount(response);
    }    
    setLoading(false);
  }

  const fetch = async (current) => {
    setLoading(true);
    let response = current ? await apiServices.read('reports/parts', current) : await apiServices.list('reports/parts');
    if (response) {
      setPhoto(response.wrongPhoto);
      setReport(response.wrongReport);
      setObject(response);
      setSent("null");
      response = await apiServices.list('reports/parts/count');
      setCount(response);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetch(current);
    config.activeKey = '3';
    // eslint-disable-next-line
  }, [current]);

  const verify = async () => {
    setLoading(true);
    let response = await apiServices.update('reports/parts', { verified: true, next: object.next }, object.id);
    setObject(response);
    response = await apiServices.list('reports/parts/count');
    setCount(response);
    setLoading(false);
  }

  const goPrev = () => setCurrent(object.prev);

  const goNext = () => setCurrent(object.next);

  const Verified = () => {
    if (object.verified) return <Alert type="success" message="CHECKED" />;
    return <Alert type="error" message="NOT CHECKED" />
  }

  const reportIssues = async () => {
    setLoading(true);
    const response = await apiServices.update('reports/parts', { wrongPhoto: photo, wrongReport: report, next: object.next, prev: object.prev }, object.id);
    if (response) {
      setSent("success");
    }
    else {
      setSent("error");
    }
    setLoading(false);
  }

  return (
    <>
      {loading && <Curtain />}
      <Card className='reportCard'>
        <h2>Part Reports ({count.count} verified, {count.total} to go)</h2>
        <hr />
        <div className='reportContainer'>
          <div className='reportButton'>
            <Button
              type='primary'
              disabled={object.prev === 0}
              onClick={goPrev}>
              PREV
            </Button>
          </div>
          <div className='reportButton'>
            <Button type='primary' onClick={verify}>CHECKED</Button>
          </div>
          <div className='reportButton'>
            <Search onSearch={search} allowClear className='searchBar' />
          </div>
          <div className='reportButton'>
            <Button
              type='primary'
              disabled={object.next === 0}
              onClick={goNext}>
              NEXT
            </Button>
          </div>
        </div>
        <hr />
        <div>
          <h3 style={{ textAlign: 'left' }}>{object.objectId} --&gt; {object.objectName}<br />
          {object.partId} --&gt; {object.name}</h3>
          <Verified />
          <img
            style={{ border: '1px black solid', marginTop: '24px', cursor: 'pointer', height: '240px' }}
            src={`${config.tempoPath}${object.image}`}
            onClick={() => setShowDetail(true)}
            alt={object.image}
          />
        </div>
        <div style={{ margin: '12px auto', width: '25%' }}>
          <hr />
          <div style={{ textAlign: 'left' }}>
            {/* <Checkbox.Group style={{ textAlign: 'left', paddingLeft:'12px' }}> */}
            {/* <Checkbox value="photo" onClick={() => {setSent("null"); setPhoto(!photo); }} checked={photo}>Wrong photo</Checkbox><br /> */}
            <Checkbox value="report" onClick={() => {setSent("null"); setReport(!report)}} checked={report}>Wrong report</Checkbox>
            {/* </Checkbox.Group> */}
          </div>
          <div style={{ marginTop: '12px' }} />
          {sent === "null" && <Button type="primary" onClick={reportIssues}>REPORT ISSUES</Button>}
          {sent === "success" && <button className="success message" onClick={goNext}>ISSUES SUBMITTED!</button>}
          {sent === "error" && <button className="error message">ERROR SUBMITTING ISSUES TO THE SERVER!</button>}
        </div>
      </Card>
      {!!object.report &&
        <Card className='reportCard'>
          <h2>{object.report}</h2>
          <hr />
          <iframe
            title={object.report}
            src={`${config.tempoPath}${object.file}#toolbar=0#view=fitH`}
            style={frame}
          />
        </Card>
      }
      <Drawer
        title="Object View"
        width={'60%'}
        placement="right"
        onClose={() => setShowDetail(false)}
        destroyOnClose={true}
        open={showDetail}>
        <ObjectView id={object.objId} />
      </Drawer>
    </>
  );
}

export default PartReport;

const frame = {
  display: 'block',
  height: '100vh',
  width: '100%',
  border: 'none'
}