import React, { useState, useEffect, useCallback } from 'react';
import moment from "moment";
import { message, Divider, Layout, Drawer, Form, Input, Row, Col, Select, DatePicker, Checkbox, Modal, Collapse } from "antd";
import FilterNavigator from './FilterNavigator';
import FilterList from './FilterList';
import { Curtain } from "common";
import service from 'services/apiServices';
import { OralHistory } from 'components/oralHistory';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

const styles = {
  select: {
    textAlign: 'left',
    fontSize: '11px',
    borderRadius: '4px',
    border: 'solid 1px black',
    backgroundColor: 'white',
    color: 'black'
  },
  txt: {
    height: '32px'
  },
  col: {
    padding: '0 8px'
  },
  divider: {
    backgroundColor: '#DDC7B0',
    fontSize: '1.2rem',
    marginBottom: 8
  }
};

const yesNoOptions = [
  { label: 'YES', value: 'YES' },
  { label: 'NO', value: 'NO' }
]

const mediaOptions = [
  { label: 'Audio', value: 'Audio' },
  { label: 'Video', value: 'Video' },
  { label: 'Other', value: 'Other' }
]

const joinValues = values => {
  values.compoundType = 'And';
  if (values.conflicts) values.conflicts = values.conflicts.join();
  if (values.researchCells) values.researchCells = values.researchCells.join();
  if (values.tag) values.tag = values.tag.join();
  if (values.subtag) values.subtag = values.subtag.join();
  if (values.restrictions) values.restrictions = values.restrictions.join();
  if (values.nativeLanguage) values.nativeLanguage = values.nativeLanguage.join();
  if (values.mediaType) values.mediaType = values.mediaType.join();
  if (values.transcribed) values.transcribed = values.transcribed.join();
  if (values.translated) values.translated = values.translated.join();
  if (values.editedBHS) values.editedBHS = values.editedBHS.join();
  if (values.editedEng) values.editedEng = values.editedEng.join();
  values.interDate1 = !values.interDate1 ? null : moment(values.interDate1).format('YYYY-MM-DD');
  values.interDate2 = !values.interDate2 ? null : moment(values.interDate2).format('YYYY-MM-DD');

  return values;
}

const splitValues = data => {
  const values = {
    ...data,
    conflicts: !data.conflicts ? undefined : data.conflicts.split(','),
    researchCells: !data.researchCells ? undefined : data.researchCells.split(','),
    tag: !data.tag ? undefined : data.tag.split(','),
    subtag: !data.subtag ? undefined : data.subtag.split(','),
    restrictions: !data.restrictions ? undefined : data.restrictions.split(','),
    nativeLanguage: !data.nativeLanguage ? undefined : data.nativeLanguage.split(','),
    mediaType: !data.mediaType ? undefined : data.mediaType.split(','),
    transcribed: !data.transcribed ? undefined : data.transcribed.split(','),
    translated: !data.translated ? undefined : data.translated.split(','),
    editedBHS: !data.editedBHS ? undefined : data.editedBHS.split(','),
    editedEng: !data.editedEng ? undefined : data.editedEng.split(',')
  };
  values.interDate1 = data.interDate1 ? moment(data.interDate1) : null;
  values.interDate2 = data.interDate2 ? moment(data.interDate2) : null;
  values.dateRange = [values.interDate1, values.interDate2];
  return values;
}

const OralHistoryFilter = ({ onChangeTitle }) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [conflicts, setConflicts] = useState([]);
  const [cells, setCells] = useState([]);
  const [tags, setTags] = useState([]);
  const [subtags, setSubtags] = useState([]);
  const [langs, setLangs] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showView, setShowView] = useState(false);
  const [dataSet, setData] = useState([]);

  /* --------------------------------- */

  const master = useCallback(async () => {
    try {
      setLoading(true);
      setConflicts(await service.list('master/conflicts'));
      setCells(await service.list('master/researchCells'));
      setLangs(await service.list('master/tags/5'));
      setTags(await service.list('master/otags'));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    master();
  }, [master])

  const setDates = range => {
    if (range === null) {
      form.setFieldsValue({
        interDate1: null,
        interDate2: null
      })
    } else {
      form.setFieldsValue({
        interDate1: range[0],
        interDate2: range[1]
      })
    }
  }

  /* --------------------------------- */

  const onFinish = async values => {
    try {
      setLoading(true);
      values = joinValues(values);
      let result;
      if (values.id === 0) {
        values.sqlName = prompt('Please enter a name for this filter');
        if (values.sqlName === null) return;
        console.log(values);
        result = await service.insert('historyfilters', values);
      } else {
        result = await service.update('historyfilters', values, values.id);
      }
      setLoading(false);
      if (result) message.success('The filter parameters successfully saved.');
    } catch (error) {
      setLoading(false);
      message.error('Error occured while saving filter');
    }
  };

  const onFailed = error => console.error(error);

  const preview = async data => {
    try {
      setLoading(true);
      data = joinValues(form.getFieldsValue());
      const result = await service.insert('historyfilters/runsql', data);
      setLoading(false);
      if (result) {
        setData(result.dataSet);
        setShowView(true);
      } else {
        message.error('Error occured while getting sql view');
      }
    } catch (error) {
      setLoading(false);
      message.error('Error occured while getting sql view');
    }
  }

  const onReset = () => form.resetFields();

  const showSaved = () => setShowList(true);

  const selectFilter = async data => {
    data = splitValues(data);
    await tagChanged(data.tag);
    form.setFieldsValue(data);
    setShowList(false);
    onChangeTitle(data.sqlName);
  }

  const takeSnap = () => console.log(tags);
  const viewSnap = () => console.log('viewSnap');

  const tagChanged = async values => {
    if(values) {
      setSubtags(await service.insert('master/stags', values));  
    } else {
      setSubtags([]);
    }
  }

/* --------------------------------- */

  if (loading) return <Curtain />;
  return (
    <Layout>
      <Form form={form} layout='vertical' onFinish={onFinish} onFinishFailed={onFailed}>
        <h2>{form.getFieldValue("sqlName")}</h2>
        <FilterNavigator
          showSaved={showSaved}
          submit={() => form.submit()}
          preview={preview}
          onReset={onReset}
          takeSnap={takeSnap}
          haveSnap={form.getFieldValue('snapshot')}
          viewSnap={viewSnap} />
        <Row>
          <Col><Form.Item label='Id' name='id' initialValue={0} hidden><Input /></Form.Item></Col>
          <Col><Form.Item label='Sql name' name='sqlName' initialValue="" hidden><Input /></Form.Item></Col>
          <Col><Form.Item label='Snapshot' name='snapshot' initialValue="" hidden><Input /></Form.Item></Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item label='Armed Conflicts' name='conflicts' initialValue={[]}>
              <Select mode='multiple' style={styles.select} showSearch>
                {conflicts.map(c => (<Option key={c.id} value={c.name}>{c.name}</Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Research Cells' name='researchCells' initialValue={[]}>
              <Select mode='multiple' style={styles.select} showSearch>
                {cells.map(c => (<Option key={c.id} value={c.name}>{c.name}</Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item label='Restrictions on use' name='restrictions' initialValue={[]}>
              <Checkbox.Group options={yesNoOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Collapse defaultActiveKey="H">
          <Panel style={styles.divider} header="Oral History Criteria" key="H">
            <Row>
              <Col span={8}>
                <Form.Item label='Native Language' name='nativeLanguage' initialValue={[]}>
                  <Select mode='multiple' style={styles.select} showSearch>
                    {langs.map(c => (<Option key={c.id} value={c.name}>{c.name}</Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Interview Date' name="dateRange">
                  <RangePicker onChange={setDates} initialValue={[null, null]} />
                </Form.Item>
                <Form.Item label='' name="interDate1" hidden><DatePicker /></Form.Item>
                <Form.Item label='' name="interDate2" hidden><DatePicker /></Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label='Location' name='location' initialValue="">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={3}>
                <Form.Item label='Media Type' name='mediaType' initialValue={[]}>
                  <Checkbox.Group options={mediaOptions} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label='Transcribed' name='transcribed' initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label='Translated' name='translated' initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label='Edited Native' name='editedBHS' initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label='Edited English' name='editedEng' initialValue={[]}>
                  <Checkbox.Group options={yesNoOptions} />
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                <Row>
                  <Col span={24}>
                    <Form.Item label='Notes' name='notes' initialValue="">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label='Interview Notes' name='interNotes' initialValue="">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label='Tags' name='tag' initialValue={[]}>
                  <Select mode='multiple' style={styles.select} showSearch onChange={tagChanged}>
                    {tags.map(c => (<Option key={c.id} value={c.name}>{c.name}</Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Subtags' name='subtag' initialValue={[]}>
                  <Select mode='multiple' style={styles.select} showSearch>
                    {subtags.map(c => (<Option key={c.id} value={c.name}>{c.name}</Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Form>

      <Drawer
        title={<h2>Oral Histories Advanced Filters</h2>}
        width={'60%'}
        placement="right"
        onClose={() => setShowList(false)}
        destroyOnClose={true}
        open={showList}>
        <FilterList
          filterType="H"
          selectFilter={selectFilter}
          preview={preview}
          takeSnap={takeSnap}
          viewSnap={viewSnap} />
      </Drawer>

      <Modal aria-hidden width={'90%'} open={showView} footer={[]} onCancel={() => setShowView(false)} style={{ top: 20 }} destroyOnClose={true}>
        <OralHistory dataSource={dataSet} />
      </Modal>

    </Layout>
  );
}

export default OralHistoryFilter;