import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page25 = () => {
  const page = 25;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["5.09", "5.10", "5.11", "5.12", "5.13", "5.14"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });

      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <TextField
            form={form}
            label="9. What was the size of the living space (approximately in m²)?"
            question="c5.09"
          />
          <TextField
            form={form}
            label="10.	Whom did you live with?"
            question="c5.10"
          />
          <TextField
            form={form}
            label="11. What was your favorite meal?"
            question="c5.11"
          />
          <TextField
            form={form}
            label="12. Which food did you miss the most?"
            question="c5.12"
          />
          <TextField
            form={form}
            label="13. What was your least favorite food?"
            question="c5.13"
          />
          <SingleField
            form={form}
            label="14. Did you have access to medical care?"
            question="c5.14"
            options={["Yes", "No", "I don't remember"]}
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page25;
