import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppHeader from './core/appHeader';
import Footer from './core/appFooter';
import MainRouter from './mainRouter';
import { Layout } from 'antd';

const App = () => {
  return (
    <BrowserRouter>
      <AppHeader />
      <Layout className='layout'>
        <MainRouter />
      </Layout>
      <Footer />
    </BrowserRouter>
  );
}

export default App;