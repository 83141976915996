import React from "react";
import { Row, Spin } from "antd";

export default function Spinner() {
  return (
    <Row justify="space-around" align="middle">
      <Spin size="large" style={{marginTop:'50vh'}} />
    </Row>
  );
}

