import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import service from 'services/apiServices';

const Survey = ({ participant }) => {

  const [survey, setSurvey] = useState([]);

  async function fetch(id) {
    const result = await service.read('participants', id + '/survey');
    if (result) setSurvey(result.Qa);
  }

  useEffect(() => { fetch(participant) }, [participant]);

  let n = 0;

  const Item = ({ item }) => {
    const title = item.Title;
    let answer = "n/a";
    if (!(item.Answer === undefined || item.Answer.string === undefined || item.Answer.string === null)) {
      answer = (item.Answer.string.length === 1) ? item.Answer.string : item.Answer.string[0];
    }
    else {
      if (!(item.Qas === undefined || item.Qas.Qa === undefined || item.Qas.Qa.Answer === undefined)) {
        answer = (item.Qas.Qa.Answer.string.length === 1) ? item.Qas.Qa.Answer.string : item.Qas.Qa.Answer.string[0];
      } else {
        answer = item.Description;
      }
    }
    return (
      <Row className='surveyRow'>
        <Col className='surveyCol' span={12} style={{ textAlign: 'right' }}>{title}:</Col>
        <Col className='surveyCol' span={12} style={{ textAlign: 'left' }}><b>{answer}</b></Col>
      </Row>)
  };

  return (
    <Layout>
      {survey.map(item => <Item key={n++} item={item} />)}
    </Layout >
  );
}

export default Survey;
