import React from 'react';
import { Row, Col, Table } from "antd";
import labels from "assets/labels/participant.json";
import columns from "./resCols";

const divider = { border: '1px solid darkgrey' };

const ResidencesView = ({ returnCountry, returnTown, residences, refugee, returnee, idp, stateless, unChild, homeTown }) => {

  if (!(returnCountry || returnTown || residences.length || refugee || returnee || idp || stateless)) return <div />
  
  const TableTitle = () => <h2>Places of Residence and Time Periods<br /><small>Hometown/home country: {homeTown}</small></h2>;

  return (
    <>
    {/* <Card style={cardItem}> */}
      <Table
        title={TableTitle}
        size="small"
        tableLayout={"fixed"}
        columns={columns()}
        pagination={false}
        dataSource={residences}
        rowKey={(row) => row.id}
      />
      {(returnCountry || returnTown) && (
        <Row style={{ marginTop: '12px' }}>
          <Col span={12}>{labels.RETURN_COUNTRY}: <b>{returnCountry}</b></Col>
          <Col span={12} align='right'>{labels.RETURN_TOWN}: <b>{returnTown}</b></Col>
        </Row>
      )}
      <hr style={divider} />
      <Row>
        <Col span={4}>Refugee: <b>{refugee ? 'YES' : 'NO'}</b></Col>
        <Col span={4}>Returnee: <b>{returnee ? 'YES' : 'NO'}</b></Col>
        <Col span={4}>IDP: <b>{idp ? 'YES' : 'NO'}</b></Col>
        <Col span={4}>Stateless: <b>{stateless ? 'YES' : 'NO'}</b></Col>
        <Col span={7}>Unaccompanied child: <b>{unChild ? 'YES' : 'NO'}</b></Col>
      </Row>
      <hr style={divider} />
    {/* </Card> */}
    </>
  );
}

export default ResidencesView;
