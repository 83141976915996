import React, { useState, useEffect, useCallback } from 'react';
import { Table, message, Layout, Drawer } from 'antd';
import config from 'config';
import { Filter } from 'common';
import { isSuper } from "services/authServices";
import service from 'services/apiServices';
import columns from './columns';
import ExhibitionView from './exhibitionView';

const { Content } = Layout;

const Exhibitions = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(config.headers.page);
  const [showView, setShowView] = useState(false);
  const [selId, setId] = useState(0);
  const canEdit = isSuper();

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await service.list('exhibitions');
      setData(result);
    }
    catch (error) {
      message.error(error.message);
    }
    finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const onChange = o => {
    config.headers.page = o.current;
    setCurrentPage(config.headers.page);
  }

  const refreshPage = () => {
    fetch();
  }

  const TableTitle = () => {
    return (
      <div>
        <div style={{ float: 'left' }}><h2 align='left'>Exhibitions / {data.length}</h2></div>
        <Filter onChange={refreshPage} />
      </div>
    )
  }

  return (
    <Layout className='container'>
      <Drawer
        title={<h2>Exhibition Detail View</h2>}
        width={'60%'}
        placement="right"
        onClose={() => setShowView(false)}
        destroyOnClose={true}
        open={showView}>
        <ExhibitionView id={selId} />
      </Drawer>

      <Content>
        <Table
          loading={loading}
          title={TableTitle}
          size="small"
          tableLayout={"fixed"}
          columns={columns({
            canEdit: canEdit,
            editUrl: 'exhibitions'
          })}
          dataSource={data}
          onChange={onChange}
          onRow={(record) => ({ onClick: () => { setId(record.id); setShowView(true); } })}
          pagination={{ current: currentPage, size: 'small', hideOnSinglePage: true, showSizeChanger: true }}
          rowKey={(row) => row.id}
        />
      </Content>
    </Layout>
  );
}

export default Exhibitions;
