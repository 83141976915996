import axios from 'axios';
import config from 'config';
import { getToken } from 'services/authServices';
import { encode } from './base64';

const defaultHeaders = () => {
  config.headers = {
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    sortBy: 'id',
    sortDir: 'asc',
    filter: ''
  }
}

const setSort = (what) => {
  if (what === config.headers.sortBy) {
    config.headers.sortDir = (config.headers.sortDir === 'asc') ? 'des' : 'asc'
  }
  else {
    config.headers.sortBy = what
    config.headers.sortDir = 'asc'
  }
}

const headers = () => {
  // const token = config.token;
  const token = getToken();
  return {
    'content-type': 'application/json; charset=utf8',
    'pragma': 'no-cache, no-store, must-revalidate',
    'cache-control': 'no-cache',
    'expires': '0',
    'accept': '*/*',
    'authorization': `Bearer ${token}`,
    'headermodel': JSON.stringify(config.headers)
  };
};

const auth = async function (credentials) {
  return await request('post', `${config.apiUrl}login`, credentials, headers());
};

const reset = async function (email) {
  const address = encode(email);
  return await request(
    'post',
    `${config.apiUrl}reset`,
    `"${address}"`,
    headers()
  );
};

const list = async function (dataSet) {
  let url = `${config.apiUrl}${dataSet}`;
  let hdr = headers();
  if (config.filter) {
    const f = (config.prefix === '') ? config.filter : config.prefix + config.filter;
    url += url.includes('?') ? '&' : '?';
    url += `filter=${f}`;
  }
  return await request('get', url, {}, hdr);
};

const read = async function (dataSet, id) {
  return await request(
    'get',
    `${config.apiUrl}${dataSet}/${id}`,
    {},
    headers()
  );
};

const insert = async function (dataSet, data) {
  return await request(
    'post',
    `${config.apiUrl}${dataSet}`,
    data,
    headers());
};

const update = async function (dataSet, data, id) {
  return await request(
    'put',
    `${config.apiUrl}${dataSet}/${id}`,
    data,
    headers()
  );
};

const remove = async function (dataSet, id) {
  return await request(
    'delete',
    `${config.apiUrl}${dataSet}/${id}`,
    {},
    headers()
  );
};

const upload = async function (dataSet, data) {
  const headers = {
    'content-type': 'image/*',
    authorization: `Bearer ${config.token}`
  }
  const res = await request('post', `${config.apiUrl}${dataSet}`, data, headers);
  return res;
};

const request = async function (method, url, data, headers) {
  try {
    const res = await axios({
      method: method,
      url: url,
      data: data,
      headers: headers
    });
    let hdr = res.headers.headermodel;
    if (hdr !== undefined) {
      config.headers = JSON.parse(hdr);
    }
    if (res.status === 207) {
      return { status: 207, message: res.data };
    } else {
      return res.data;
    }
  } catch (err) {
    const error = err.response.data.message;
    console.error(err);
    if(error) console.error(error);
    if(error && error.toLowerCase().includes("token")) window.location.href = '/login';
    return false;
  }
};

const getFile = async function (url) {
  let hdr = headers();
  return await request('get', url, {}, hdr);
};

const anything = (inp, opt) => {
  opt = opt.toLowerCase();
  let x = inp.toLowerCase().split(' ');
  let y = true;
  x.forEach(e => {
    y = y && opt.indexOf(e) >= 0
  });
  return y;
}

const toNumber = sid => {
  if (!sid) return 0;
  const x = sid.split('.');
  if (x.length !== 2) return sid;
  return 10000 * parseInt(x[0]) + parseInt(x[1]);
}

const toFormat = id => {
  if (parseInt(id) === 0) return '0';
  const x = Math.floor(id / 10000);
  const y = id % 10000;
  return `${x}.${y}`;
}

const bool = x => {
  if (Array.isArray(x)) {
    let y = x.join();
    if (y === 'YES') return 'YES';
    if (y === 'NO') return 'NO';
    return '';
  } else {
    return x === 'YES,NO' ? '' : x;
  }
}

export default { anything, toFormat, toNumber, bool, auth, reset, list, read, insert, update, remove, upload, headers, defaultHeaders, setSort, getFile };
