import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { SingleField } from "../fields";
import { Curtain } from "common";

const Page8 = () => {

  const page = 8;
  const [loading, setLoading] = useState(false);
  const qn = ["3.41", "3.42", "3.43"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent)

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      };
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <SingleField form={form} label="41. Did you have access to medical care?" question="c3.41" options={["Yes", "No", "I don't remember"]} />

          <SingleField form={form} label="42. How did you manage to obtain medicine/medical supplies?"
            question="c3.42" other="Other:" extension="e3.42"
            options={[
              "Home supplies",
              "Neighbors' supplies",
              "We received them in humanitarian aid",
              "We received medicine in a package from abroad",
              "We received them in a local medical institution",
              "We bought medicine in a pharmacy",
              "We bought medicine on the black market",
              "I don't remember"
            ]} />

          <SingleField form={form} label="43. Did you ever visit a hospital during armed conflict?"
            question="c3.43" other="Other:" extension="e3.43"
            options={[
              "Yes, because of some health problems that were not related to wounding",
              "As a volunteer/employee",
              "As a visitor to a member of my family/friend",
              "No, there was no need"
            ]} />

        </Form>
        <Navigator prev={page-1} next={page+1} save={() => form.submit()} />
      </div>
    </>
  );
}

export default Page8;
