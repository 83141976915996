import React, { useState } from "react";
import { Form, Input, Layout, Row, Col, message } from "antd";
import moment from 'moment';
import service from "services/apiServices";
import { Curtain } from 'common';

const Confirm = ({ form, labels, onFinish }) => {

  const [loading, setLoading] = useState(false);

  const onUpdate = async function (values) {
    if(values.storyId === "0" || values.storyId === 0) values.storyId = "0.0";
    let { id } = values;
    if (id === undefined) id = '0';
    values.accessionDate = moment(values.accessionDate).format('YYYY-MM-DD');
    if (values.useAndStorage === undefined) values.useAndStorage = '';
    if (Array.isArray(values.material)) values.material = values.material.join();
    if (values.digitalCopy === undefined) values.digitalCopy = '';
    if (Array.isArray(values.digitalCopy)) values.digitalCopy = values.digitalCopy.join();

    let msgText = "";
    setLoading(true);
    let result = null;
    if (id === 0) {
      result = await service.insert('pieces', values);
      msgText = "Object's info succesfully inserted."
    } else {
      result = await service.update('pieces', values, id);
      msgText = "Object's info succesfully updated."
    }
    setLoading(false);
    if (result) {
      message.success(msgText);
      onFinish(result.count);
    } else {
      message.error("Error updating object's info!");
    }
  };

  const Show = ({ label, value, type }) => {
    let x = form.getFieldValue(value);
    switch (type) {
      case 'bool': x = x ? 'YES' : 'NO'; break;
      case 'date': x = moment(x).format("YYYY-MM-DD"); break;
      default: break;
    }
    if (x && x.length > 90) x = x.substring(0, 100) + '...';
    return (<Row>
      <Col span={12} style={{ textAlign: 'right' }}>{label}:&nbsp;</Col>
      <Col span={12} style={{ textAlign: 'left' }}><b>{x}</b>
        <Form.Item label={label} name={value} hidden><Input /></Form.Item>
      </Col>
    </Row>);
  };

  if (loading) return <Curtain />;
  return (
    <Layout className='container'>
      <Form form={form} onFinish={onUpdate}>
        <Show key={0} label={labels.ID} value="id" />
        <Show key={1} label={labels.PARTICIPANT} value='participantId' />
        <Show key={'1a'} label={labels.PARTICIPANT} value='participant' />
        <Show key={2} label={labels.STORY} value='storyId' />
        <Show key={'2a'} label={labels.STORY} value='story' />
        <Show key={3} label={labels.NAME} value='name' />
        <Show key={4} label={labels.TYPE} value='objectType' />
        <Show key={5} label={labels.DESCRIPTION} value='description' />
        <Show key={6} label={labels.DIGITAL_COPY} value='digitalCopy' />
        <Show key={7} label={labels.STORAGE} value="storage" />
        <Show key={'7a'} label={labels.STORAGE} value="storageCode" />
        <Show key={8} label={labels.LOCATION} value="location" />
        <Show key={9} label={labels.LEGAL_STATUS} value='legalStatus' />
        <Show key={10} label={labels.ACCESSION} value='accessionDate' type='date' />
        <Show key={11} label={labels.LOAN_STATUS} value='loanStatus' />
        <Show key={12} label={labels.USE_STORAGE} value='useAndStorage' />
        <Show key={13} label={labels.EXHIBITION} value='exhibitionHistory' />
        <Show key={14} label={labels.REPORT} value='conditionReport' type='bool' />
        <Show key={'14a'} label={labels.REPORT} value='report' />
        <Show key={15} label={labels.CONSERVATION} value='conservation' type='bool' />
        <Show key={16} label='Mount' value='stand' type='bool' />
        <Show key={17} label='Mount Location' value='standLocation' />
        <Show key={18} label={labels.HANDMADE} value="handmade" type='bool' />
        <Show key={19} label={labels.MATERIAL} value='material' />
        <Show key={21} label={labels.QUANTITY} value="quantity" />
        <Show key={22} label={labels.BROKEN} value="broken" type='bool' />
        <Show key={23} label={labels.HANDLING} value="handlingRequirements" />
        <Show key={24} label={labels.LENGTH} value="length" />
        <Show key={25} label={labels.WIDTH} value="width" />
        <Show key={26} label={labels.HEIGHT} value="height" />
        <Show key={27} label={labels.OTHER} value="other" />

        <Show key={28} label='Conflict' value="conflict" />
        <Show key={29} label='Order' value="order" />
        <Show key={30} label='Digital' value="digital" />
        <Show key={31} label='Copy' value="copy" />
        <Show key={32} label='Study' value="study" />
        <Show key={33} label='Object Contributor' value="objectContributor" />
        <Show key={34} label='Contributor Contact' value="objectContributor" />
        <Show key={35} label="Restrictions" value="restrictions" type="bool" />
        <Show key={36} label="Owned by" value="ownedBy" />
        <Show key={37} label="Shape" value="shape" />
      </Form >
    </Layout >
  );
};

export default Confirm;
