import React, { useState, useEffect } from 'react';
import { Divider, Row, Col, Button, Form, Input, Card } from 'antd';
import apiServices from 'services/apiServices';
import { Spinner } from 'common';

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

const S2P = ({ id, onTrans }) => {

  const [object, setObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const fetchObject = async () => {
    setLoading(true);
    const response = await apiServices.read('transfer/s2p', id);
    form.setFieldValue('participantId', response.participantId);
    form.setFieldValue('firstName', response.firstName);
    form.setFieldValue('lastName', response.lastName);
    form.setFieldValue('phone', response.phone);
    form.setFieldValue('email', response.email);
    setLoading(false);
    setObject(response);
  }

  useEffect(() => {
    fetchObject();
    // eslint-disable-next-line
  }, []);

  const transfer = e => {
    onTrans(object);
  }

  if (loading) return <Spinner />;
  return (
    <Card elevation={0}>
      <h2>{object.name}</h2>
      <Divider />
      <Row>
        <Col span={8} align="center">
          <h4>Old object ID</h4>
          <h1>{object.oldId}</h1>
        </Col>
        <Col span={8} align="center">
          <Button type="primary" onClick={transfer}>CONFIRM TRANSFER</Button>
        </Col>
        <Col span={8} align="center">
          <h4>New object ID</h4>
          <h1>{object.newId}</h1>
        </Col>
      </Row>
      <Divider />
        <Row>
          <Col span={8} offset={4}>
            <h4>Object Contributor<br />{object.objectContributor}</h4>
          </Col>
          <Col span={12}>
            <h4>Contributor Contact<br />{object.contributorContact}</h4>
          </Col>
        </Row>
      <Divider />
      <Form form={form} {...layout}>
        <Row>
          <Col span={4} offset={2}>
            <Form.Item name="participantId" label="Id"><Input disabled /></Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="firstName" label="First Name"><Input /></Form.Item>
            <Form.Item name="lastName" label="Last Name"><Input /></Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="phone" label="Phone"><Input /></Form.Item>
            <Form.Item name="email" label="Email"><Input /></Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default S2P;