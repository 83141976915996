import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import moment from 'moment';
import { Table, Layout, Modal, message } from "antd";

import service from "services/apiServices";
import tableService from "services/tableService";
import config from "config";
import Note from './note';

const colProps = [
  { key: "time", title: "Time", width: '10%', render: (time) => moment(time).format('YYYY-MM-DD') },
  { key: "type", title: "Type", width: '10%' },
  { key: "comment", title: "Comment", width: '57%' },
  { key: "user", title: "User", width: '10%' }
];

const { Content } = Layout;

const Notes = ({ object }) => {

  const columns = () =>
  tableService(
    colProps,
    false,
    true,
    {},
    id => editNote(id)
  );
  
  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list(`pieces/${object}/notes`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [object]);

  useEffect(() => { fetch(); }, [fetch]);

  const editNote = id => {
    if(id===0){
      setItem({ id: 0, time: Date.now(), type: 'General', comment: '', objectId: object, userId: config.currentUser.id });
    } else {
      const note = _.find(data, { id: id });
      setItem({ id: note.id, time: note.time, type: note.type, comment: note.comment, objectId: object, userId: note.user.id });  
    }
    showModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={isLoading}
            size="small"
            tableLayount={"fixed"}
            columns={columns({
              canView: false,
              canEdit: true,
              onViewClick: {},
              onEditClick: (id) => editNote(id),
            })}
            dataSource={data}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
          <Modal width={'60%'} open={visible} title={<h2>N O T E</h2>} footer={[]} onCancel={hideModal}><Note item={item} onOk={refresh} /></Modal>
    </div>
  );
};

export default Notes;
