import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';

const LoginPin = ({ onClose, onFinish }) => {

  const [form] = Form.useForm();

  const submit = values => {
    onFinish({ username: "pin", password: values.pin });
  }

  return (
    <div style={{ width: '480px' }}>
      <Form form={form} onFinish={submit}>
        <div className="pin-title">VERIFICATION CODE REQUIRED</div>
        <div className="pin-text">
          <p>Dear user,</p>
          <p>We just sent a verification code to your email.</p>
          <p>Please check your mail and enter the received code into the appropriate field below. You have to use the verification code during next 15 minutes.</p>
          <p>If you are having any issues with your account please don’t hesitate to contact us by replying to this mail.</p>
        </div>
        <hr />
        <Row style={{ height: '36px', paddingTop: '4px' }}>
          <Col span={12}>
            <Form.Item name="pin" label={<span className='white-label'>Verification code: </span>}><Input /></Form.Item>
          </Col>
          <Col span={6}>
            <Button key='s' type='primary' htmlType="submit">Submit</Button>
          </Col>
          <Col span={6}>
            <Button key='c' type='primary' onClick={onClose}>Close</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default LoginPin;