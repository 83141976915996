import React, { useState, useCallback, useEffect } from "react";
import { Drawer, Divider, message, Card, List, Row, Col } from "antd";
import service from "services/apiServices";
import config from 'config';
import { ParticipantView } from "components/participants";
import { StoryView } from "components/stories";
import { PartView } from "components/parts";
import NotesView from './components/notesView';
import AttsView from './components/attsView';
import { EditHistory, DateInfo, TextInfo, Info, Spinner, ParticipantInfo, StoryInfo, ShowImage, Condition } from "common";
import ViewImage from "common/ViewImage";
import Restrictions from "common/restrictions";

const styles = {
  card: {
    border: '1px solid black',
    marginBottom: '12px'
  },
  info: {
    border: '1px solid #AA9024',
    marginBottom: 6,
    elevation: 2,
    height: '180px'
  },
  list: {
    border: '1px solid black',
    marginBottom: '12px',
    maxHeight: '250px',
    overflow: 'hidden'
  },
  photo: {
    padding: 0,
    cursor: 'pointer',
    overflow: 'hidden'
  }
}

const ObjectView = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [object, setObject] = useState({});
  const [showPariticipantDetailView, setShowPariticipantDetailView] = useState(false);
  const [participantId, setParticipantId] = useState(0);
  const [showStoryDetailView, setShowStoryDetailView] = useState(false);
  const [storyId, setStoryId] = useState(0);
  const [showPartDetailView, setShowPartDetailView] = useState(false);
  const [selectedPartId, setSelectedPartId] = useState({});
  const [image, setImage] = useState({ show: false, id: 0, url: '', alt: '', type: 'X' });
  const [view, setView] = useState({ show: false, id: 0, type: 'X' });

  const getObject = useCallback(async (id) => {
    try {
      const result = await service.read("pieces", id);
      setObject({ ...result });
      setLoading(false);
    } catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experienciing some network related issues. Please try again in a minute."
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id && parseInt(id)) {
      setLoading(true);
      getObject(id);
    }
  }, [id, getObject]);

  const cardTitle = (name, id) => (
    <div style={{ fontSize: '1.4em', fontWeight: '400' }}>
      <div style={{ float: 'left' }}>{name}</div>
      <div style={{ float: 'right' }}>{id}</div>
      <Divider />
    </div>
  )


  const conditionTitle = (report, count) => {
    const ShowReport = () => {
      if (!report) return <div className="no-report">NO</div>;
      if (report && !count) return <div className="req-report">YES</div>
      return <div className="yes-report">YES</div>
    }
    return (
      <Row style={{ fontSize: '1.4em', fontWeight: '400' }}>
        <Col span={16}>Object Condition</Col>
        <Col span={8}>Condition Reports <ShowReport /></Col>
        <Divider />
      </Row>);
  }

  if (!id || loading) return <Spinner />;
  return (
    <>
      {!!object.restrictions && <Restrictions />}
      <Card style={styles.card}>
        {object.study && config.currentUser.role !== "Intern" && (
          <div style={{ backgroundColor: '#eeeaea', padding: '4px' }}>
            {cardTitle('Study Collection', '')}
            <Row>
              <Col span={12}><Info label='Object Contributor' value={object.objectContributor} /></Col>
              <Col span={12}><Info label='Contributor Contact Information' value={object.contributorContact} /></Col>
            </Row>
            <p>&nbsp;</p>
            <hr />
          </div>
        )}

        {cardTitle(object.name, 'Id: ' + object.objectId)}
        <EditHistory lastUpdate={object.lastUpdate} editHistory={object.editHistory} />
        <p>{object.description}</p>
        <div className='right' style={styles.photo}>
          {object.lightPhoto !== 'noimg' &&
            <img style={{ border: '1px black solid', maxHeight: '150px' }}
              src={`${config.imagePath}${object.lightPhoto}.jpg`} alt={object.lightPhoto}
              onClick={() => {
                setView({
                  show: true,
                  type: 'light',
                  id: object.id
                });
              }} />}
          {object.darkPhoto !== 'noimg' &&
            <img style={{ border: '1px black solid', maxHeight: '150px', marginLeft: '12px' }}
              src={`${config.imagePath}${object.darkPhoto}.jpg`} alt={object.darkPhoto}
              onClick={() => {
                setView({
                  show: true,
                  type: 'dark',
                  id: object.id
                });
              }} />}
        </div>
      </Card>

      <Card style={styles.card}>
        <Row>
          <Col span={6}>
            <ParticipantInfo
              id={object.participant.id}
              name={object.participant.name}
              show={config.currentUser.role !== 'Intern'}
              onClick={() => {
                setShowPariticipantDetailView(true);
                setParticipantId(object.participant.id);
              }} />
          </Col>
          <Col span={6}>
            <StoryInfo
              id={object.story.id}
              title={object.story.name}
              show={config.currentUser.role !== 'Intern'}
              onClick={() => {
                setShowStoryDetailView(true);
                setStoryId(object.story.id);
              }} />
          </Col>
          <Col span={6}><Info label='Object Type' value={object.objectType} /></Col>
          <Col span={6}>
            <DateInfo label='Accession Date' value={object.accessionDate} />
            {!!object.ownedBy && <div><b>Owned by {object.ownedBy}</b></div>}
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col span={6}>
            <Info label='Current Legal Status' value={`${object.legalStatus}`} />
            {object.legalStatus === "Deaccessioned" && <div>{object.reason}</div>}
          </Col>
          {!object.part && <Col span={12}>
            <Info label='Storage Location' tooltip={object.tooltip}
              value={object.storageCode ? object.storageCode : object.storage} />
          </Col>}
          <Col span={6}><Info label='Quantity' value={object.quantity} /></Col>
        </Row>
        <Divider />
        <Row>
          <Col span={6}><Info label='Material' value={object.material} /></Col>
          <Col span={6}><Info label='Handling Requirements' value={object.handlingRequirements} /></Col>
          <Col span={6}><Info label='Study Collection' value={object.study} /></Col>
          <Col span={6}><Info label='Mount' value={object.standLocation} /></Col>
        </Row>
      </Card>

      {object.loan &&
        <Card style={styles.card}>
          {cardTitle(`${object.loan.type} Loan Information`, `Current status: ${object.loan.status}`)}
          <Row>
            <Col span={12}><Info label='Institution/Organization' value={object.loan.institution} /></Col>
            <Col span={12}><Info label='Contact Person' value={object.loan.contactPerson} /></Col>
          </Row>
          <hr />
          <Row>
            <Col span={7}>
              <Card style={styles.info}>
                <h3>LOAN</h3>
                <DateInfo label='Date' value={object.loan.loanDate} />
                <TextInfo label='Period' value={object.loan.loanPeriod} />
                <DateInfo label='Return' value={object.loan.returnDate} />
              </Card>
            </Col>
            <Col span={7}>
              <Card style={styles.info}>
                <h3>EXTENSION</h3>
                <DateInfo label='Date' value={object.loan.extensionDate} />
                <TextInfo label='Period' value={object.loan.extensionPeriod} />
                <DateInfo label='Return' value={object.loan.extensionReturnDate} />
              </Card>
            </Col>
            <Col span={10}>
              <Card style={styles.info}>
                <h3>SHIPPING</h3>
                <DateInfo label='Date' value={object.loan.shippingDate} />
                <TextInfo label='Shipper' value={object.loan.shipper} />
                <DateInfo label='Return Date' value={object.loan.returnShippingDate} />
                <TextInfo label='Return Shipper' value={object.loan.returnShipper} />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Info label='Mount Storage' value={object.loan.mountStorage} />
            </Col>
            <Col span={7}>
              <Info label='Note' value={object.loan.note} />
            </Col>
            <Col span={10}>
              <Info label='Way of Transport' value={object.loan.wayOfTransport} />
            </Col>
          </Row>
        </Card>
      }

      {object.parts.length > 0 && (
        <Card style={styles.card}>
          {cardTitle(`Parts [${object.parts.length}]`, '')}
          <List
            size="small" grid={{ gutter: 16, column: 4 }}
            dataSource={object.parts}
            renderItem={(item) => (
              <List.Item>
                <Card bodyStyle={{ cursor: "pointer" }} style={styles.list}
                  onClick={() => {
                    setShowPartDetailView(true);
                    setSelectedPartId(item.id);
                  }}
                  width={'50%'}>
                  {item.partId}<hr />
                  {(item.photo !== 'noimg') && <img src={`${config.imagePath}${item.photo}.jpg`} alt={item.photo} />}
                  {item.name}
                </Card>
              </List.Item>
            )} />
        </Card>
      )}

      {object.photos.length > 0 && (
        <Card style={styles.card}>
          {cardTitle('Photos', object.photos.length)}
          <List
            size="small" grid={{ gutter: 16, column: 4 }}
            dataSource={object.photos}
            renderItem={(item) => (
              <List.Item>
                <div style={styles.photo}
                  onClick={() => {
                    setImage({ id: item.id, type: 'photo', show: true, url: item.url });
                  }}
                  width={'50%'}>
                  <img style={{ border: '1px black solid' }} src={`${config.imagePath}/${item.url}.jpg`} alt={item.url} />
                </div>
              </List.Item>)}>
          </List>
        </Card>
      )}
      {image.show && <ShowImage id={image.id} url={image.url} type={image.type} alt={image.alt} visible={image.show} onCancel={() => setImage({ show: false, url: '', alt: '' })} />}
      {view.show && <ViewImage id={view.id} type={view.type} visible={view.show} onCancel={() => setView({ show: false, id: 0 })} />}

      {!object.part && <Card style={styles.card}>
        {conditionTitle(object.conditionReport, object.reports.length)}
        <Row>
          <Col span={14} style={{ borderRight: '1px solid gray' }}>
            <Row>
              <Col span={6}><Info label='Handmade' value={object.handmade.toString()} /></Col>
              <Col span={6}><Info label='Broken' value={object.broken.toString()} /></Col>
              <Col span={6}><Info label='Conservation' value={object.conservation.toString()} /></Col>
              <Col span={6}><Info label='Shape' value={object.shape} /></Col>
            </Row>
            <Divider />
            <Row>
              <Col span={6}><Info label='Length' value={object.length} /></Col>
              <Col span={6}><Info label='Width' value={object.width} /></Col>
              <Col span={6}><Info label='Height' value={object.height} /></Col>
              <Col span={6}><Info label='Other' value={object.other} /></Col>
            </Row>
          </Col>
          <Col span={10}>
            <Condition reports={object.reports} />
          </Col>
        </Row>
        {/* <Col span={12}><Info label='Prior use and storage' value={object.useAndStorage} /></Col>
          <Col span={12}><Info label='Exhibition history' value={object.exhibitionHistory} /></Col> */}
      </Card >
      }

      {
        object.notes.length > 0 && (
          <Card style={styles.card}>
            {cardTitle('Notes', '')}
            <NotesView data={object.notes} />
          </Card>
        )
      }

      {
        object.attachments.length > 0 && (
          <Card style={styles.card}>
            {cardTitle('Attachments', '')}
            <AttsView data={object.attachments} />
          </Card>
        )
      }

      {/* {object.loans.length > 0 && (
        <Card style={styles.card}>
          {cardTitle('Loans', '')}
          <LoansView data={object.loans} />
        </Card>
      )} */}

      <Drawer title={<h2>Contributor Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowPariticipantDetailView(false)}
        open={showPariticipantDetailView}>
        <ParticipantView id={participantId} />
      </Drawer>

      <Drawer title={<h2>Story Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowStoryDetailView(false)}
        open={showStoryDetailView}>
        <StoryView id={storyId} />
      </Drawer>

      <Drawer title={<h2>Part Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowPartDetailView(false)}
        open={showPartDetailView}>
        <PartView id={selectedPartId} />
      </Drawer>
    </>
  );
};

export default ObjectView;
