import React, { useState, useEffect, useCallback } from "react";
import { Table, Layout, Drawer, Modal, Button, message } from "antd";

import service from "services/apiServices";
import { Filter } from "common";
import { isAdmin } from "services/authServices";
import config from "config";
import columns from "./columns";
import StoryView from "./storyView";
import AudioFilter from "./AudioFilter";

const { Content } = Layout;

const Stories = ({ history, participant, canEdit, dataSource }) => {

  if (config.currentUser.role === "Intern" && config.currentUser.id !== 76) history.push('/home');

  canEdit = isAdmin();
  const [ids, setIds] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);
  const [selectedId, setId] = useState(0);
  const [prev, setPrev] = useState({ id: 0, index: -1 });
  const [next, setNext] = useState({ id: 0, index: -1 });
  const [currentPage, setCurrentPage] = useState(config.headers.page);
  const [modal, setModal] = useState(false);

  const onChange = o => {
    config.headers.page = o.current;
    setCurrentPage(config.headers.page);
  };

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      if (participant) {
        config.filter = '';
        const result = await service.list(`participants/${participant}/stories`);
        setData(result);
      }
      else if (dataSource) {
        setData(dataSource);
      } else {
        if (config.filter === '' && config.stories.length !== 0) {
          setData(config.stories);
        } else {
          const result = await service.list('stories');
          setData(result);
        }
      }
      setIsLoading(false);
      setCurrentPage(config.headers.page);
    }
    catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [participant, dataSource]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const refreshPage = () => fetch();

  const audioFilter = () => {
    setIds(data.map(({ id }) => id));
    setModal(true);
  }

  const TableTitle = () => (
    <>
        <h2 className="left">Stories / {data.length}</h2>
      <div className="right" style={{marginTop:'16px'}}>
        <Button type='primary' htmlType="button"
          className='filterButton'
          onClick={audioFilter}
          style={{ margin: '0 12px', height: 36, width: 164 }}>
          Filter for audio files
        </Button>
      </div>
      <div className="right">
        {!participant && !dataSource && <Filter onChange={refreshPage} title='Stories' />}
      </div>
      <div style={{clear:"both"}} />
    </>
  );

  const setView = (id, index) => {
    if (index < 10) index = 10 * (currentPage - 1) + index;
    setId(id);
    if (index > 0) {
      setPrev({ id: data[index - 1].id, index: index - 1 });
    } else {
      setPrev({ id: 0, index: -1 });
    }
    if (index < data.length - 1) {
      setNext({ id: data[index + 1].id, index: index + 1 });
    } else {
      setNext({ id: 0, index: -1 });
    }
    setShowDetailView(true);
  }

  const navstyle = {
    float: 'right',
    marginRight: '24px',
    marginTop: '-18px'
  }

  const DrawerTitle = () => {
    return (
      <div>
        <h3>Story Detail View</h3>
        <Button style={navstyle} type='primary' size='small' disabled={!next.id} onClick={() => setView(next.id, next.index)}>NEXT</Button>
        <Button style={navstyle} type='primary' size='small' disabled={!prev.id} onClick={() => setView(prev.id, prev.index)}>PREV</Button>
      </div>
    )
  }

  return (
    <Layout className="container">
      <Content>
        <Table
          loading={isLoading}
          title={TableTitle}
          size="small"
          tableLayout={"fixed"}
          scroll={{ y: '59vh' }}
          columns={columns({
            canEdit: canEdit,
            editUrl: 'stories',
          })}
          dataSource={data}
          onChange={onChange}
          onRow={(record, index) => ({ onClick: () => { setView(record.id, index); } })}
          pagination={{
            current: currentPage,
            size: 'small',
            hideOnSinglePage: true,
            showSizeChanger: true,
            defaultPageSize: 100,
            pageSizeOptions: ['100', '200', '500'],
            showQuickJumper: true
          }}
          rowKey={row => row.id} />
      </Content>

      <Drawer
        title={<DrawerTitle />}
        width={'60%'}
        placement="right"
        onClose={() => setShowDetailView(false)}
        destroyOnClose={true}
        open={showDetailView}>
        <StoryView id={selectedId} />
      </Drawer>

      <Modal width={'92%'}
        title="FILTER FOR AUDIO FILES"
        open={modal} footer={[]}
        onCancel={() => setModal(false)}
        style={{ top: 20 }}
        destroyOnClose={true}>
        <AudioFilter data={ids} />
      </Modal>

    </Layout>
  );
};

export default Stories;
