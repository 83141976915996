import React from "react";
import { Table, Layout } from "antd";
import config from "config";

const columns = [
  { key: "type", dataIndex: "type", title: "Type", width: '20%' },
  { key: "url", dataIndex: "url", title: "Address", width: '30%' },
  { key: "comment", dataIndex: "comment", title: "Comment", width: '50%' }
];

const { Content } = Layout;

const AttsView = ({ data }) => {

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={false}
            size="small"
            tableLayout={"fixed"}
            columns={columns}
            dataSource={data}
            onRow={(record) => ({ onClick: () => { window.open(config.documentPath + record.url, "_blank"); } })}        
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
    </div>
  );
};

export default AttsView;





//const item = _.find(data, { id: id });
//window.open(item.url, "_blank")
