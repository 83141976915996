import React, { useEffect } from "react";
import { Layout, Tabs } from "antd";
import ObjectReport from "./ObjectReport";
import ObjectCorrect from "./ObjectCorrect";
import PartReport from "./PartReport";
import config from "config";

const tabItems = [
  { key: '1', label: <h2>Objects</h2>, children: <ObjectReport /> },
  { key: '2', label: <h2>Objects Correction</h2>, children: <ObjectCorrect /> },
  { key: '3', label: <h2>Parts</h2>, children: <PartReport /> }
];

const Dashboard = () => {
  const [activeKey, setActiveKey] = React.useState('1');

  useEffect(() => {
    if(config.activeKey) setActiveKey(config.activeKey);
  }, []);

  return (
    <Layout style={layoutStyle}>
      <Tabs items={tabItems} defaultActiveKey={activeKey} />
    </Layout>
  )
};

export default Dashboard;

const layoutStyle = {
  width: '100%',
  textAlign: 'left',
  padding: '0 24px',
  margin: '12px 0'
};