import React from 'react';
import { Button, Tooltip } from "antd";
import { InfoOutlined } from "@ant-design/icons";
import services from 'services/apiServices';

const InfoButton = ({ id, onClick }) => (
  <Tooltip title="Contributor details">
    &nbsp;<Button className='infoButton'
      shape="circle"
      size="small"
      icon={<InfoOutlined />}
      onClick={onClick}
    />
  </Tooltip>
);

const ParticipantInfo = ({ id, name, show, onClick }) => (
  <div>
    <b>Contributor</b>
    {show && id !== 0 && (
      <span>
        <InfoButton id={id} onClick={onClick} /><br />
        <span style={{ fontSize: '1.1em' }}>{services.toFormat(id)}: {name}</span>
      </span>
    )}
    {!show && <div style={{ fontSize: '1.1em' }}>{services.toFormat(id)}</div>}
  </div>
);

export default ParticipantInfo;