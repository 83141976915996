import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Radio, Switch, Layout, Divider, message, Card, Row, Col, Button } from "antd";
import { Spinner } from "common";
import labels from "assets/labels/participant.json";
import service from 'services/apiServices';

const { Content } = Layout;

const styles = {
  cardItem: {
    border: '1px solid black',
    marginBottom: '12px'
  },
  divider: {
    margin: '6px 0'
  }
}

const OralHistory = ({ id }) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const getOral = useCallback(async (id) => {
    try {
      const result = await service.read("oralhistory", id);
      form.setFieldsValue(result);
      setLoading(false);
    } catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experienciing some network related issues. Please try again in a minute.",
      });
      setLoading(false);
    }
  }, [form]);

  useEffect(() => {
    getOral(id);
  }, [id, getOral]);

  const onFinish = async (values) => {
    const data = {...values, participant: {id: id}};
    data.transcribed = data.transcriber !== '';
    data.translated = data.traslator !== '';
    data.editedBHS = data.editorBHS !== '';
    data.editedEng = data.editorEng !== '';
    data.tagged = data.tags !== '';
    const result = data.id === 0 ? await service.insert('oralhistory', data) : await service.update('oralhistory', data, data.id);
    if (result) {
      message.success("Oral history successfully updated in the database.");
    }
  }

  if (loading) return <Spinner />;
  return (
    <Layout className="container">
      <Content align="center">
        <Card style={styles.cardItem}>
          <Form form={form} layout='vertical' size='small' onFinish={onFinish}>
            <Form.Item label={labels.ID} name="id" hidden><Input className="oralFormField" /></Form.Item>
            <Row>
              <Col span={7}>
                <Form.Item label={labels.MEDIA_TYPE} name='mediaType' style={{ textAlign: 'left' }}>
                  <Radio.Group buttonStyle='solid'>
                    <Radio.Button value='Audio'>Audio</Radio.Button>
                    <Radio.Button value='Video'>Video</Radio.Button>
                    <Radio.Button value='Other'>Other</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Divider style={styles.divider} />
                <Form.Item label={labels.INTERVIEWER} name="interviewer"><Input className="oralFormField" /></Form.Item>
                <Divider style={styles.divider} />
                <Form.Item label={labels.LOCATION} name="location"><Input className="oralFormField" /></Form.Item>
                <Divider style={styles.divider} />
                <Form.Item label={labels.NATIVE_LANGUAGE} name="nativeLanguage"><Input className="oralFormField" /></Form.Item>
                <Divider style={styles.divider} />
                <Form.Item label={labels.LENGTH} name="length"><Input className="oralFormField" /></Form.Item>
              </Col>
              <Col span={1}>
                <Divider type='vertical' style={{ backgroundColor: 'black', border: '1px solid black', height: '440px', margin: '0 12px' }} />
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={6}>
                    <Form.Item style={{ textAlign: 'left' }} label={labels.TRANSCRIBED} name="transcribed" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item style={{ textAlign: 'left' }} label={labels.TRANSLATED} name="translated" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item style={{ textAlign: 'left' }} label={labels.EDITED_BHS} name="editedBHS" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item style={{ textAlign: 'left' }} label={labels.EDITED_ENG} name="editedEng" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item style={{ textAlign: 'left' }} label={labels.TAGGED} name="tagged" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                  </Col>
                  <Col span={18}>
                    <Form.Item label={labels.TRANSCRIBER} name="transcriber"><Input className="oralFormField" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item label={labels.TRANSLATOR} name="translator"><Input className="oralFormField" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item label={labels.EDITOR_BHS} name="editorBHS"><Input className="oralFormField" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item label={labels.EDITOR_ENG} name="editorEng"><Input className="oralFormField" /></Form.Item>
                    <Divider style={styles.divider} />
                    <Form.Item label={labels.TAGS} name="tags"><Input className="oralFormField" /></Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={1}>
                <Divider type='vertical' style={{ backgroundColor: 'black', border: '1px solid black', height: '440px', margin: '0 12px' }} />
              </Col>
              <Col span={3}>
                <Form.Item label={labels.PAGES} name="pages"><Input className="oralFormField" /></Form.Item>
                <Divider style={styles.divider} />
                <Form.Item style={{ textAlign: 'left' }} label={labels.SENT} name="sent" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                <Divider style={styles.divider} />
                <Form.Item style={{ textAlign: 'left' }} label={labels.RECORDING} name="recording" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                <Divider style={styles.divider} />
                <Form.Item style={{ textAlign: 'left' }} label={labels.EDITED_VIDEO} name="editedVideo" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
                <Divider style={styles.divider} />
                <Form.Item style={{ textAlign: 'left' }} label={labels.SUBTITLES} name="subtitles" valuePropName="checked"><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              </Col>
            </Row>
            <Divider style={{ backgroundColor: 'black', border: '1px solid black', margin: '0 0 24px 0' }} />
            <Row>
              <Col span={7}><Form.Item label={labels.TRANSCRIPT_URL} name="transcriptUrl"><Input className="oralFormField" /></Form.Item></Col>
              <Col span={1}></Col>
              <Col span={7}><Form.Item label={labels.MEDIA_URL} name="mediaUrl"><Input className="oralFormField" /></Form.Item></Col>
              <Col span={1}></Col>
              <Col span={7}><Form.Item label={labels.NOTES} name="notes"><Input className="oralFormField" /></Form.Item></Col>
            </Row>
            <Row>
              <Col span={7}><Form.Item label={labels.TRANS_STORAGE} name="transStorage"><Input className="oralFormField" /></Form.Item></Col>
              <Col span={1}></Col>
              <Col span={7}><Form.Item label={labels.TEST_STORAGE} name="testStorage"><Input className="oralFormField" /></Form.Item></Col>
              <Col span={1}></Col>
              <Col span={7}><Button className='tableButton' htmlType='submit' style={{ width: '100%', height: '36px', marginTop: '24px' }}>S A V E</Button></Col>
            </Row>
          </Form>
        </Card>
      </Content>
    </Layout >
  );
}

export default OralHistory;
