import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, SingleField } from "../fields";
import { Curtain } from "common";

const Page30 = () => {
  const page = 30;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = [
    "6.01",
    "6.02",
    "6.021",
    "6.03",
    "6.04",
    "6.05",
    "6.051",
    "6.06",
    "6.07",
    "6.071",
    "6.08",
    "6.09",
    "6.091",
    "6.10",
    "6.11",
    "6.12",
    "6.121",
    "6.13",
    "6.14",
    "6.15",
    "6.16",
    "6.17",
    "6.171",
    "6.18",
  ];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.respondent);

  const [v602, setV602] = useState("No");
  const [v605, setV605] = useState("No");
  const [v607, setV607] = useState("No");
  const [v609, setV609] = useState("No");
  const [v612, setV612] = useState("No");
  const [v617, setV617] = useState("No");

  // variables for specific questions (conditional)

  // fetch data
  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV602(result.answers[1].choice);
      setV605(result.answers[5].choice);
      setV607(result.answers[9].choice);
      setV609(result.answers[11].choice);
      setV612(result.answers[15].choice);
      setV617(result.answers[21].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, []);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the entire period of armed conflict.
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <SingleField
            form={form}
            label="1. Do you remember members of international organizations/peacekeepers/religious associations?"
            question="c6.01"
            options={["Yes", "No"]}
          />
          <CondField
            form={form}
            label="2. Did you participate in any of their activities/workshops?"
            question="c6.02"
            trigger="Yes"
            current={v602}
            options={["Yes", "No"]}
            subQuestion="c6.021"
            subLabel="2.1 If yes, which activities?"
          />
          <SingleField
            form={form}
            label="3. Were you in direct contact with infantry weapons/explosive devices?"
            question="c6.03"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="4. Did you ever encounter any landmines?"
            question="c6.04"
            options={["Yes", "No"]}
          />

          <CondField
            form={form}
            label="5. Did you have first-hand experience with bombing or shelling?"
            question="c6.05"
            trigger="Yes"
            current={v605}
            options={["Yes", "No"]}
            subQuestion="c6.051"
            subLabel="5.1 If yes, how many times?"
          />
          <SingleField
            form={form}
            label="6. At what range?"
            question="c6.06"
            options={[
              "10 m (10 steps)",
              "20 m (20 steps)",
              "30 m (over 30 steps)",
              "I don’t remember",
            ]}
          />
          <CondField
            form={form}
            label="7. Was someone killed or wounded on that occasion?"
            question="c6.07"
            trigger="Yes"
            current={v607}
            options={["Yes", "No"]}
            subQuestion="c6.071"
            subLabel="7.1 If yes, how many persons?"
          />
          <SingleField
            form={form}
            label="8. Did you ever witness someone being wounded or killed by sniper fire?"
            question="c6.08"
            options={["Yes", "No"]}
          />

          <CondField
            form={form}
            label="9. Were you ever wounded?"
            question="c6.09"
            trigger="Yes"
            current={v609}
            options={["Yes", "No"]}
            subQuestion="c6.091"
            subLabel="9.1 If yes, how many times?"
          />
          <SingleField
            form={form}
            label="10.	Were you detained during armed conflict?"
            question="c6.10"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="11. Were you subjected to any form of sexual or gender-based violence during armed conflict?"
            question="c6.11"
            options={["Yes", "No"]}
          />
          <CondField
            form={form}
            label="12. Did you lose a member of your family during armed conflict?"
            question="c6.12"
            trigger="Yes"
            current={v612}
            options={["Yes", "No"]}
            subQuestion="c6.121"
            subLabel="12.1 If yes, who?"
          />
          <SingleField
            form={form}
            label="13. Did you lose a close friend during armed conflict?"
            question="c6.13"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="14. Did you lose a boyfriend/girlfriend during armed conflict?"
            question="c6.14"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="15. Were you separated from close family during armed conflict?"
            question="c6.15"
            options={["Yes", "No"]}
          />
          <SingleField
            form={form}
            label="16. Did you have parents at the start of armed conflict?"
            question="c6.16"
            options={[
              "Yes, I had both",
              "Yes, I had a mom",
              "Yes, I had a dad",
              "I didn’t have parents when the armed conflict started",
            ]}
          />
          <CondField
            form={form}
            label="17. Did you have any siblings at the start of the armed conflict?"
            question="c6.17"
            trigger="Yes"
            current={v617}
            options={["Yes", "No"]} // nije zavrseno
            subQuestion="c6.171"
            subLabel="17.1 If yes, how many?"
          />
          <SingleField
            form={form}
            label="18. Do you remember the end of the armed conflict?"
            question="c6.18"
            options={["Yes", "No"]}
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page30;
