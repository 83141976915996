import React, { useState, useEffect } from "react";
import { Table, Layout } from "antd";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

import TableHead from "services/TableHead";
import service from "services/apiServices";
import { isSuper } from "services/authServices";
import config from "config";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'move', color: '#666' }} />
));

const Classes = ({ history }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async () => {
    setIsLoading(true);
    config.tagType = 1;
    config.tagDesc = 'Classification';
    const result = await service.list(`tags/1`);
    if (result) setData(result.list);
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  const TableTitle = () => <h2>Story Classification</h2>;

  const colProps = [
    { title: '', dataIndex: 'sort', width: '5%', className: 'drag-visible', render: () => config.currentUser.role.includes('SuperAdmin') ? <DragHandle /> : <div /> },
    { key: "value", title: "Classification", width: '20%' },
    { key: "description", title: "Description", width: '60%', ellipsis: false },
  ];

  const columns = () =>
    TableHead(
      colProps,
      isSuper(),
      `classes/1`,
      true // can add new
    );

  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);

  const onSortEnd = async ({ newIndex, oldIndex }) => {
    if (oldIndex !== newIndex) {
      const dataSource = data;
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      setIsLoading(true);
      const result = await service.insert('tags/order', newData);
      setIsLoading(false);
      setData(result);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll={false}
      useWindowAsScrollContainer
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = data;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Layout className="container">
      <Table style={{ width: '75%', margin: '0 auto' }}
        loading={isLoading}
        title={TableTitle}
        size="small"
        columns={columns()}
        dataSource={data}
        pagination={false}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          }
        }}
      />
    </Layout>
  );
};

export default Classes;
