import React from 'react';
import { UserOutlined, ReadOutlined, ShoppingOutlined, CustomerServiceOutlined } from '@ant-design/icons';

const iconStyle = {
  color: '#1890ff'
}

const Conflict = ({ data }) => {

  const InfoGraph = ({ icon, number, text, single }) => {
    const disp = (number === 1) ? single : text;
    return <div>{icon} {number} {disp}</div>
  }

  return (
    <div>
      <InfoGraph icon={<UserOutlined style={iconStyle} />} number={data.participants} text='contributors' single='contributor' />
      <InfoGraph icon={<ReadOutlined style={iconStyle} />} number={data.stories} text='stories' single='story' />
      <InfoGraph icon={<ShoppingOutlined style={iconStyle} />} number={data.pieces} text='objects' single='object' />
      { data.orals !== "0m" &&
        <InfoGraph icon={<CustomerServiceOutlined style={iconStyle} />} number={data.orals} text='' single='' />
      }
    </div>
  );
}

export default Conflict;