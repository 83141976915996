import React, { useState, useCallback, useEffect } from "react";
import { Drawer, Divider, message, List, Card, Row, Col } from "antd";
import service from "services/apiServices";
import moment from 'moment';
import { WarningOutlined } from '@ant-design/icons'
import { StoryView } from "components/stories";
import config from "config";
import { Spinner, Info } from "common";

const styles = {
  cardItem: {
    border: '1px solid black',
    marginBottom: '12px'
  },
  listItem: {
    border: '1px solid black',
    marginBottom: '12px',
    overflow: 'hidden',
    minHeight: 200,
    maxHeight: 200
  },
  divider: {
    margin: '6px 0'
  }
}

const ExhibitionView = ({ id }) => {

  const [loading, setLoading] = useState(true);
  const [exhibition, setExhibition] = useState({});
  const [showStory, setShowStory] = useState(false);
  const [storyId, setStoryId] = useState(0);

  const getExhibition = useCallback(async (exhibitionId) => {
    try {
      const result = await service.read("exhibitions", exhibitionId);
      setExhibition(result);
    }
    catch (error) {
      message.error({
        title: "Network problem",
        message: "We are experiencing some network related issues. Please try again in a minute.",
      });
    }
    finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getExhibition(id);
  }, [getExhibition, id]);

  const cardTitle = (name, id) => (
    <div style={{ fontSize: '1.4em', fontWeight: '400' }}>
      <div style={{ float: 'left' }}>{name}</div>
      <div style={{ float: 'right' }}>{id}</div>
      <Divider className='divider' />
    </div>
  )

  // const smallFont = history => <span style={{ fontSize: '0.7em' }}>{history}</span>;

  const StoryItem = ({ item, onClick }) => {
    const title = item.name === '' ? <WarningOutlined style={{ fontSize: '1.5em', color: 'red' }} /> : item.name;
    const id = parseInt(item.id);
    return (
      <List.Item>
        <Card bodyStyle={{ cursor: "pointer" }} style={styles.listItem}
          onClick={onClick}
          width={'50%'}>
          {/* <div className='left'>{service.toFormat(item.id)}</div> */}
          <div className='left'>{title}</div>
          <div style={{ clear: 'both' }} />
          <hr />
          {id !== 0 && <div>
            <div className='left'>{item.desc}</div>
            <div className='ex-image'><img src={config.imagePath + item.image} alt={item.image} /></div>
          </div>}
        </Card>
      </List.Item>
    );
  };

  // const ObjectItem = ({ item, onClick }) => {
  //   return (
  //     <List.Item>
  //       <Card bodyStyle={{ cursor: "pointer" }} style={styles.listItem}
  //         onClick={onClick}
  //         width={'50%'}>
  //         {service.toFormat(item.id)}<hr />{item.name}
  //       </Card>
  //     </List.Item>
  //   );
  // };

  const fullDate = date => {
    return moment(date).year() !== 1 ? moment(date).format("YYYY-MM-DD") : '';
  }

  return (
    <>
      {loading && <Spinner />}
      <Card style={styles.cardItem}>
        {cardTitle(exhibition.title, 'Id: ' + exhibition.id)}
        <div style={{ textAlign: 'right', marginTop: '-24px', marginBottom: '12px', fontSize: '0.9em' }}>{exhibition.editHistory}</div>
        <Row>
          <Col span={6}><Info label='Type' value={exhibition.type} /></Col>
          <Col span={6}><Info label='Venue' value={exhibition.venue} /></Col>
          <Col span={6}><Info label='Opening Date' value={fullDate(exhibition.openingDate)} /></Col>
          <Col span={6}><Info label='Closing Date' value={fullDate(exhibition.closingDate)} /></Col>
        </Row>
        {/* <Divider style={styles.divider} />
         <Row>
          <Col span={6}><Info label={labels.CONFLICT} value={participant.conflict} /></Col>
          <Col span={6}><Info label={labels.RESEARCH_CELL} value={participant.researchCell} /></Col>
          <Col span={6}><Info label={labels.INSTITUTION} value={participant.institution} /></Col>
          <Col span={6}><Info label={labels.PARTICIPATION_DATE} value={moment(participant.participationDate).format("DD/MM/YYYY")} /></Col>
        </Row>
        <Divider style={styles.divider} />
        <Row>
          <Col span={6}><Info label={labels.PHONE} value={participant.phone} /></Col>
          <Col span={6}><Info label={labels.EMAIL} value={participant.email} /></Col>
          <Col span={6}><Info label={labels.STATEMENT_ID} value={participant.statementId} /></Col>
          {participant.legalGuardian && (<Col span={6}><Info label={labels.LEGAL_GUARDIAN} value={participant.legalGuardian} /></Col>)}
        </Row>
        <Row>
          <Divider style={styles.divider} />
          <Info label={labels.NOTES} value={participant.notes} />
        </Row> */}

      </Card>

      {exhibition.exhibitionStories && exhibition.exhibitionStories.length !== 0 && (
        <Card style={styles.cardItem}>
          {cardTitle(`Stories (${exhibition.exhibitionStories.length})`, ' ', '')}
          <List
            size="small"
            grid={{ gutter: 2, column: 4 }}
            dataSource={exhibition.exhibitionStories}
            renderItem={(item) => <StoryItem item={item.story}
              onClick={() => {
                if (item.story.id !== 0) {
                  setShowStory(true);
                  setStoryId(item.story.id);
                }
              }}
            />}
          />
        </Card>
      )}

      {/* {participant.pieces.length !== 0 && (
        <Card style={styles.cardItem}>
          {cardTitle('Objects', '', '')}
          <List
            size="small"
            grid={{ gutter: 16, column: 4 }}
            dataSource={participant.pieces}
            renderItem={(item) => <ObjectItem item={item} onClick={() => { setShowObjectDetailView(true); setSelectedObjectId(item.id); }} />}
          />
        </Card>
      )} */}

      <Drawer title={<h2>Story Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowStory(false)}
        destroyOnClose={true}
        open={showStory}>
        <StoryView id={storyId} />
      </Drawer>

      {/* <Drawer title={<h2>Object Detail View</h2>}
        width={'60%'}
        closable={false}
        onClose={() => setShowObjectDetailView(false)}
        destroyOnClose={true}
        open={showObjectDetailView}>
        <ObjectView id={selectedObjectId} />
      </Drawer> */}
    </>
  );
};

export default ExhibitionView;
