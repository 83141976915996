import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { CondField, SingleField, TextField } from "../fields";
import { Curtain } from "common";

const Page24 = () => {
  const page = 24;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["5.05", "5.06", "5.07", "5.071", "5.08"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const [v507, setV507] = useState("No");
  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV507(result.answers[2].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };

  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    // collect data from form fields question id (3.42) primary answer (c3.42) and extension (e3.42)
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
        {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <TextField
            form={form}
            label="5. For how long were you and your family looking for accommodation?"
            question="c5.05"
          />
          <TextField
            form={form}
            label="6.	Where were you staying meanwhile?"
            question="c5.06"
          />
          <CondField
            form={form}
            label="7. Did you take any personal belongings with you from home?"
            question="c5.07"
            trigger="Yes"
            current={v507}
            options={["Yes", "No"]}
            subQuestion="c5.071"
            subLabel="7.1 If yes, what?"
          />

          <SingleField
            form={form}
            label="8. Please describe your new housing situation:"
            question="c5.08"
            extension="e5.08"
            options={[
              "Private house",
              "Apartment",
              "Office space",
              "Collective housing",
              "Refugee camp",
              "Military camp",
            ]}
            other="Other:"
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page24;
