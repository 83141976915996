import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { setPage } from "store/pageSlice";

const pStyle = { color: 'black' }

const Introduction = () => {
  const dispatch = useDispatch();

  return (
    <div style={{ marginTop: "24px" }}>
      <div className="quest-card">
        <div className="quest-title">
          Dear Participant,
        </div>
        <br />
        <p style={pStyle}>We are thankful that you have decided to contribute to the War Childhood Museum's collection.</p>
        <p style={pStyle}>
          By presenting personal stories and belongings from the wartime period, the War Childhood Museum (WCM) aims to show how war affects children
          and adolescents around the world. The WCM's collection currently holds over 5.000 personal objects and hundreds of hours of oral history
          testimonies connected to 15 armed conflicts of the past and present - we believe that these and many more stories of war childhoods have to
          be told, and the questionnaire before you is the first step to do so. We would like to thank you for taking the time to fill in the
          questionnaire, as this research tool helps us better understand your personal experience, but also war-affected childhoods in general.
        </p>
        <div className="quest-title" style={{margin:'12px 0'}}>
          Instructions
        </div>
        <p style={pStyle}>
          It will take you approximately 45 minutes to complete the questionnaire. Questions with two or more options can be answered by
          choosing one or more available options. Don't worry if there are some questions that you don't know the answer to, are unsure of, or don't
          wish to disclose. You can return to those unanswered questions after you've finished with the others, or skip them entirely. However, it's
          important that when you give answers to the questions that follow you do so responsibly and honestly.{" "}
        </p>
        <p style={pStyle}>
          We kindly ask for your patience in answering each of the questions as they are of utmost importance for our research efforts. Thank you for your care and cooperation!
        </p>

        <div className="quest-title" style={{margin:'12px 0'}}>
          Data protection statement
        </div>
        <p style={pStyle}>
          All personal and other data collected by this questionnaire may be published and/or analyzed by the War Childhood Museum, except for data
          entered in response to the name and surname, phone, and email address questions. Data entered in response to the name and surname, phone and
          email address questions will be kept in a password-protected database with access by the War Childhood Museum staff and used only to verify
          data. By submitting your information, you confirm your consent for your data to be captured, stored and used in these ways.
        </p>
        <Button type="primary" className="quest-button" style={{ width: "100%" }} onClick={() => dispatch(setPage(2))}>LET'S START!</Button>
      </div>
    </div>
  );
};

export default Introduction;