import React from 'react'
import TableHead from "services/TableHead";
import { WarningOutlined } from '@ant-design/icons'
import config from 'config';

const columns = ({ canEdit, editUrl, raiseImage }) => {
  const noName = name => {
    return (name === '') ? <WarningOutlined style={{ fontSize: '1.5em', color: 'red' }} /> : name;
  }

  const thumbnail = image => <img style={{ border: '1px #999 solid' }} src={`${config.imagePath}${image}.jpg`} alt={image} />;

  const colProps = [
    { key: "image", title: "Photo", width: '6%', render: image => thumbnail(image) },
    { key: "objectId", title: "Id", width: '8%' },
    // { key: "conflict", title: "", width: '5%' },
    { key: "name", title: "Name", width: '16%', sorter: (a, b) => a.name.localeCompare(b.name), render: name => noName(name) },
    { key: "description", title: "Description", width: '16%' },
    { key: "story", title: "Story", width: '16%', sorter: (a, b) => a.story.localeCompare(b.story), render: name => noName(name) },
    { key: "participant", title: "Contributor", width: '14%', sorter: (a, b) => a.participant.localeCompare(b.participant) },
    { key: "objectType", title: "Object Type", width: '9%', sorter: (a, b) => a.objectType.localeCompare(b.objectType) }
  ];

  return TableHead(
    colProps,
    canEdit,
    editUrl
  );
}

export default columns;