import React, { useState } from 'react';
import t1 from 'assets/images/1.png';
import t2 from 'assets/images/2.png';
import t3 from 'assets/images/3.png';
import t4 from 'assets/images/4.png';
import t5 from 'assets/images/5.png';
import t6 from 'assets/images/6.png';
import t7 from 'assets/images/7.png';
import t8 from 'assets/images/8.png';
import t9 from 'assets/images/9.png';
import t10 from 'assets/images/10.png';
import { Card, Modal } from 'antd';

const Help = () => {

  const [visible, setVisible] = useState(false);
  const [video, setVideo] = useState('');
  const [title, setTitle] = useState('');

  const openVideo = (videoId, title) => {
    setVideo(videoId);
    setTitle(title);
    setVisible(true);
  }

  return (<div className='cardContainer'>
    <Card title='Change a password' className='antCard' onClick={() => openVideo('cCR0Obn0uHw', 'How to change a password')}>
      <img src={t1} alt='t1' /><p style={{ marginTop: '18px' }}>How to change a password</p>
    </Card>
    <Card title='Add new participant' className='antCard' onClick={() => openVideo('zfT_JcAvY94', 'How to add new participant')}>
      <img src={t2} alt='t2' /><p style={{ marginTop: '18px' }}>How to add new participant</p>
    </Card>
    <Card title='Edit a participant' className='antCard' onClick={() => openVideo('hAEwpdZfLDM', 'How to edit a participant')}>
      <img src={t3} alt='t3' /><p style={{ marginTop: '18px' }}>How to edit a participant</p>
    </Card>
    <Card title='Add new story' className='antCard' onClick={() => openVideo('hfsQ-k90xyQ', 'How to add new story')}>
      <img src={t4} alt='t4' /><p style={{ marginTop: '18px' }}>How to add new story</p>
    </Card>
    <Card title='Edit a story' className='antCard' onClick={() => openVideo('By0oe3cX4zk', 'How to edit a story')}>
      <img src={t5} alt='t5' /><p style={{ marginTop: '18px' }}>How to edit a story</p>
    </Card>
    <Card title='Add new object' className='antCard' onClick={() => openVideo('mjG4mvdX3Kw', 'How to add new object')}>
      <img src={t6} alt='t6' /><p style={{ marginTop: '18px' }}>How to add new object</p>
    </Card>
    <Card title='Edit an object' className='antCard' onClick={() => openVideo('NJQlaKi_N7A', 'How to edit an object')}>
      <img src={t7} alt='t7' /><p style={{ marginTop: '18px' }}>How to edit an object</p>
    </Card>
    <Card title='Participants filters' className='antCard' onClick={() => openVideo('-0Kt3zjjGkI', 'How to use advanced filters (Participants)')}>
      <img src={t8} alt='t8' /><p style={{ marginTop: '18px' }}>How to use advanced filters (Participants)</p>
    </Card>
    <Card title='Stories filters' className='antCard' onClick={() => openVideo('rfXX0qwxloo', 'How to use advanced filters (Stories)')}>
      <img src={t9} alt='t9' /><p style={{ marginTop: '18px' }}>How to use advanced filters (Stories)</p>
    </Card>
    <Card title='Objects filters' className='antCard' onClick={() => openVideo('QIxp8AV87sw','How to use advanced filters (Objects)')}>
      <img src={t10} alt='t10' /><p style={{ marginTop: '18px' }}>How to use advanced filters (Objects)</p>
    </Card>

    <Modal title={<h2>{title}</h2>} open={visible} width={'70%'} style={{top:20}} footer={[]} onCancel={() => { setVisible(false); }} destroyOnClose={true}>
      <div className='video-container'>
        <iframe title={title} width='100%' height='80vh' src={`https://www.youtube.com/embed/${video}`} />
      </div>
    </Modal>
  </div>)
}

export default Help;