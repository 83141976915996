import { createSlice } from "@reduxjs/toolkit";

const initialState = { page: 0, location: 0, place: "", direction: "" }

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage: (state, action) => { return { ...state, page: action.payload } },
    setLocation: (state, action) => { return {...state, location: action.payload} },
    setPlace: (state, action) => { return {...state, place: action.payload} },
    setDirection: (state, action) => { return {...state, direction: action.payload} },
    clearPage: () => { return { page: 0 } }
  }
});

const { reducer, actions } = pageSlice;

export const { setPage, clearPage, setLocation, setPlace, setDirection } = actions;

export default reducer;