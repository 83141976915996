import React, { useEffect } from 'react';
import { Form, Input, DatePicker, Radio, Layout, Button, Row, Col, Switch, Divider } from 'antd';
import service from 'services/apiServices';
import { Buttons, Delete } from 'common';
import moment from 'moment';

const { Content } = Layout;

const styles = {
  userField: {
    borderRadius: '4px',
    border: '1px solid #211E1A',
    backgroundColor: '#faffff',
    color: 'black'
  }
}

const Loan = ({ item, onOk }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...item,
      loanDate: moment(item.loanDate),
      returnDate: moment(item.returnDate),
      extensionDate: moment(item.extensionDate),
      extensionReturnDate: moment(item.extensionReturnDate),
      shippingDate: moment(item.shippingDate),
      returnShippingDate: moment(item.returnShippingDate)
    });
  }, [form, item]);

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    const data = {
      ...values,
      piece: { id: item.objectId },
      loanDate: moment(values.loanDate).format('YYYY-MM-DD'),
      returnDate: moment(values.returnDate).format('YYYY-MM-DD'),
      extensionDate: moment(values.extensionDate).format('YYYY-MM-DD'),
      extensionReturnDate: moment(values.extensionReturnDate).format('YYYY-MM-DD'),
      shippingDate: moment(values.shippingDate).format('YYYY-MM-DD'),
      returnShippingDate: moment(values.returnShippingDate).format('YYYY-MM-DD')
    };
    if (item.id === 0) {
      await service.insert('loans', data);
    } else {
      await service.update('loans', data, item.id);
    }
    onOk();
  };

  return (
    <Layout>
      <Content align="center">
        <Form layout='vertical' form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Form.Item label='Id' name='id' hidden><Input style={styles.userField} /></Form.Item>
          <Form.Item label='Object' name='objectId' hidden><Input style={styles.userField} /></Form.Item>

          <Row>
            <Col span={4}>
              <Form.Item label='Type' name='type' style={{ textAlign: 'left' }}>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value='Incoming'>Incoming</Radio.Button>
                  <Radio.Button value='Outgoing'>Outgoing</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Divider type='vertical' />
            <Col span={7}>
              <Form.Item label='Status' name='status' style={{ textAlign: 'left' }}>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value='OnLoan'>On Loan</Radio.Button>
                  <Radio.Button value='Returned'>Returned</Radio.Button>
                  <Radio.Button value='Extended'>Extended</Radio.Button>
                  <Radio.Button value='Unknown'>Unknown</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Divider type='vertical' />
            <Col span={5} offset={1}>
              <Form.Item label='Institution or Organization' name='institution'><Input style={styles.userField} /></Form.Item>
            </Col>
            <Divider type='vertical' />
            <Col span={5}>
              <Form.Item label='Contact Person' name='contactPerson'><Input style={styles.userField} /></Form.Item>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col span={5}>
              <Form.Item label="Loan Date" name="loanDate"><DatePicker className="stepFormField" /></Form.Item>
              <Form.Item label='Loan Period' name='loanPeriod'><Input style={styles.userField} /></Form.Item>
              <Form.Item label="Return Date" name="returnDate"><DatePicker className="stepFormField" /></Form.Item>
            </Col>
            <Divider type='vertical' />
            <Col span={5}>
              <Form.Item label="Extension Date" name="extensionDate"><DatePicker className="stepFormField" /></Form.Item>
              <Form.Item label='Extension Period' name='extensionPeriod'><Input style={styles.userField} /></Form.Item>
              <Form.Item label="Extension Return" name="extensionReturnDate"><DatePicker className="stepFormField" /></Form.Item>
            </Col>
            <Divider type='vertical' />
            <Col span={6}>
              <Form.Item style={{ textAlign: 'left' }} label="Mount" name="mount" valuePropName="checked" hidden><Switch checkedChildren="YES" unCheckedChildren="NO" /></Form.Item>
              <Form.Item label='Mount Storage' name='mountStorage'><Input style={styles.userField} /></Form.Item>
              <Form.Item label="Shipping Date" name="shippingDate"><DatePicker className="stepFormField" /></Form.Item>
              <Form.Item label='Shipper' name='shipper'><Input style={styles.userField} /></Form.Item>
            </Col>
            <Divider type='vertical' />
            <Col span={6}>
              <Form.Item label='Way of Transport' name='wayOfTransport'><Input style={styles.userField} /></Form.Item>
              <Form.Item label="Return Shipping Date" name="returnShippingDate"><DatePicker className="stepFormField" /></Form.Item>
              <Form.Item label='Return Shipper' name='returnShipper'><Input style={styles.userField} /></Form.Item>
            </Col>
          </Row>
          <Row><Col span={23}><Form.Item label="Notes" name="note"><Input style={styles.userField} /></Form.Item></Col></Row>
          <hr />
          <Row>
            <Col span={12}><Delete dataSet='loans' id={item.id} onFinish={onOk} /></Col>
            <Col span={12}><Button style={Buttons.button} htmlType='submit'>S A V E</Button></Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default Loan;
