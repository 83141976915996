import React, { useState } from 'react';
import { Form, Input, Button } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

const Password = ({ onClose, onSubmit, name, getOld }) => {

  const [form] = Form.useForm();
  const [length, setLength] = useState(false);
  const [upper, setUpper] = useState(false);
  const [lower, setLower] = useState(false);
  const [digit, setDigit] = useState(false);
  const [special, setSpecial] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const passChanged = e => {
    const pass = form.getFieldValue('newPass');
    const confirm = form.getFieldValue('confirm');
    setLength(/(?=.{8,16}$)/.test(pass));
    setUpper(/(?=.*[A-Z])/.test(pass));
    setLower(/(?=.*[a-z])/.test(pass));
    setDigit(/(?=.*[0-9])/.test(pass));
    setSpecial(/(?=.*[!@#$%^&*])/.test(pass));
    setConfirm(pass === confirm);
  }

  const ValidCheck = ({ flag, text }) =>
    <div style={{ textAlign: 'left', color:'white' }}>
      {flag ?
        <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '1.2rem' }} /> :
        <CloseCircleOutlined style={{ color: '#f5222d', fontSize: '1.2rem' }} />}
        &nbsp;{text}
    </div>

  const Validation = () => (
    <div className="pass-valid">
      <ValidCheck flag={length} text="Password must be 8-16 characters long" />
      <ValidCheck flag={upper} text="Password must contain at least one uppercase letter" />
      <ValidCheck flag={lower} text="Password must contain at least one lowercase letter" />
      <ValidCheck flag={digit} text="Password must contain at least one digit" />
      <ValidCheck flag={special} text="Password must contain at least one special character" />
      <ValidCheck flag={confirm} text="Password and confirmation must match" />
    </div>
  );

  const allGood = length && upper && lower && digit && special && confirm;

  const onFinish = values => onSubmit(values);

  const onFailed = (errorInfo) => console.error("Failed:", errorInfo);

  return (
    <Form form={form} {...layout} onFinish={onFinish} onFinishFailed={onFailed}>
      <div className="pin-title">PASSWORD SETTING</div>
      <div className="pin-text">
        <p>Dear {name},</p>
        <p>Please, set your password. Password must obey rules due to password policy.<br />
          After successfully password setting, you will be redirected to the login page<br />
          If you are having any issues with your account please don't hesitate to contact us.</p>
        <hr />
      </div>
      <div>
        {getOld &&
          <Form.Item
            name="oldPass"
            label={<span className="white-label">Old Password :</span>}>
            <Input type="password" />
          </Form.Item>
        }
        <Form.Item
          name="newPass"
          label={<span className="white-label">New password</span>}>
          <Input type="password" onChange={passChanged} />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={<span className="white-label">Confirmation</span>}>
          <Input type="password" onChange={passChanged} />
        </Form.Item>
        <hr />
        <Validation />
      </div>
      <hr />
      <div className="right">
        <Button key='c' type='primary' onClick={onClose} style={{ marginRight: '12px' }}>Close</Button>
        <Button key='s' type='primary' htmlType="submit" disabled={!allGood}>Set Password</Button>
      </div>
    </Form >
  );
}

export default Password;