import React, { useEffect } from 'react';
import config from 'config';
import { clearToken } from 'services/authServices'
import { clearUser } from 'store/userSlice';
import { useDispatch } from 'react-redux';

export default function Logout({ history }) {

  const dispatch = useDispatch();

  useEffect(() => {
    const eraseToken = () => {
      try {
        config.currentUser = { id: 0, name: '', role: '', landing: '', conflict: 0, canDelete: false };
        config.token = '';
        dispatch(clearUser());
        clearToken();
        history.push('/home');
      } catch (error) {
        console.error(error);
      }
    }
    eraseToken();
    // eslint-disable-next-line
  }, [history]);

  return <div />
}
