import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { Table, Layout, Modal, message } from "antd";

import service from "services/apiServices";
import { isAdmin } from "services/authServices";
import tableHeader from "services/tableService";
import config from "config";
import Attachment from './attachment';

const colProps = [
  { key: "comment", title: "Comment", width: '80%' }
];

const columns = ({ canView, canEdit, onViewClick, onEditClick }) =>
  tableHeader(
    colProps,
    canView,
    canEdit,
    onViewClick,
    onEditClick
  );

const { Content } = Layout;

const Attachments = ({ story }) => {

  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list(`stories/${story}/attachments`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [story]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const editAttachment = id => {
    if(id === 0){
      setItem({ id: 0, url: '', comment: '', storyId: story });
    } else {
      const attachment = _.find(data, { id: id });
      setItem({ id: attachment.id, url: attachment.url, comment: attachment.comment, storyId: story });  
    }
    showModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={isLoading}
            size="small"
            tableLayount={"fixed"}
            columns={columns({
              canView: true,
              canEdit: isAdmin(),
              onViewClick: (id) => {
                const item = _.find(data, { id: id });
                window.open(item.url, "_blank")
              },
              onEditClick: (id) => editAttachment(id),
            })}
            dataSource={data}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
          <Modal width={'50%'} open={visible} title={<h2>STORY ATTACHMENTS</h2>} footer={[]} onCancel={hideModal}><Attachment item={item} onOk={refresh} /></Modal>
    </div>
  );
};

export default Attachments;
