import Cookies from 'universal-cookie';
import config from 'config';
import apiServices from 'services/apiServices';
import { encode } from './base64';

const cookies = new Cookies();

export const isSuper = () => config.currentUser.role === 'SuperAdmin';
export const isAdmin = () => config.currentUser.role.includes('Admin');
export const notIntern = () => 'SuperAdmin,Admin,User'.includes(config.currentUser.role);
export const isLogged = () => config.currentUser.id !== 0;

export const setToken = token => {
  clearToken();
  let expires = new Date();
  expires = new Date(expires.getTime() + 1000 * 60 * 60 * 8); // 8 hours
  cookies.set('wcmToken', token, { expires: expires });
}
export const getToken = () => cookies.get('wcmToken');

export const clearToken = () => cookies.remove('wcmToken');

export const authenticate = async values => {
  let credentials;
  if (values.username === "token") {
    credentials = "token:" + values.password;
  }
  else {
    credentials = encode(`${values.username}:${values.password}`);
  }
  const response = await apiServices.insert('login', `"${credentials}"`);
  if (response.token) setToken(response.token);
  return response;
}

export const setPassword = async (values, id) => {
  const response = await apiServices.update('setpass', values, id);
  return response;
}