import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Input, Layout, Row, Col } from 'antd';
import { isAdmin } from 'services/authServices';
import service from 'services/apiServices';
import { Curtain, Delete, Title, Buttons } from "common";

const layout = { labelCol: { span: 3 }, wrapperCol: { span: 21 } };

export default function ({ history }) {

  if (!isAdmin()) history.goBack();

  const [id] = useState(parseInt(useParams().id));
  const [tagId] = useState(parseInt(useParams().parent));
  const [tagVal, setTagVal] = useState('');
  const [typeId, setType] = useState({});

  const [loading, setLoading] = useState(true);
  const [subf] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    const fetch = async (id) => {
      const restag = await service.list('tags?tag=' + tagId);
      setType(restag.type);
      setTagVal(restag.value);
        if (id === 0) {
        subf.setFieldsValue({ id: 0, tagId: tagId });
      } else {
        const result = await service.read('subtags', id);
        subf.setFieldsValue({ ...result, tagId: tagId });
      }
      setLoading(false);
    };
    if (id !== '0') fetch(id);
    setLoading(false);
  }, [id, subf, tagId]);

  useEffect(() => {
    if (id !== '0') fetch(id);
    setLoading(false);
  }, [id, subf, tagId]);

  const onFailed = () => history.goBack();

  const onFinish = async (values) => {
    setLoading(true);
    if (id === 0)
      await service.insert('subtags', { ...values, tag: { id: values.tagId } });
    else
      await service.update('subtags', { ...values, tag: { id: values.tagId } }, id);
    setLoading(false);
    history.push(`/tags/${typeId}/${tagId}`);
  }

  const onDelete = () => {
    history.push(`/tags/${typeId}/${tagId}`);
  }

  if (loading) return <Curtain />;
  return (<Layout className='container'>
    <Title align="center">Subtag for {tagVal}</Title>
    <hr style={{ marginBottom: '24px' }} />
    <Form form={subf} {...layout} onFinish={onFinish} onFinishFailed={onFailed}>
      <Row><Col span={12} offset={6}><Form.Item label='English' name='value'><Input /></Form.Item></Col></Row>
      <Row><Col span={12} offset={6}><Form.Item label='B C S' name='bhValue'><Input /></Form.Item></Col></Row>
      <hr />
      <Row>
        <Col span={4} offset={6}><Delete dataSet='subtags' id={id} onFinish={onDelete} />
        </Col><Col span={4}><Button style={Buttons.close} onClick={onDelete}>Close</Button>
        </Col><Col span={5}><Button style={Buttons.confirm} htmlType='submit'>SAVE</Button></Col>
      </Row>

      <Form.Item name='id' hidden><Input /></Form.Item>
      <Form.Item name='order' ><Input hidden /></Form.Item>
      <Form.Item name='tagId' hidden><Input /></Form.Item>
    </Form>
  </Layout>);
};