import TableHead from 'services/TableHead';
import labels from 'assets/labels/participant.json';
import services from 'services/apiServices';


const columns = ({ canEdit, editUrl }) => {

  const colProps = [
    { key: 'id', dataIndex: 'id', title: labels.ID, width: '6%', sorter: (a, b) => a.id - b.id, render: x => services.toFormat(x) },
    { key: 'firstName', title: labels.FIRST_NAME, width: '10%', sorter: (a, b) => a.firstName.localeCompare(b.firstName) },
    { key: 'lastName', title: labels.LAST_NAME, width: '10%', sorter: (a, b) => a.lastName.localeCompare(b.lastName) },
    { key: 'gender', title: labels.GENDER, width: '10%' },
    { key: 'year', title: labels.YEAR_OF_BIRTH, width: '10%', sorter: (a, b) => a.year - b.year },
    { key: 'currentResidence', title: labels.CURRENT_RESIDENCE, width: '18%' },
    { key: 'conflict', title: labels.CONFLICT, width: '11%' },
    { key: 'researchCell', title: labels.RESEARCH_CELL, width: '11%' },
  ];

  return TableHead(colProps, canEdit, editUrl);
}

export default columns;
