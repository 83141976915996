import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import {
  SingleField
} from "../fields";
import { Curtain } from "common";

const Page13 = () => {

  const page = 13;
  const [loading, setLoading] = useState(false);
  const qn = [
    "4.13",
    "4.14",
    "4.15",
    "4.16",
    "4.17"
  ];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the
          location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>

          <SingleField
            form={form}
            label="13. How often was there shelling in your neighborhood?"
            question="c4.13"
            extension="e4.13"
            options={[
              "Daily",
              "Frequently",
              "Occasionally",
              "Rarely",
              "I don't remember"
            ]}
            other="Other:"
          />
          <SingleField
            form={form}
            label="14. Was your temporary accommodation ever directly hit by a shell or another type of missile?"
            question="c4.14"
            options={[
              "Yes",
              "No",
              "I don't remember"
            ]}
          />
          <SingleField form={form} label="15. Was your accommodation burnt down?"
            question="c4.15"
            options={["Yes", "No"]} />
          <SingleField
            form={form}
            label="16. Was your neighborhood exposed to sniper fire?"
            question="c4.16"
            options={[
              "Yes",
              "No",
              "I don't remember"
            ]}
          />
          <SingleField
            form={form}
            label="17. Where did you hide during shelling/bombing?"
            question="c4.17"
            extension="e4.17"
            options={[
              "Bomb shelter",
              "Basement in a house or building",
              "Basement in the neighborhood",
              "The safest room in the house (bathroom, hallway..)",
              "We were not hiding"
            ]}
            other="Other (specify):"
          />

        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page13;