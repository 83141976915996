import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import Navigator from "./navigator";
import { useDispatch, useSelector } from "react-redux";
import { setRespondent } from "store/respondentSlice";
import { setPage } from "store/pageSlice";
import { NumberField, TextField, SingleField, CondField } from "./fields";
import { Curtain } from "common";

const Context = () => {

  const page = 3;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const respondent = useSelector(state => state.respondent);

  useEffect(() => {
    form.setFieldsValue(respondent);
    // eslint-disable-next-line
  }, [respondent]);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    setLoading(true);
    const result = await apiServices.insert('quest', { ...respondent, ...values, page: page });
    setLoading(false);
    if (result) {
      dispatch(setRespondent(result));
    }
  }

  return (
    <div className="quest-card">
      {loading && <Curtain />}
      <div className="quest-title">
        The following questions relate to the period of the outbreak of armed conflict
      </div>

      <Form form={form} onFinish={onFinish}>
        <Form.Item label="Id" name="id" hidden><Input /></Form.Item>
        <Form.Item label="Code" name="code" hidden><Input /></Form.Item>

        <TextField label="1. Where did you live before the outbreak of an armed conflict (country, city, neighborhood)?" question="residence" />

        <NumberField label="2. How old were you when the armed conflict started?" question="age" />

        <CondField form={form} label="3. Were you attending school when the armed conflict started?"
          question="school" options={["Yes", "No"]}
          subLabel="3.1 If yes, which grade?" subQuestion="grade" trigger="Yes"
          current={respondent.school} />

        <SingleField label="4. Do you remember the beginning of armed conflict?" question="memories"
          options={["Yes", "No"]} />

        <CondField form={form} label="5. Do you recall any noticeable changes in your surroundings before the armed conflict started?"
          question="recall" options={["Yes", "No"]}
          subLabel="5.1 If yes, please describe some of these changes" subQuestion="recDesc" trigger="Yes"
          current={respondent.recall} />

        <TextField label="6. Place of residence at the outbreak of armed conflict (city, country):" question="place" />

        <TextField label="- The time period spent in the aforementioned location:" question="period" />
      </Form>
      <Navigator prev={page-1} next={page+1} save={() => form.submit()} />
    </div>
  );
}

export default Context;
