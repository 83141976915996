import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import service from 'services/apiServices';
import TableOral from 'services/TableOral';
import TranscriptForm from './TranscriptForm';

const TranscriptList = ({ oral }) => {

  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState({});
  const [data, setData] = useState([]);

  const columns = () => {
    const colProps = [
      { key: 'time', dataIndex: 'time', title: "Time", width: '20%' },
      { key: 'text', dataIndex: 'text', title: "Text", width: '70%' },
    ];
    return TableOral(colProps, true, editModal);
  }

  const editModal = x => {
    if(!x.oralHistoryId) x.oralHistoryId = oral;
    setRecord(x);
    setVisible(true);
  }

  const fetch = async () => {
    const response = await service.list(`transcripts/${oral}`);
    setData(response);
  }

  // eslint-disable-next-line
  useEffect(() => { fetch(); }, []);

  return (
    <>
      <Table
        style={{ marginTop: '-24px' }}
        size="small"
        tableLayout={"fixed"}
        scroll={{ y: '59vh' }}
        columns={columns()}
        dataSource={data}
        pagination={false}
        rowKey={(row) => row.id}
      />
      <Modal
        width={'90%'}
        style={{ top: '80px' }}
        open={visible} title={<h2>Transcript Detail</h2>}
        footer={[]}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}>
        <TranscriptForm transcriptId={record.id} oralId={oral} onClose={() => { setVisible(false); fetch(); }} />
      </Modal>
    </>
  )
}

export default TranscriptList;