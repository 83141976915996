import React from "react";
import { Form, Input, Switch, Layout, Row, Col } from "antd";
import Residences from '../components/residences';

const pad = { padding: '0 4px' };

const Residence = ({ form, labels }) => {

  const hidden = false; // useState(participantId === '0');

  return (
    <Layout style={{ paddingTop: '24px' }}>
      <Form form={form} layout='vertical'>
        <Row>
          <Col style={pad} span={8}>
          <Form.Item label="Hometown/home country" name="homeTownCountry"><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>
        <Row>
          <Col style={pad} span={4}>
            <Form.Item label={labels.RETURN_COUNTRY} name="returnHomeCountry"><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col style={pad} span={4}>
            <Form.Item label={labels.RETURN_TOWN} name="returnHomeTown"><Input className="stepFormField" /></Form.Item>
          </Col>
          <Col style={pad} span={3} offset={1}>
            <Form.Item label='Refugee' name='refugee' valuePropName='checked' style={{ textAlign: 'left' }}>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
          <Col style={pad} span={3}>
            <Form.Item label='Returnee' name='returnee' valuePropName='checked' style={{ textAlign: 'left' }}>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
          <Col style={pad} span={3}>
            <Form.Item label='IDP' name='idp' valuePropName='checked' style={{ textAlign: 'left' }}>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
          <Col style={pad} span={3}>
            <Form.Item label='Stateless' name='stateless' valuePropName='checked' style={{ textAlign: 'left' }}>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
          <Col style={pad} span={3}>
            <Form.Item label='Unaccompanied child' name='unChild' valuePropName='checked' style={{ textAlign: 'left' }}>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row hidden={hidden}>
        <Col span={24}>
          <Residences participant={form.getFieldValue('pid')} labels={labels} />
        </Col>
      </Row>
    </Layout>
  );
};

export default Residence;
