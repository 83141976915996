import React, { useState } from "react";
import { Layout, Tabs } from "antd";
import { Notes, Attachments, Photos, Loans } from '../components';
import { Parts } from 'components/parts';

const { TabPane } = Tabs;

const TabHead = ({ children }) => {
  return <span style={{ fontSize: '1.5em' }}>{children}</span>;
}

const Related = ({ form, labels }) => {

  const [objectId] = useState(form.getFieldValue('id'));

  return (
    <Layout style={{ textAlign: 'left', padding: '24px 0' }}>
      <div>{objectId}</div>
      <Tabs type='card' className='container' style={{ textAlign: 'left' }}>
        <TabPane tab={<TabHead>Notes</TabHead>} key="1">
          <Notes object={objectId} />
        </TabPane>
        <TabPane tab={<TabHead>Parts</TabHead>} key="2">
          <Parts object={objectId} />
        </TabPane>
        <TabPane tab={<TabHead>Attachments</TabHead>} key="3">
          <Attachments object={objectId} canEdit={false} />
        </TabPane>
        <TabPane tab={<TabHead>Photos</TabHead>} key="4">
          <Photos object={objectId} />
        </TabPane>
        <TabPane tab={<TabHead>Loans</TabHead>} key="5">
          <Loans object={objectId} />
        </TabPane>
      </Tabs>
    </Layout>
  );
};

export default Related;
