import React, { useState } from "react";
import { Form, Input, DatePicker, Radio, Layout, Row, Col, Select, Switch } from "antd";

const ownerOptions = [
  { value: 'WCM Netherlands', label: 'WCM Netherlands' },
  { value: 'WCM Bosnia-Herzegovina', label: 'WCM Bosnia-Herzegovina' }
]

const { TextArea } = Input;

const Legal = ({ form, labels }) => {

  //const hasParts = form.getFieldValue("part");
  const [onLoan, setOnLoan] = useState(form.getFieldValue("legalStatus") === 'OnLoan');
  const legalChanged = e => setOnLoan(e.target.value === 'OnLoan');

  return (
    <Layout style={{ paddingTop: '24px' }}>
      <Form form={form} layout='vertical'>
        <Row>
          <Col span={12}>
            <Form.Item label={labels.LEGAL_STATUS} name='legalStatus' style={{ textAlign: 'left' }} initialValue='Permanent' onChange={legalChanged}>
              <Radio.Group buttonStyle='solid' size='small'>
                <Radio.Button value='Null'>Null</Radio.Button>
                <Radio.Button value='Permanent'>Permanent</Radio.Button>
                <Radio.Button value='OnLoan'>On Loan</Radio.Button>
                <Radio.Button value='LoanEnded'>Loan Ended</Radio.Button>
                <Radio.Button value='Deaccessioned'>Deaccessioned</Radio.Button>
                <Radio.Button value='Unknown'>Unknown</Radio.Button>
                <Radio.Button value='Mixed'>Mixed</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {onLoan &&
              <Form.Item label={labels.LOAN_STATUS} name='loanStatus' style={{ textAlign: 'left' }} initialValue='None'>
                <Radio.Group buttonStyle='solid' size='small'>
                  <Radio.Button value='Null'>Null</Radio.Button>
                  <Radio.Button value='OnLoan'>On Loan</Radio.Button>
                  <Radio.Button value='Returned'>Returned</Radio.Button>
                  <Radio.Button value='Extended'>Extended</Radio.Button>
                  <Radio.Button value='Permanent'>Permanent</Radio.Button>
                  <Radio.Button value='Unknown'>Unknown</Radio.Button>
                  <Radio.Button value='Mixed'>Mixed</Radio.Button>
                </Radio.Group>
              </Form.Item>
            }
          </Col>
          <Col span={12}>
            <Form.Item label={labels.EXHIBITION} name='exhibitionHistory' initialValue=''><Input className="stepFormField" /></Form.Item>
          </Col>
          {/* <Col span={7} offset={1}>
          </Col> */}
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label={labels.ACCESSION} name='accessionDate'><DatePicker className="stepFormField" /></Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Owned by' name='ownedBy'>
              <Select className="stepFormField" options={ownerOptions} style={{ textAlign: 'left' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={labels.USE_STORAGE} name='useAndStorage' initialValue=''><Input className="stepFormField" /></Form.Item>
          </Col>
        </Row>


<div style={{display:'none'}}>
        <Row>
          <Col span={12}>
            <Form.Item label={labels.NAME} name='name' initialValue=''>
              <TextArea className="stepFormField" autoSize={{ minRows: 1, maxRows: 3 }} />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item label={labels.TYPE} name='objectType' initialValue=''>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Digital' name='digital' style={{ textAlign: 'left' }} valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Copy' name='copy' style={{ textAlign: 'left' }} valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO"  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item style={{ textAlign: 'left' }} label={labels.CONSERVATION} name="conservation" valuePropName="checked">
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Row>
                  <Col style={{marginBottom:'9px'}}>
                    Condition Report
                  </Col>
                </Row>
                <Row>
                  <Col span={4}>
                    <Form.Item style={{ textAlign: 'left' }} label='' name="conditionReport" valuePropName="checked">
                      <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                    </Form.Item>
                  </Col>
                  <Col span={20}>
                    <Form.Item style={{ textAlign: 'left' }} label='' name="report" initialValue=''>
                      <Input className="stepFormField" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item label='Mount Location' name='standLocation' initialValue=''><Input className="stepFormField" /></Form.Item>
                <Form.Item label=' ' name='stand' valuePropName='checked' style={{ textAlign: 'left' }} hidden>
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item label={labels.DESCRIPTION} name='description' initialValue=''>
              <Input.TextArea className='stepFormField' rows={10} />
            </Form.Item>
          </Col>
        </Row>
        </div>
      </Form >
    </Layout >
  );
}

export default Legal;
