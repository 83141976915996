import React, { useEffect } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, Layout, Button, Row, Col } from 'antd';

import service from 'services/apiServices';
import { Buttons, Delete } from 'common';
import config from 'config';

const { Content } = Layout;

const Note = ({ item, onOk }) => {

  const [form] = Form.useForm();

  useEffect(() => { form.setFieldsValue({ ...item, time: moment(item.time) }) }, [form, item]);

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    let result = false;
    if (item.id === 0) {
      values = { ...values, story: { id: service.toNumber(item.storyId) }, user: config.currentUser.name };
      result = await service.insert('storynotes', values);
    } else {
      result = await service.update('storynotes', { ...values, story: { id: service.toNumber(item.storyId) }, user: config.currentUser.name }, item.id);
    }
    if (result) {
      onOk();
    }
  };

  return (
    <Layout className="container">
      <Content align="center">
        <Form layout='vertical' form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Row>
            <Col span={24}>
              <Form.Item label='Id' name='id' hidden><Input /></Form.Item>
              <Form.Item label='Date' name='time'><DatePicker className='stepFormField' /></Form.Item>
              <Form.Item label='Comment' name='comment'><Input.TextArea className='stepFormField' rows={6} /></Form.Item>
              <Form.Item label='Story' name='storyId' hidden><Input /></Form.Item>
              <Form.Item label='User' name='userId' hidden><Input /></Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}><Delete dataSet='storyNotes' id={item.id} onFinish={onOk} /></Col>
            <Col span={12} style={{ textAlign: 'right' }}><Button style={Buttons.button} htmlType='submit'>SAVE</Button></Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default Note;
