import React, { useState } from 'react';
import { Button, Table } from "antd";
import config from 'config';

const PdfGenerator = ({ data }) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const thumbnail = image => <img style={{ border: '1px #999 solid' }} src={`${config.imagePath}${image}.jpg`} alt={image} />;

  const columns = [
    { key: 'objectId', title: 'Object ID', ellipsis: true, dataIndex: 'objectId', width: '5%' },
    { key: 'image', title: 'Photo', dataIndex: 'image', width: '5%', render: image => thumbnail(image) },
    { key: 'name', title: 'Name', ellipsis: true, dataIndex: 'name', width: '20%' },
    { key: 'description', title: 'Description', ellipsis: true, dataIndex: 'description', width: '20%', align: 'center' },
    { key: 'story', title: 'Story', ellipsis: true, dataIndex: 'story', width: '20%', align: 'center' },
    { key: 'participant', title: 'Participant', ellipsis: true, dataIndex: 'participant', width: '20%' },
    { key: 'objectType', title: 'Object Type', ellipsis: true, dataIndex: 'objectType', width: '10%' }
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const pdfExport = () => {
    //console.info(selectedRowKeys);
  }

  const TableTitle = () =>
    <div>
      <div className='left'>
        <h2 align='left'>Objects {data.length}</h2>
      </div>
      <div className='right'>
        <Button type='primary' htmlType="button"
          className='tableButton'
          onClick={pdfExport}
          style={{ margin: 0, height: 36, width: 164 }}>
          Export to PDF
        </Button>
        {/* {!!link && <a href={link} target="_blank" rel="noopener noreferrer" download>&nbsp;&nbsp;&nbsp;--&gt; VIEW PDF</a>} */}
      </div>
    </div>

  return (
    <>
      <Table
        size="small"
        title={TableTitle}
        tableLayout={"fixed"}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        // onRow={(record, index) => ({ onClick: () => { viewTrans(record); } })}
        rowKey={row => row.id}
      />

    </>
  );
}

export default PdfGenerator;