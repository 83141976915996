import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Layout } from "antd";
import Password from "./Password";
import LoginForm from "./LoginForm";
import LoginPin from "./LoginPin";
import ResetForm from "./ResetForm";

import config from "config";
import inverse from "assets/images/inverse.png";
import service from "services/apiServices";
import { authenticate, setPassword, clearToken, getToken, setToken } from "services/authServices";
import { Curtain } from "common";
import Error from "./Error";
import { setUser } from "store/userSlice";

const Login = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [mode, setMode] = useState("login");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");

  const checkToken = useCallback(async () => {
    const token = getToken();
    if (!token) return clearToken();
    const response = await authenticate({ username: 'token', password: token });
    if (!response) return clearToken();
    dispatch(setUser(response));
    setMaster();
    setCurrent(response);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  const setMaster = async () => {
    const ss = await service.list("stories"); config.stories = ss;
    if (config.currentUser.role !== "Intern") {
      const ps = await service.list("participants"); config.participants = ps;
      const oh = await service.list('oralhistory'); config.orals = oh;
    }
  }

  const setCurrent = result => {
    config.currentUser = {
      id: result.id,
      name: result.name,
      role: result.role,
      landing: result.landing,
      conflict: result.conflict,
      canDelete: result.role === "SuperAdmin",
      delParticipants: result.delParticipants || result.role === "SuperAdmin",
      delStories: result.delStories || result.role === "SuperAdmin",
      delObjects: result.delObjects || result.role === "SuperAdmin",
      delParts: result.delParts || result.role === "SuperAdmin",
      delHistories: result.delHistories || result.role === "SuperAdmin"
    };

    config.token = result.token;
    setToken(result.token);
    history.push(config.attemptRoute ? config.attemptRoute : result.landing);;
    config.attemptRoute = '';
  }

  const checkCredentials = async values => {
    setError("");
    setLoading(true);
    const response = await authenticate(values);
    setLoading(false);
    if (!response) {
      setMode("login");
      return setError("Invalid credentials! Try again!");
    }

    if (response.landing === "null") {
      setUserId(response.id);
      setUserName(response.name);
      return setMode("pass");
    }

    if (response.token === "pin required") return setMode("pin");
    dispatch(setUser(response));
    setCurrent(response);
    setMaster();
  }

  const submitPassword = async values => {
    await setPassword(values, userId);
    setMode("login");
  }

  const onClose = () => setMode("login");
  const onReset = () => setMode("forgot");

  return (
    <div>
      <Layout className="bgLogIn">
        <img src={inverse} alt="inverse" style={{ width: '32vw', margin: '48px auto' }} />
        <div className="outer">
          {error && <Error message={error} onClose={() => setError("")} />}
          <div className="inner">
            {loading && <Curtain />}
            {mode === "login" && <LoginForm onReset={onReset} onFinish={checkCredentials} />}
            {mode === "pin" && <LoginPin onClose={onClose} onFinish={checkCredentials} />}
            {mode === "pass" && <Password name={userName} onClose={onClose} onSubmit={submitPassword} />}
            {mode === "forgot" && <ResetForm onClose={onClose} />}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Login;