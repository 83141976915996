import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Divider, Form, Switch, Input, Select, Steps, message, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import config from 'config';
import service from "services/apiServices";
import { EditHistory, FormNavigation, Title, Spinner } from "common";
import { General, Legal, Structural, Related, Confirm } from './steps';
import labels from "assets/labels/object.json";

const { TextArea } = Input;
const Step = Steps.Step;

const ObjectEdit = ({ history }) => {

  const id = parseInt(useParams().id);
  const [objectId, setObjectId] = useState('');
  const [name, setObjectName] = useState('');
  const [loading, setLoading] = useState(false);
  const [pplLoad, setPplLoad] = useState(false);
  const [people, setPeople] = useState([]);
  const [styLoad, setStyLoad] = useState(false);
  const [stories, setStories] = useState([]);
  const [step, setStep] = useState(0);
  const [lastEdit, setLastEdit] = useState({});
  const [form] = Form.useForm();
  const [steps] = useState([
    { title: "General information", content: () => (<General form={form} labels={labels} getId={getId} setName={setName} />) },
    { title: "Legal information", content: () => (<Legal form={form} labels={labels} />) },
    { title: "Structural information", content: () => (<Structural form={form} labels={labels} />) },
    { title: "Additional Information", content: () => (<Related form={form} labels={labels} />) },
    { title: "Confirm & Save", content: () => (<Confirm form={form} labels={labels} onFinish={onFinish} />) }
  ]);

  if (config.currentUser.id === 0) { history.push('/login') }

  const participantSelected = async value => {
    try {
      let result = _.find(people, ['id', service.toNumber(value)]);
      form.setFieldsValue({ study: result.id % 10000 === 0, participant: result.name, conflict: Math.floor(result.id / 10000) });
      if (id === 0) {
        let next = await service.read('pieces/next', result.id);
        if (next) {
          form.setFieldsValue({ order: next });
          getId();

          const values = form.getFieldsValue();
          values.storyId += '';
          const res = await service.insert("pieces", values);
          if (res) {
            form.setFieldsValue({ id: res.id });
          }
        }
      }
      getStories(result.id);
    } catch (error) {
      console.error(error);
    }
  };

  const getPeople = useCallback(async () => {
    setPplLoad(true);
    const result = await service.list("master/participants");
    setPplLoad(false);
    setPeople(result);
  }, []);

  const getStories = useCallback(async (id) => {
    if (id === undefined) id = 0;
    setStyLoad(true);
    const result = await service.list("master/stories/" + id);
    setStyLoad(false);
    setStories(result);
  }, []);

  const getMaster = useCallback(async (pid) => {
    try {
      if (pid === 0) pid = '0.0';
      setLoading(true);
      await getPeople();
      await getStories(service.toNumber(pid));
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
  }, [getPeople, getStories]);

  const getObject = useCallback(async (id) => {
    try {
      if (id === 0) {
        form.setFieldsValue({ id: 0, participantId: 0, storyId: 0 });
        getMaster(0);
      } else {
        setLoading(true);
        const result = await service.read('pieces', id + '/edit');
        if (result) {
          setLastEdit({ lastUpdate: result.lastUpdate, editHistory: result.editHistory });
          setObjectName(result.name);
          form.setFieldsValue({
            ...result,
            accessionDate: moment(result.accessionDate),
            material: result.material.split(','),
            digitalCopy: result.digitalCopy.split(',')
          });
          getMaster(result.participantId);
        }
        if (id !== 0) getId();
        setLoading(false);
      }
    }
    catch (error) {
      message.error("We are experienciing some network related issues. Please try again in a minute.");
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getObject(id);
    // eslint-disable-next-line
  }, [id]);

  const onFinish = (page) => {
    config.headers.page = page;
    config.filter = "";
    history.push('/objects');
    // }
  }

  const onDelete = () => {
    _.remove(config.objects, item => item.id === id);
    history.push('/objects');
  }

  // const onCancel = () => { history.push('/objects'); };
  const onCancel = () => { history.goBack(); };
  // const onChange = step => { setStep(step); }

  const onChange = async step => {
    setStep(step);
  }

  const setName = value => setObjectName(value);

  const Single = () => {
    return (
      <div>
        <Steps type='navigation' current={step} onChange={onChange}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} style={{ width: '20%' }} />
          ))}
        </Steps>
        <div className="steps-content">
          {steps[step].content()}
        </div>
      </div>
    );
  }

  const nameOpt = (id, name) => name + ' (' + service.toFormat(id) + ')';

  const getId = () => {
    let objId = form.getFieldValue('conflict') + '.' + form.getFieldValue('order');
    if (form.getFieldValue('study')) objId += '.M';
    if (form.getFieldValue('digital')) objId += '.D';
    if (form.getFieldValue('copy')) objId += '.C';
    setObjectId(objId);
  }

  const transfer = async () => getObject(id);

  if (loading) return <Spinner />;
  return (
    <Layout className="container">
      {/* <Prompt when={formDirty} message={'Are you sure you wish to leave this page\r\nwithout saving the changes?'} /> */}
      <Title align="center">Object Info {objectId && <span title={name}>[{objectId}: {name.substring(0, 40)}...]</span>}</Title>
      <Divider />
      <EditHistory lastUpdate={lastEdit.lastUpdate} editHistory={lastEdit.editHistory} />
      <Form form={form} layout="vertical">
        <FormNavigation
          form={form}
          step={step}
          limit={steps.length - 1}
          onCancel={onCancel}
          onChange={onChange}
          canDelete={config.currentUser.delObjects}
          dataSet='pieces'
          study={form.getFieldValue('study')}
          id={id}
          transfer={transfer}
          onFinish={onDelete} />
        <Divider />
        <Row>
          <Col span={10}>
            <Form.Item label='Object Id' name='id' hidden><Input /></Form.Item>
            <Form.Item label='Participant' name='participant' hidden><Input /></Form.Item>
            <Form.Item label='Has Parts' name='part' hidden><Input /></Form.Item>
            <Form.Item label={`Contributor`} name="participantId">
              <Select className="stepFormField" mode="combobox"
                loading={pplLoad}
                showSearch
                optionFilterProp="children"
                onSelect={participantSelected}
                filterOption={(input, option) => service.anything(input, option.children)}
                style={{ textAlign: 'left' }}>
                {people.map(p => (<Select.Option key={p.id} value={service.toFormat(p.id)}>{nameOpt(p.id, p.name)}</Select.Option>))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={`Story`} name="storyId">
              <Select className="stepFormField" mode="combobox"
                loading={styLoad}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => service.anything(input, option.children)}
                style={{ textAlign: 'left' }}>
                {stories.map(s => (<Select.Option key={s.id} value={service.toFormat(s.id)}>{s.name}</Select.Option>))}
              </Select>
            </Form.Item>
            <Form.Item label='Conflict' name='conflict' initialValue={0} onChange={getId} hidden><Input /></Form.Item>
            <Form.Item label='Order' name='order' initialValue={0} onChange={getId} hidden><Input /></Form.Item>
            <Form.Item label='Study' name='study' initialValue={false} onChange={getId} hidden><Input /></Form.Item>
            <Form.Item label='Dark' name='darkPhoto' initialValue='noimg' hidden><Input /></Form.Item>
            <Form.Item label='Light' name='lightPhoto' initialValue='noimg' hidden><Input /></Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item label='Restrictions on use' name='restrictions'
              valuePropName='checked' style={{ textAlign: 'left' }}>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
          </Col>
        </Row>
        {form.getFieldValue('study') && (
          <Row>
            <Col span={12}>
              <Form.Item label='Object Contributor' name='objectContributor' initialValue=''>
                <TextArea className="stepFormField" autoSize={{ minRows: 1, maxRows: 3 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Contributor Contact' name='contributorContact' initialValue=''>
                <TextArea className="stepFormField" autoSize={{ minRows: 1, maxRows: 3 }} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      <Single />
    </Layout >
  );
}

export default ObjectEdit;
