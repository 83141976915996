import TableHead from 'services/TableHead';
import moment from 'moment';

const fullYear = date => {
  return moment(date).year() !== 1 ?  moment(date).format("YYYY-MM-DD") : '';
}

const columns = ({ canEdit, editUrl }) => {

  const colProps = [
    { key: 'id', title: 'Id', width: '6%', sorter: (a, b) => a.id - b.id },
    { key: 'type', title: 'Type', width: '8%', sorter: (a, b) => a.type.localeCompare(b.type) },
    { key: 'title', title: 'Title', width: '40%', sorter: (a, b) => a.title.localeCompare(b.title) },
    { key: 'openingDate', title: 'Opening Date', width: '10%', align: 'center', render: x => fullYear(x) },
    { key: 'closingDate', title: 'Closing Date', width: '10%', align: 'center', render: x => fullYear(x) },
    { key: 'venue', title: 'Venue', width: '15%' }
  ];

  return TableHead(colProps, canEdit, editUrl);
}

export default columns;
