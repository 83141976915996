import { Modal, Row, Col, Tooltip } from 'antd';
import React, { useState } from 'react';
import apiServices from 'services/apiServices';
import config from 'config';

const PdfView = ({ link, comment, part }) => {

  const [visible, setVisible] = useState(false);
  const [document, setDocument] = useState(null);

  const onClick = async () => {
    const url = "pieces/report";
    const result = await apiServices.read(url, link);
    setDocument(result);
    setVisible(true);
  }

  const close = async () => {
    await apiServices.insert("images/photos", { paths: [document] });
    setVisible(false);
  }

  if (!link) return <div />
  return (
    <>
      {!part &&
        <Row>
          <Col span={19} className="ttl-report" style={{ overflow: 'hidden' }}>
            <Tooltip title={comment}>
              {link}
            </Tooltip>
          </Col>
          <Col span={5} className="pdf-report" onClick={onClick}>VIEW</Col>
        </Row>
      }
      {!!part &&
        <Row style={{marginTop:'12px'}}>
          <Col span={24} className="pdf-report" onClick={onClick}>{link}</Col>
        </Row>
      }
      <Modal
        width='100%'
        bodyStyle={{ height: '100%' }}
        style={{ top: 0, textAlign: 'center' }}
        open={visible}
        title="CONDITION REPORT"
        footer={[]}
        onCancel={close}>
        <iframe
          title="Condition Report"
          src={`${config.srvUrl}${document}`}
          style={frame}
        />
      </Modal>
    </>
  );
}

export default PdfView;

const frame = {
  display: 'block',
  height: '100vh',
  width: '100vw',
  border: 'none',
  background: 'lightyellow'
}