import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { Table, Layout, Modal, message } from "antd";

import service from "services/apiServices";
import { isAdmin } from "services/authServices";
import tableHeader from "services/tableService";
import config from "config";
import Medium from './medium';

const colProps = [
  { key: "comment", title: "Comment", width: '85%' }
];

const columns = ({ canView, canEdit, onViewClick, onEditClick }) =>
  tableHeader(
    colProps,
    canView,
    canEdit,
    onViewClick,
    onEditClick
  );

const { Content } = Layout;

const Media = ({ participant }) => {

  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await service.list(`participants/${participant}/media`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [participant]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const editMedium = id => {
    if (id === 0) {
      setItem({ id: 0, url: '', comment: '', participantId: participant });
    } else {
      const medium = _.find(data, { id: id });
      setItem({ id: medium.id, url: medium.url, comment: medium.comment, participantId: participant });
    }
    showModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <Layout>
      <Content>
        <Table
          loading={isLoading}
          size="small"
          tableLayount={"fixed"}
          columns={columns({
            canView: true,
            canEdit: isAdmin(),
            onViewClick: (id) => {
              const item = _.find(data, { id: id });
              window.open(item.url, "_blank")
            },
            onEditClick: (id) => editMedium(id),
          })}
          dataSource={data}
          pagination={false}
          rowKey={(row) => row.id}
        />
      </Content>
        <Modal width={'70%'} open={visible} title={<h2>MEDIA ATTACHMENTS</h2>} footer={[]} onCancel={hideModal}><Medium item={item} onOk={refresh} /></Modal>
    </Layout>
  );
};

export default Media;
