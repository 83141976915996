import React, { useEffect } from 'react';
import { useState } from 'react';
import SingleField from './SingleField';
import MultiField from './MultiField';

const CondField = ({
  form, label, question, options, extension, other,
  trigger, current,
  subLabel, subQuestion, subOptions, subExtension, subOther, subMulti
}) => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(!!current && current.includes(trigger));
    // eslint-disable-next-line
  }, [current, trigger]);

  const checkChoice = e => {
    setVisible(e.includes(trigger));
  }

  return (
    <>
      <SingleField key={0}
        form={form} label={label} question={question}
        options={options} other={other} extension={extension}
        check={checkChoice} />

      {visible && !subMulti &&
        <SingleField key={1}
          form={form} label={subLabel} question={subQuestion}
          options={subOptions} other={subOther} extension={subExtension}
        />
      }

      {visible && subMulti &&
        <MultiField key={2}
          form={form} label={subLabel} question={subQuestion}
          options={subOptions} other={subOther} extension={subExtension}
        />
      }
    </>
  );
}

export default CondField;