import React from 'react';
import logobw from "assets/images/logo_wl.png";
import { Form, Input, Button } from 'antd';

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

const LoginForm = ({ onFinish, onReset }) => {

  const [form] = Form.useForm();
  const onFailed = error => console.error(error);
  const onSubmit = values => onFinish(values);

  return (
    <>
      <img src={logobw} alt="logo" className="logo-bw" />
      <div className='login-form'>
        <Form form={form} {...layout}
          name="loginForm"
          onFinish={onSubmit}
          onFinishFailed={onFailed}>
          <Form.Item
            label={<span className="white-label">Username :</span>}
            name="username">
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label={<span className="white-label">Password :</span>}
            name="password">
            <Input type="password" />
          </Form.Item>
          <div className="head-link" onClick={onReset}>FORGOT PASSWORD? Click here</div>
          <Button
            className="buttonLogIn"
            type="primary"
            htmlType="submit">
            SIGN IN
          </Button>
        </Form>
      </div>
    </>
  );
}

export default LoginForm;