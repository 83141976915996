import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import Navigator from "../navigator";
import { SingleField, CondOptions, TextField, NumberField } from "../fields";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import { Curtain } from "common";

const Page4 = () => {

  const page = 4;
  const [loading, setLoading] = useState(false);
  const qn = ["3.01", "3.02", "3.03", "3.04", "3.05", "3.051", "3.06", "3.07", "3.08", "3.09"];
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id, place } = useSelector(state => state.respondent);
  const [v305, setV305] = useState("No");

  const fetchData = async () => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });
      setV305(result.answers[4].choice);
      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      };
    }
  }
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const onFinish = async values => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : ""
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, answers });
    setLoading(false);
  }


  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden><Input /></Form.Item>

          <SingleField form={form} label="1. Please describe your housing situation:" question="c3.01" extension="e3.01" other="Other:"
            options={["Private house", "Apartment", "Office space", "Basement", "Collective housing", "Refugee camp", "Military camp"]} />

          <NumberField form={form} label="2. Size of the living space (approximately in m²)?" question="c3.02" />

          <TextField form={form} label="3. How many people did you share that space with?" question="c3.03" />

          <TextField label="4. Whom did you live with?" question="c3.04" />

          <CondOptions form={form} label="5. Was your neighborhood exposed to shelling?" question="c3.05"
            options={["Yes", "No", "I don't remember"]} trigger="Yes" current={v305}
            subLabel="5.1. If yes, how often?" subQuestion="c3.051"
            subOptions={["Daily", "Frequently", "Occasionally", "Rarely", "I don't remember"]} />

          <SingleField form={form} label="6. Was your home ever directly hit by a shell or another type of missile?" question="c3.06" other={false}
            options={["Yes", "No", "I don't remember"]} />

          <SingleField form={form} label="7. Was your home burnt down?" question="c3.07" other={false}
            options={["Yes", "No", "I don't remember"]} />

          <SingleField form={form} label="8. Have you witnessed street fights in your neighborhood or community?" question="c3.08" other={false}
            options={["Yes", "No"]} />

          <SingleField form={form} label="9. Was your neighborhood exposed to sniper fire?" question="c3.09" other={false}
            options={["Yes", "No", "I don't remember"]} />

        </Form>
      </div>
      <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
    </>
  );
}

export default Page4;