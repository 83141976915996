import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import apiServices from "services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "store/pageSlice";
import Navigator from "../navigator";
import { SingleField, TextField, MultiField } from "../fields";
import { Curtain } from "common";

const Page22 = () => {
  const page = 22;
  const [loading, setLoading] = useState(false);
  // question on the page
  const qn = ["4.74", "4.75", "4.76", "4.77", "4.78", "4.79"];

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.respondent);
  const { place, location } = useSelector(state => state.page);

  const fetchData = async (location) => {
    setLoading(true);
    const result = await apiServices.read('answers', `${id}/${page}?location=${location}&fields=${qn.join(',')}`);
    setLoading(false);
    if (result && result.answers.length > 0) {
      form.setFieldsValue({ id: result.id });

      for (let i = 0; i < qn.length; i++) {
        if (result.answers[i] !== undefined) {
          form.setFieldValue(`c${qn[i]}`, result.answers[i].choice);
          form.setFieldValue(`e${qn[i]}`, result.answers[i].extension);
        }
      }
    }
  };
  useEffect(() => {
    fetchData(location);
    window.scrollTo(0, 0);  
    // eslint-disable-next-line
  }, [location]);

  const onFinish = async (values) => {
    if (values.id === undefined) dispatch(setPage(page));
    let answers = [];
    for (let i = 0; i < qn.length; i++) {
      let c = form.getFieldValue(`c${qn[i]}`);
      let e = form.getFieldValue(`e${qn[i]}`);
      if (typeof (c) === 'object') c = c.join(',');
      answers.push({
        question: qn[i],
        choice: c ? c : "",
        extension: e ? e : "",
      });
    }
    setLoading(true);
    await apiServices.insert('answers', { id, page, location, answers });
    setLoading(false);
  };

  return (
    <>
      <div className="quest-card">
      {loading && <Curtain />}
        <div className="quest-title">
          The following set of questions relates to the time spent in the location: {place}
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Id" name="id" hidden>
            <Input />
          </Form.Item>
          <TextField
            form={form}
            label="74. List the places in which you played the most (in front of the house, in the basement, in the street…etc.)"
            question="c4.74"
          />

          <SingleField
            form={form}
            label="75. Whom did you mostly play with?"
            question="c4.75"
            options={[
              "Local children",
              "Other refugees/internally displaced children",
            ]}
          />

          <MultiField
            form={form}
            label="76. Which kind of transportation did you use?"
            question="c4.76"
            other="Other:"
            extension="e4.76"
            options={["I went everywhere on foot", "Bicycle", "Car", "Tram"]}
          />

          <SingleField
            form={form}
            label="77. Did you receive any packages from abroad?"
            question="c4.77"
            options={["Yes", "No", "I don't remember"]}
          />

          <MultiField
            form={form}
            label="78. What did the package contain?"
            question="c4.78"
            other="Other:"
            extension="e4.78"
            options={[
              "Food",
              "Sanitary products",
              "Cosmetics",
              "School supplies",
              "Toys",
              "Candy",
              "Clothing",
              "I don’t remember",
            ]}
          />

          <SingleField
            form={form}
            label="79. How did you obtain clothing and shoes during armed conflict?"
            question="c4.79"
            extension="e4.79"
            options={[
              "I wore my older brother/sister’s clothes and shoes",
              "My neighbors/friends gave them to me",
              "I received them in humanitarian aid",
              "I received them in a package from abroad",
              "I was wearing military clothing and boots",
              "We sewed our clothes and wore improvised shoes",
              "I bought my clothes and shoes",
              "I don’t remember",
            ]}
            other="Other:"
          />
        </Form>
        <Navigator prev={page - 1} next={page + 1} save={() => form.submit()} />
      </div>
    </>
  );
};

export default Page22;
