import React, { useState } from 'react';
import { Divider } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import LocationItem from './LocationItem';
import LocationForm from './LocationForm';
import service from 'services/apiServices';

const LocationCard = ({ title, type, collection, onUpdate }) => {

  const [data, setData] = useState(collection);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleEdit = itemId => setSelectedItem(itemId);

  const onSave = async item => {
    item.id === 0 ? await service.insert("locationcodes", item) : await service.update("locationcodes", item, item.id);
    setSelectedItem(null);
    onUpdate();
  }

  const onDelete = async id => {
    await service.remove("locationcodes", id);
    setSelectedItem(null);
    onUpdate();
  }

  const onCancel = () => {
    setSelectedItem(null);
    onUpdate();
  }

  const onAdd = () => {
    const newItem = {
      id: 0,
      type: type,
      code: '',
      value: ''
    };
    setData(data => [...data, newItem]);
    setSelectedItem(0);
  }

  return (
    <div className="lc-card">
      <h2>
        <div className='left'>{title}</div>
        <div className='right'><PlusCircleOutlined onClick={onAdd} /></div>
      </h2>
      <Divider />
      {data && data.map(item =>
        <div className="lc-item" key={item.id}>
          {item.id === selectedItem ?
            <LocationForm item={item} onSave={onSave} onCancel={onCancel} onDelete={onDelete} /> :
            <LocationItem item={item} onEdit={handleEdit} />
          }
        </div>
      )}
    </div>
  )
}

export default LocationCard;