import { Col, Row, Statistic } from 'antd';
import React from 'react';

const TranscriptView = ({ data }) => {

  const DisplayTag = ({ style, tag }) => {
    return (
      <div style={style}>
        {tag.tag}
        {tag.subtag > " " ? <div>- {tag.subtag}</div> : ""}
      </div>
    );
  }

  return (
    <>
      <Statistic value={data.time} />
      <hr />
      <Row>
        <Col span={16} style={styles.text}>
          <pre className="pre-text">{data.text}</pre>
        </Col>
        <Col span={8} style={styles.tags}>
          {/* {data.tags.map(x => <div style={styles.tag} key={x.id}>{DisplayTag(x)}</div>)} */}
          {data.tags.map(x => <DisplayTag style={styles.tag} key={x.id} tag={x} />)}
        </Col>
      </Row>
    </>
  );
}

export default TranscriptView;

const styles = {
  time: {
    color: 'blue',
    border: '1px solid blue'
  },
  tag: {
    color: 'green',
    borderBottom: '1px solid green',
    padding: '4px 0'
  },
  text: {
    padding: '0 12px',
    borderRight: '1px solid black'
  },
  tags: {
    padding: '0 12px'
  }
}