import React from 'react'

let title = {
  display: 'table',
  marginTop: 24,
  fontSize: '2em',
  color: '#333'
}

export default function Title({ align, children }) {
  const alignment = align === 'center' ? 'center' : 'left'
  return (
    <div>
      <div style={title} className={alignment}>{children}</div>
    </div>
  )
}