import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';

const LocationForm = ({ item, onSave, onCancel, onDelete }) => {

  const [form] = Form.useForm();

  const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

  useEffect(() => { form.setFieldsValue(item); }, [item, form]);

  const submit = values => onSave(values);
  const remove = () => onDelete(item.id);

  return (
    <Form form={form} {...layout} onFinish={submit}>
      <Form.Item name="id" hidden><Input /></Form.Item>
      <Form.Item name="type" hidden><Input /></Form.Item>
          <Form.Item name="code" label="Code"><Input /></Form.Item>
          <Form.Item name="value" label="Value"><Input /></Form.Item>
          <Button htmlType="submit" style={{marginLeft:'6px', backgroundColor:'teal', color:'white'}}>
            Save
          </Button>
          <Button style={{marginLeft:'6px', backgroundColor:'teal', color:'white'}} onClick={onCancel}>
            Close
          </Button>
          <Button style={{marginLeft:'6px', backgroundColor:'teal', color:'white'}} onClick={remove}>
            Delete
          </Button>
    </Form>
  )
}

export default LocationForm;