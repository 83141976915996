import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Curtain, Title } from 'common';
import service from 'services/apiServices';
import LocationCard from './LocationCard';

const LocationCodes = () => {

  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState({});

  const fetch = async () => {
    setLoading(true);
    const response = await service.list("locationcodes");
    setCodes(response);
    setLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  if (loading) return <Curtain />;
  return (
    <Layout className='container'>
      <Title align='center'>Object Location Codes</Title>
      <div className='lc-container'>
        <LocationCard title="COUNTRIES" type={0} collection={codes.countries} onUpdate={fetch} />
        <LocationCard title="CITIES" type={1} collection={codes.cities} onUpdate={fetch} />
        <LocationCard title="BUILDINGS" type={2} collection={codes.buildings} onUpdate={fetch} />
        <LocationCard title="ROOMS" type={3} collection={codes.rooms} onUpdate={fetch} />
      </div>
    </Layout>
  )
}

export default LocationCodes