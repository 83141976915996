import React, { useEffect, useCallback, useState } from "react";
import { Button, Form, Modal, Input, Select, Switch, Layout, Row, Col, Upload, message, Divider } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import service from 'services/apiServices';
import config from 'config';
import PdfView from "common/ViewPdf";
import Storage from './storage';
import { Delete } from "common";

const { TextArea } = Input;

const modout = { labelCol: { span: 4 }, wrapperCol: { span: 20 } };

const General = ({ form, labels, getId, setName }) => {

  const [tags, setTags] = useState([]);
  const [visDesc, setVisDesc] = useState(false);
  const [visStor, setVisStor] = useState(false);
  const [codes, setCodes] = useState({});
  const [reports, setReports] = useState([]);

  const hideDesc = () => setVisDesc(false);

  const getRelated = useCallback(async () => {
    try {
      let result = await service.list("master/tags/2");
      setTags(result);
      result = await service.list("locationcodes");
      setCodes(result);
      if (!form.getFieldValue("part")) {
        const id = form.getFieldValue('id');
        if (id) result = await service.read("conditionreports", id);
        setReports(result);
      }
    } catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
    }
    // eslint-disable-next-line
  }, []);

  const getReports = async () => {
    try {
      const result = await service.read("conditionreports", form.getFieldValue('id'));
      setReports(result);
    } catch (error) {
      console.error(error);
      message.error("We are experienciing some network related issues. Please try again in a minute.");
    }
  }

  useEffect(() => { 
    getRelated(); 
    // eslint-disable-next-line
  }, []);

  const modalTitle = subTitle => <h3>{subTitle}</h3>;

  // const checkReport = () => alert("CHECK REPORT! for " + form.getFieldValue('id'));

  const options = {
    name: 'file',
    multiple: false,
    action: config.uploadPath + 'report?id=' + form.getFieldValue('id'),
    headers: { 'authorization': `Bearer ${config.token}` },
    showUploadList: false,
    async onSuccess(body) {
      const data = {
        name: body,
        pieceId: form.getFieldValue('id')
      }
      await service.insert("conditionreports", data);
      form.setFieldsValue({ conditionReport: true });
      getReports();
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.info(`uploading ${info.file.name}...`);
      }
      if (status === 'done') {
        message.success(`${info.file.name} uploaded successfully`);
      } else if (status === 'error') {
        message.error(`${info.file.name} upload failed`);
      }
    }
  }

  const showDesc = () => {
    setVisDesc(true);
  }

  const reportChanged = value => {
    if (!value) form.setFieldsValue({ report: '' });
  }

  const nameChanged = event => {
    setName(event.target.value);
  }

  const handleCode = async (code) => {
    form.setFieldsValue({ storageCode: code });
    const result = await service.update("pieces/storage", { storageCode: code }, form.getFieldValue('id'));
    form.setFieldsValue({ tooltip: result.tooltip });
    setVisStor(false);
  }

  return (
    <Layout style={{ paddingTop: '24px' }}>
      <Form form={form} layout='vertical'>
        <Row>
          <Col span={12}>
            <Form.Item label={labels.NAME} name='name' initialValue='' onBlur={nameChanged}>
              <TextArea className="stepFormField" autoSize={{ minRows: 1, maxRows: 3 }} />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item label={labels.TYPE} name='objectType' initialValue=''>
                  <Select className="stepFormField" mode="combobox"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => service.anything(input, option.children)}
                    style={{ textAlign: 'left' }}>
                    {tags.map(t => (<Select.Option key={t.id} value={t.name}>{t.name}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Digital' name='digital' style={{ textAlign: 'left' }} valuePropName="checked" initialValue={false}>
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" onChange={getId} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label='Copy' name='copy' style={{ textAlign: 'left' }} valuePropName="checked" initialValue={false}>
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" onChange={getId} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item style={{ textAlign: 'left' }} label={labels.CONSERVATION} name="conservation" valuePropName="checked" initialValue={false}>
                  <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            {!form.getFieldValue("part") && <>
              <Row>
                <Col span={8}>
                  <h2>Condition Reports</h2>
                </Col>
                <Col span={8} align='center'>
                  <Form.Item label='' name="conditionReport" valuePropName="checked" initialValue={false}>
                    <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" onChange={reportChanged} />
                  </Form.Item>
                </Col>
                <Col span={8} align='right'>
                  <Upload {...options}>
                    <Button type="primary" icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col span={24}>
                  {!!reports.length && reports.map((r, i) => (
                    <Row key={i} style={{ borderBottom: '1px solid grey', padding: '12px 0' }}>
                      <Col span={16} style={{ paddingTop: 12 }}>
                        <PdfView link={r.name} comment={r.note} />
                      </Col>
                      <Col span={6} offset={2}>
                        <Delete
                          dataSet="conditionreports"
                          id={r.id}
                          disabled={config.currentUser.role !== 'SuperAdmin'}
                          onFinish={getReports} />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </>}
          </Col>

          <Col span={11} offset={1}>
            <Form.Item label={labels.DESCRIPTION} name='description' onClick={showDesc} initialValue=''>
              <Input.TextArea className='stepFormField' rows={9} />
            </Form.Item>
            <Form.Item label='Mount Location' name='standLocation' initialValue=''><Input className="stepFormField" /></Form.Item>
            <Form.Item label=' ' name='stand' valuePropName='checked' style={{ textAlign: 'left' }} hidden>
              <Switch size='small' checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>

            {!!form.getFieldValue("storageCode") ?
              <>
                <Form.Item name="tooltip" hidden><Input /></Form.Item>
                <Form.Item label="Storage Code" name="storageCode" initialValue='' tooltip={form.getFieldValue("tooltip")}>
                  <Input className="stepFormField" onClick={() => setVisStor(true)} />
                </Form.Item>
              </> :
              <Row>
                <Col span={20}>
                  <Form.Item label={labels.STORAGE} name="storage" initialValue=''><Input className="stepFormField" /></Form.Item>
                </Col>
                <Col span={4}>
                  <Button type="primary" style={{ marginTop: '28px' }} onClick={() => setVisStor(true)}>Build</Button>
                </Col>
              </Row>
            }
          </Col>
        </Row>

        <Modal width={'70%'}
          open={visDesc}
          title={modalTitle('Object Description')}
          footer={[]}
          onCancel={hideDesc}
          style={{ top: 20 }}>
          <Form.Item {...modout} label="Object Description" name="description">
            <Input.TextArea className="stepFormField" autoFocus cursor='end' rows={24} />
          </Form.Item>
        </Modal>

        <Modal width={'50%'}
          open={visStor}
          title={modalTitle('Storage Location Code')}
          footer={[]}
          style={{ top: 20 }}>
          <Storage
            codes={codes}
            code={form.getFieldValue("storageCode")}
            onOk={handleCode}
            onCancel={() => setVisStor(false)}
          />
        </Modal>

      </Form >
    </Layout>
  );
}

export default General;
