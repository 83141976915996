import React from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const TextField = ({ form, label, question }) => {

  return (
    <div>
      <div className="quest-label">{label}</div>
      <Form.Item label="" name={question} initialValue="">
        <TextArea className="quest-input" autoSize={{ minRows: 1, maxRows: 5 }} />
      </Form.Item>
    </div>
  );
}

export default TextField;
