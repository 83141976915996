import React, { useEffect } from 'react';
import { Form, Input, InputNumber, Layout, Button, Row, Col } from 'antd';

import service from 'services/apiServices';
import { Buttons, Delete } from 'common';

const { Content } = Layout;

const styles = {
  userField: {
    borderRadius: '4px',
    border: '1px solid #211E1A',
    backgroundColor: '#faffff',
    color: 'black'
  }
}

const Inhabitancy = ({ item, labels, onOk }) => {

  const [form] = Form.useForm();

  useEffect(() => { form.setFieldsValue({ ...item }) }, [form, item]);

  const onFailed = error => console.error(`Failed: ${error}`)

  const onFinish = async function (values) {
    const pid = service.toNumber(item.participantId);
    if (item.id === 0) {
      await service.insert('residences', { ...values, participant: { id: pid } });
    } else {
      await service.update('residences', { ...values, participant: { id: pid } }, item.id);
    }
    onOk();
  };

  return (
    <Layout>
      <Content align="center">
        <Form layout='vertical' form={form} onFinish={onFinish} onFinishFailed={onFailed}>
          <Form.Item label='Id' name='id' hidden><Input style={styles.userField} /></Form.Item>
          <Row>
            <Col span={24} align='left'>
              <Form.Item label='Order' name='order'><InputNumber style={styles.userField} /></Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label={labels.PLACE_INHABITED} name='place'><Input style={styles.userField} /></Form.Item>
              <Form.Item label={labels.PERIOD_INHABITED} name='period'><Input style={styles.userField} /></Form.Item>
              <Form.Item label='Participant' name='participantId' hidden><Input style={styles.userField} /></Form.Item>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col span={8}><Delete dataSet='residences' id={item.id} onFinish={onOk} /></Col>
            <Col span={8}><Button style={Buttons.cancel} onClick={onOk}>Cancel</Button></Col>
            <Col span={8}><Button style={Buttons.button} htmlType='submit'>SAVE</Button></Col>
          </Row>
        </Form>
      </Content>
    </Layout >
  );
}

export default Inhabitancy;
