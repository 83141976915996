import React from 'react';
import { Form, Radio } from 'antd';

const OneToFive =  ({ form, label, question }) => {
  return (
    <>
      <div className="quest-label">{label}</div>
      <Form.Item label="" name={question} initialValue="">
        <Radio.Group size="small">
            <Radio value="I don't feel it">1</Radio>
            <Radio value="I feel it a bit">2</Radio>
            <Radio value="I feel it slightly">3</Radio>
            <Radio value="I feel it significantly">4</Radio>
            <Radio value="I feel it strongly">5</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

export default OneToFive;