import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import moment from 'moment';
import { Table, Layout, Modal, message } from "antd";

import service from "services/apiServices";
import { isAdmin } from "services/authServices";
import tableHeader from "services/tableService";
import config from "config";
import Photo from './photo';
import { ShowImage } from "common";

const thumbnail = (image, type) =>
  <img style={{ border: '1px #999 solid' }}
    src={`${config.imagePath}${image}.${type}`}
    error={`${config.imagePath}noimg.jpg`}
    alt={image} />;

const colProps = [
  { key: "url", title: "Photo", width: '10%', render: (url, record) => thumbnail(url, record.fileType) },
  { key: "shootingDate", title: "Shooting Date", width: '12%', render: (time) => moment(time).format('YYYY-MM-DD') },
  { key: "size", title: "Size", width: '12%' },
  { key: "photographer", title: "Photographer", width: '25%' },
  { key: "photoCredit", title: "Photo Credit", width: '25%' }
];

const columns = ({ canView, canEdit, onViewClick, onEditClick }) =>
  tableHeader(
    colProps,
    true,
    true,
    onViewClick,
    onEditClick
  );

const { Content } = Layout;

const Photos = ({ object }) => {

  const [item, setItem] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const [image, setImage] = useState({ show: false, url: '' });
  const [objName, setObjName] = useState('');

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setObjName(await service.list(`pieces/${object}/name`));
      const result = await service.list(`pieces/${object}/photos`);
      if (result) setData(result);
      setIsLoading(false);
    } catch (error) {
      message.error(config.errorMessage);
      setIsLoading(false);
    }
  }, [object]);

  useEffect(() => { 
    fetch();
    // eslint-disable-next-line
  }, [fetch]);

  const editPhoto = id => {
    if (id === 0) {
      setItem({ 
        id: 0, 
        url: '', 
        fileType: '', 
        shootingDate: '0001-01-01', 
        size: '', 
        resolution: '', 
        photographer: '', 
        photoCredit: '', 
        note: '',
        use: 'light',
        objectId: object });
    } else {
      const photo = _.find(data, { id: id });
      setItem({ 
        id: photo.id,
        url: photo.url, 
        fileType: photo.fileType, 
        shootingDate: photo.shootingDate, 
        size: photo.size, 
        resolution: photo.resolution, 
        photographer: photo.photographer, 
        photoCredit: photo.photoCredit, 
        note: photo.note,
        use: photo.use,
        objectId: photo.piece.id });
    }
    showModal();
  }

  const refresh = () => {
    hideModal();
    fetch();
  }

  return (
    <div>
      <Layout>
        <Content>
          <Table
            loading={isLoading}
            size="small"
            tableLayount={"fixed"}
            columns={columns({
              canView: true,
              canEdit: isAdmin(),
              onViewClick: (id) => {
                const item = _.find(data, { id: id });
                setImage({ show: true, url: config.imagePath + item.url + '.' + item.fileType });
              },
              onEditClick: (id) => editPhoto(id),
            })}
            dataSource={data}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Content>
      </Layout>
      <ShowImage url={image.url} open={image.show} onCancel={() => setImage({ show: false, url: '' })} />
      <Modal width={'80%'} open={visible} title={<h2>OBJECT PHOTO: {objName}</h2>} footer={[]} onCancel={hideModal} style={{ top: 20, maxHeight: '90vh' }}><Photo item={item} onOk={refresh} /></Modal>
    </div >
  );
};

export default Photos;
