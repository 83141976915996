import React, { useState } from 'react'
import { Modal, Table, Popover } from 'antd';
import TableView from 'services/TableView';
import moment from 'moment';

const EditHistory = ({ lastUpdate, editHistory }) => {

  const [pop, setPop] = useState({ open: false, property: '', more: '', less: '' });
  const [visible, setVisible] = useState(false);

  const columns = (updated, user) => {
    const colArr = [
      { width: '20%', key: "property", dataIndex: "property", title: moment(updated).format("DD.MM.YYYY HH:mm") + " UTC" },
      { width: '40%', key: "oldValue", dataIndex: "oldValue", title: user, elipsis: true },
      { width: '40%', key: "newValue", dataIndex: "newValue", title: "New Value", elipsis: true }
    ];
    return TableView(colArr);
  }

  const showDiff = (record) => {
    setPop({ open: true, property: record.property, more: record.more, less: record.less });
  }

  const openModal = () => setVisible(true);

  if (editHistory && editHistory.length > 0) {
    return (
      <>
        <div className="edit-history-button">
          <button style={{ cursor: 'pointer' }} onClick={openModal} type='button'>{lastUpdate}</button>
        </div>

        <Modal width={'90%'}
          title={<h2>{editHistory[0].entName}: Edit History</h2>}
          open={visible} footer={[]}
          onCancel={() => setVisible(false)} destroyOnClose={true}>
          <div>
            {editHistory && editHistory.length && editHistory.map((item, index) => (
              <div key={index}>
                <Table
                  size="small"
                  pagination={false}
                  columns={columns(item.updated, item.user)}
                  onRow={(record, index) => ({ onClick: () => { showDiff(record); } })}
                  dataSource={item.history}
                  rowKey={(row) => row.id} />
              </div>
            )
            )}
          </div>
        </Modal>

        <Popover 
          disabled={true} 
          placement="top"
          content={
          <div>
            taken: {pop.more}<hr />
            added: {pop.less}
          </div>} 
          title={pop.property + " (move over to close)"} 
          open={pop.open && visible} 
          onOpenChange={(pop) => setPop({...pop, open: false})} />
      </>
    );
  }
  else {
    return (
      <div className="edit-history-button">
        <button title='No change history yet!' type='button'>{lastUpdate}</button>
      </div>
    );
  }
}

export default EditHistory;