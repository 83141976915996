import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  code: '',
  name: '',
  page: 0
}

const respondentSlice = createSlice({
  name: "respondent",
  initialState,
  reducers: {
    setRespondent: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearRespondent: () => { return { code: '', name: '', page: 0 } }
  }
});

const { reducer, actions } = respondentSlice;

export const { setRespondent, clearRespondent } = actions;

export default reducer;